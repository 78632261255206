import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { CustomToastrService, DialogHelperService, JokerDetailsService, LocalStorageService, ToStringService } from '@services';
import { IWsErrorDto, JokerDetailsWSEvent, ResponseDto, StringsKeys, WsJokerDetailsResponse } from '@types';

@Injectable({
  providedIn: 'root',
})
export class JokerDetailsSocketService {
  private socketConnection: Socket | undefined;

  constructor(
    private dialogHelperService: DialogHelperService,
    private toStringService: ToStringService,
    private jokerDetailsService: JokerDetailsService,
    private customToastrService: CustomToastrService
  ) {}

  public subscribeForWsOrdersUpdate(): void {
    this.socketConnection = io(`${environment.wsUrl}/tokenomic`, {
      auth: {
        token: LocalStorageService.getAccessToken(),
      },
      withCredentials: true,
      transports: ['websocket'],
    });

    this.socketConnection.on('connect', () => {
      this.socketConnection?.onAny((e: string, response: ResponseDto<WsJokerDetailsResponse> | IWsErrorDto) => {
        if ('error' in response) {
          this.dialogHelperService.openDefaultErrorDialog();
          return;
        }

        const result = response.result;

        switch (result.type) {
          case JokerDetailsWSEvent.HealSuccess: {
            this.jokerDetailsService
              .getJokerDetails(result.data.tokenId, false)
              .subscribe(() =>
                this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsHealSuccess))
              );
            break;
          }
          case JokerDetailsWSEvent.HealFailed: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsHealFail));
            break;
          }
          case JokerDetailsWSEvent.LevelUpSuccess: {
            this.jokerDetailsService
              .getJokerDetails(result.data.tokenId, false)
              .subscribe(() =>
                this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsLevelUpSuccess))
              );
            break;
          }
          case JokerDetailsWSEvent.LevelUpFailed: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsLevelUpFail));
            break;
          }
          case JokerDetailsWSEvent.BoostSuccess: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsBoostSuccess));
            break;
          }
          case JokerDetailsWSEvent.BoostFailed: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsBoostFail));
            break;
          }
        }
      });
    });
  }

  public unsubscribeForWsOrdersUpdate(): void {
    this.socketConnection?.disconnect();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private readonly _isLoading$ = new BehaviorSubject<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public get isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  public get isLoading(): boolean {
    return this._isLoading$.value;
  }

  public activate(): void {
    this._isLoading$.next(true);
  }

  public deactivate(): void {
    this._isLoading$.next(false);
  }
}

import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { CustomToastrService, DialogHelperService, LocalStorageService, NavigationService } from '@services';
import { IWsErrorDto, Notification, NotificationEventType, ResponseDto, WsNotificationResponse } from '@types';
import { NotificationsService } from './notifications.service';
import { ROUTES_ENUM } from '@constants';
import { filter, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsSocketService {
  public socketConnection: Socket;
  constructor(
    private dialogHelperService: DialogHelperService,
    private customToastrService: CustomToastrService,
    private notificationsService: NotificationsService,
    private navigationService: NavigationService
  ) {}

  public subscribeForWsNotifications(): void {
    if (this.socketConnection) return; // Do not sub if connections is created
    this.socketConnection = io(`${environment.wsUrl}/notifications`, {
      auth: {
        token: LocalStorageService.getAccessToken(),
      },
      withCredentials: true,
      transports: ['websocket'],
    });

    this.socketConnection.on('connect', () => {
      this.socketConnection?.onAny((e: string, response: ResponseDto<WsNotificationResponse> | IWsErrorDto) => {
        if ('error' in response) {
          this.dialogHelperService.openDefaultErrorDialog();
          return;
        }

        const result = response.result;
        switch (result.type) {
          case NotificationEventType.NOTIFICATION_CREATED: {
            const notification = result.data;
            this.handleSocketNotificationsCreated(notification);
            return;
          }
        }
      });
    });
  }

  private handleSocketNotificationsCreated(notification: Notification): void {
    const title = notification.title.length > 30 ? notification.title.slice(0, 25) + '...' : notification.title;
    const message = notification.content.length > 50 ? notification.content.slice(0, 45) + '...' : notification.content;
    this.customToastrService.show(title, message);

    this.navigationService
      .isRouteActive$(ROUTES_ENUM.HOME)
      .pipe(
        filter(Boolean),
        take(1),
        switchMap(() => this.notificationsService.getNotificationsUnreadCount())
      )
      .subscribe();
  }

  public unsubscribeFromWsNotifications(): void {
    this.socketConnection?.disconnect();
  }
}

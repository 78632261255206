import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseDto } from '@types';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, catchError, EMPTY, map, take, tap } from 'rxjs';

interface OnboardingSubjectData {
  isChecked: boolean;
  isOnboarded: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private http = inject(HttpClient);
  private readonly initialOnboardingValue: () => OnboardingSubjectData = () => ({
    isChecked: false,
    isOnboarded: true,
  });

  private readonly onboarding = new BehaviorSubject<OnboardingSubjectData>(this.initialOnboardingValue());
  public readonly onboarding$ = this.onboarding.asObservable();

  public resetState(): void {
    this.onboarding.next(this.initialOnboardingValue());
  }

  public checkIfOnboarded(): void {
    if (this.onboarding.value.isChecked) {
      return;
    }
    this.http
      .get<ResponseDto<Pick<OnboardingSubjectData, 'isOnboarded'>>>(`${environment.apiUrl}/onboarding`)
      .pipe(
        take(1),
        map(({ result }) => result.isOnboarded),
        tap((isOnboarded) => {
          Object.assign(this.onboarding.value, {
            isChecked: true,
            isOnboarded: isOnboarded,
          });
          this.onboarding.next(this.onboarding.value);
        }),
        catchError(() => EMPTY)
      )
      .subscribe();
  }

  public completeOnboarding(): void {
    Object.assign(this.onboarding.value, {
      isOnboarded: true,
    });
    this.onboarding.next(this.onboarding.value);
    const body = { isOnboarded: true };
    this.http
      .put<ResponseDto<{ isOnboarded: boolean }>>(`${environment.apiUrl}/onboarding`, body)
      .pipe(
        take(1),
        catchError(() => EMPTY)
      )
      .subscribe();
  }
}

import { Component } from '@angular/core';
import { NavigationItem } from '@types';
import { Colors, headerNavigationItems } from '@constants';

@Component({
  selector: 'lpt-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss'],
})
export class DesktopHeaderComponent {
  public navItems: NavigationItem[] = headerNavigationItems;
  public Colors = Colors;
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageCacheService {
  private _storage: Storage;

  constructor(private http: HttpClient) {
    this._storage = sessionStorage;
  }

  public getImage(url: string): Promise<string> {
    const objectURL = this._storage.getItem(url);
    if (objectURL) {
      return Promise.resolve(objectURL);
    }

    return firstValueFrom(this.http.get(url, { responseType: 'blob' })).then((blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this._storage.setItem(url, <string>reader.result);
          resolve(<string>reader.result);
        };
      });
    });
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { SwiperOptions } from 'swiper/types/swiper-options';
import { SwiperItem } from '@types';
import { Swiper } from 'swiper/types';

@Component({
  selector: 'lpt-swiper',
  templateUrl: './swiper.component.html',
  styleUrls: ['./swiper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwiperComponent {
  @Input() public swiperItems: SwiperItem[] = [];

  @Input() public currentIndex: number | null;
  @Output() public currentItemIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() public jokerClicked: EventEmitter<number> = new EventEmitter<number>();

  @Input() public config: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    centeredSlides: true,
    effect: 'slide',
    freeMode: true,
  };

  public jokersTrackBy(index: number, joker: SwiperItem) {
    return joker.imageUrl;
  }

  constructor(private ngZone: NgZone) {}

  public onSlideChange([swiper]: [swiper: Swiper]) {
    const currentIndex = swiper.activeIndex;

    this.ngZone.runTask(() => {
      this.currentItemIndexChange.next(currentIndex);
    });
  }
}

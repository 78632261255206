import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { IWsErrorDto, MarketplaceWSEvent, ResponseDto, StringsKeys, WsMarketOrder, WsMarketplaceResponse } from '@types';
import { DialogHelperService } from './dialog-helper.service';
import { CustomToastrService } from './custom-toastr-service.service';
import { ToStringService } from './to-string.service';
import { JokerService } from './joker/joker.service';
import { NavigationService } from './navigation.service';
import { ROUTES_ENUM } from '@constants';
import { filter, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MarketSocketService {
  private socketConnection: Socket | undefined;
  constructor(
    private dialogHelperService: DialogHelperService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService,
    private jokerService: JokerService,
    private navigationService: NavigationService
  ) {}

  public subscribeForWsOrdersUpdate(): void {
    this.socketConnection = io(`${environment.wsUrl}/marketplace`, {
      auth: {
        token: LocalStorageService.getAccessToken(),
      },
      withCredentials: true,
      transports: ['websocket'],
    });

    this.socketConnection.on('connect', () => {
      this.socketConnection?.onAny((e: string, response: ResponseDto<WsMarketplaceResponse> | IWsErrorDto) => {
        if ('error' in response) {
          this.dialogHelperService.openDefaultErrorDialog();
          return;
        }

        const result = response.result;
        switch (result.type) {
          case MarketplaceWSEvent.MARKETPLACE_ORDER_PROCESSED: {
            this.handleJokerProcessedAction(result.data);
          }
        }
      });
    });
  }

  public handleJokerProcessedAction(order: WsMarketOrder): void {
    const user = LocalStorageService.getUser();

    const messageKey = order.buyerId === user?.id ? StringsKeys.socketMarketBuyProcessed : StringsKeys.socketMarketSellProcessed;

    this.customToastrService.show('', this.toStringService.toString(messageKey));

    this.navigationService
      .isRouteActive$(ROUTES_ENUM.HOME, true)
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          return this.jokerService.requestUserJokers();
        })
      )
      .subscribe();
  }

  public unsubscribeForWsOrdersUpdate(): void {
    this.socketConnection?.disconnect();
  }
}

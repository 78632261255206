import { Injectable } from '@angular/core';
import { catchError, filter, Observable, switchMap, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { DialogHelperService, SpinnerService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private dialogHelperService: DialogHelperService
  ) {}

  public sendSupportRequest(message: string): Observable<null> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .post<null>(`${environment.apiUrl}/support`, {
        message,
      })
      .pipe(
        tap(() => this.spinnerService.hideGlobalSpinner()),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public handleSendSupportRequest(): void {
    this.dialogHelperService
      .getSupportRequestDialog()
      .pipe(
        filter(Boolean),
        switchMap((message) => this.sendSupportRequest(message))
      )
      .subscribe();
  }
}

<div class="control-wr h-100">
  <div class="pos-rel" *ngIf="!isTextarea;else textAreaTmp">
    <input
      #input
      class="control w-100"
      [class.valid]="valid"
      [class.invalid]="invalid"
      [class.filled]="inputValue"
      [ngModel]="inputValue"
      [type]="type"
      (blur)="onBlur()"
      (focus)="onFocus()"
      (input)="changeValue(input.value)"
      (keydown)="handleNumberTypeEvents($event)"
      (paste)="changeValue(input.value)"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [mask]="mask"
    />
    <div *ngIf="clearable && inputValue && !disabled" class="control-clear" id="input-clear-icon-button" (click)="clear()">
      <svg-icon src="./assets/images/delete.svg"></svg-icon>
    </div>
  </div>

  <ng-template #textAreaTmp>
    <textarea
      #input
      class="control textarea-control w-100"
      [class.valid]="valid"
      [class.invalid]="invalid"
      [class.filled]="inputValue"
      [ngModel]="inputValue"
      (blur)="onBlur()"
      (input)="changeValue(input.value)"
      (keydown)="handleNumberTypeEvents($event)"
      (paste)="changeValue(input.value)"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [mask]="mask"
    ></textarea>
  </ng-template>
</div>

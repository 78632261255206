import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxMaskModule } from 'ngx-mask';
import { SocketIoModule } from 'ngx-socket-io';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DialogService } from '@ngneat/dialog';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from '@interceptors';
import { InitializerService, ToStringService } from '@services';
import { lottieModuleOptionsFactory, initializeAppFactory } from '@helpers';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DecimalPipe } from '@angular/common';
import { LottieCacheModule, LottieModule, provideCacheableAnimationLoader } from 'ngx-lottie';
import { PlaybackAudioEffectDirectiveModule, PlaybackVideoTrackModule } from '@directives';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
    NgxSpinnerModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    AngularSvgIconModule.forRoot(),
    SocketIoModule,
    LottieModule.forRoot(lottieModuleOptionsFactory()),
    LottieCacheModule.forRoot(),
    PlaybackAudioEffectDirectiveModule,
    PlaybackVideoTrackModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    InitializerService,
    ToStringService,
    DialogService,
    DecimalPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [InitializerService, ToStringService],
      multi: true,
    },
    provideCacheableAnimationLoader(),
  ],
})
export class AppModule {}

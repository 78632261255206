export interface Notification {
  readonly content: string;
  readonly createdAt: string;
  readonly id: string;
  readonly title: string;
  readonly unread: boolean;
}

export enum NotificationEventType {
  NOTIFICATION_CREATED = 'notifications.created',
}
export interface WsNotificationResponse {
  type: NotificationEventType;
  data: Notification;
}

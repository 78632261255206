import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { StringsKeys } from '@types';
import { FormControl, ValidatorFn } from '@angular/forms';
import { ToStringService } from '@services';

@Component({
  selector: 'lpt-enter-text-dialog',
  templateUrl: './enter-text-dialog.component.html',
  styleUrls: ['./enter-text-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterTextDialogComponent {
  public title: StringsKeys;
  public inputLabel: StringsKeys;
  public confirmButtonText: StringsKeys;
  public placeholder: StringsKeys;
  public isTextarea: boolean;
  public clearable: boolean;
  public validators: ValidatorFn[];
  public enterTextFormControl: FormControl;
  public customHeight: any = undefined;

  constructor(
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef,
    private toStringService: ToStringService
  ) {
    this.title = this.dialogRef.data.title;
    this.inputLabel = this.dialogRef.data.inputLabel;
    this.placeholder = this.dialogRef.data.placeholder;
    this.isTextarea = this.dialogRef.data.isTextarea;
    this.clearable = this.dialogRef.data.clearable;
    this.confirmButtonText = this.dialogRef.data.confirmButtonText;
    this.customHeight = this.dialogRef.data.customHeight;

    this.validators = this.dialogRef.data.validators;

    this.enterTextFormControl = new FormControl(this.dialogRef.data.initialFormValue, this.validators);
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public get controlErrors(): string {
    if (this.enterTextFormControl.errors?.['required']) return this.toStringService.toString(StringsKeys.requiredFormError);
    const maxLengthError = this.enterTextFormControl.errors?.['maxlength'];
    if (maxLengthError)
      return this.toStringService.toString(StringsKeys.maxLengthFormError, {
        length: maxLengthError.requiredLength,
      });

    const minLengthError = this.enterTextFormControl.errors?.['maxlength'];
    if (minLengthError)
      return this.toStringService.toString(StringsKeys.minLengthFormError, {
        length: minLengthError.requiredLength,
      });
    return '';
  }

  public saveValue(): void {
    this.enterTextFormControl.markAllAsTouched();
    this.cdr.detectChanges();
    if (!this.enterTextFormControl.valid) return;

    const value = this.enterTextFormControl.value;

    this.dialogRef.close(value);
  }
}

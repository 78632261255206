import { Injectable } from '@angular/core';
import { ECheckMediaPermissionsStatus, EPermissionError, EPermissionState } from '@types';
import { VIDEO_MEDIA_TRACK_CONSTRAINTS } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class MediaPermissionsService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public getMediaPermissions(): Promise<[PermissionStatus, PermissionStatus] | undefined> {
    if (!navigator?.permissions?.query) {
      return Promise.resolve(undefined);
    }
    return Promise.all([
      navigator.permissions.query(<PermissionDescriptor>{ name: 'microphone' as unknown }),
      navigator.permissions.query(<PermissionDescriptor>{ name: 'camera' as unknown }),
    ]).catch(() => undefined);
  }

  public async checkMediaPermissions(): Promise<ECheckMediaPermissionsStatus> {
    const permissions = await this.getMediaPermissions();

    if (permissions) {
      const isAudioGranted = permissions[0].state === EPermissionState.Granted;
      const isVideoGranted = permissions[1].state === EPermissionState.Granted;
      const hasAudioDevices = await this.hasAudioDevices();
      const hasVideoDevices = await this.hasVideoDevices();

      if (isAudioGranted && isVideoGranted && hasAudioDevices && hasVideoDevices) {
        return ECheckMediaPermissionsStatus.Granted;
      }

      if (isAudioGranted && isVideoGranted) {
        return ECheckMediaPermissionsStatus.GrantedButNotFound;
      }
    }

    /**
     * Firefox issue:
     * 'permissions.query' is supported partially.
     * 'microphone' and 'camera' are not valid descriptor names.
     * Supported valid descriptor names:
     * https://searchfox.org/mozilla-central/source/dom/webidl/Permissions.webidl
     */
    const perms = await this.hasPermissionsByGettingMediaTracks();
    if (perms instanceof DOMException) {
      return perms.name === EPermissionError.NotAllowed
        ? ECheckMediaPermissionsStatus.NotAllowed
        : ECheckMediaPermissionsStatus.NotFound;
    }

    if (perms.audio && perms.video) {
      return ECheckMediaPermissionsStatus.Granted;
    }

    return ECheckMediaPermissionsStatus.NotFound;
  }

  private hasAudioDevices(): Promise<boolean> {
    return navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const media = mediaDevices
        .filter((mediaDevice) => mediaDevice.kind === 'audioinput')
        .map((mediaDevice) => mediaDevice.label);
      return media.some((mediaDevice) => mediaDevice.length);
    });
  }

  private hasVideoDevices(): Promise<boolean> {
    return navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const media = mediaDevices
        .filter((mediaDevice) => mediaDevice.kind === 'videoinput')
        .map((mediaDevice) => mediaDevice.label);
      return media.some((mediaDevice) => mediaDevice.length);
    });
  }

  /**
   * @note Is used as an alternative approach instead of 'permissions.query'.
   */
  public hasPermissionsByGettingMediaTracks(): Promise<{ video: boolean; audio: boolean } | DOMException> {
    return navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: VIDEO_MEDIA_TRACK_CONSTRAINTS,
      })
      .then((mediaStream) => {
        const audio = Boolean(mediaStream.getAudioTracks()[0]?.enabled);
        const video = Boolean(mediaStream.getVideoTracks()[0]?.enabled);

        mediaStream.getTracks().forEach((track) => track.stop());

        return { video, audio };
      })
      .catch((error: DOMException) => error);
  }
}

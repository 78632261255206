import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService, LocalStorageService, NavigationService, SpinnerService } from '@services';
import { take } from 'rxjs';

@Component({
  selector: 'lpt-telegram-login-redirect',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramLoginRedirectComponent {
  constructor(
    private spinnerService: SpinnerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.spinnerService.showGlobalSpinner();

    this.route.fragment.pipe(take(1)).subscribe((fragment: string | null) => {
      if (fragment) {
        const params = new URLSearchParams(fragment);
        const accessToken = params.get('accessToken');
        if (accessToken) {
          LocalStorageService.setAccessToken(accessToken);

          this.authService.getSessionData().subscribe({
            next: (user) => {
              LocalStorageService.setAuthData(user);
              void this.navigationService.toDashboard();
            },
            error: () => {
              void this.navigationService.toLogin();
            },
          });
        } else {
          void this.navigationService.toLogin();
        }
      }
    });
  }
}

<ng-container *ngIf="insufficientFundsError">
  <div class="dialog__container">
    <div class="flex-center">
      <svg-icon class="dialog-icon" [src]="'./assets/images/light.svg'"></svg-icon>
    </div>
    <div class="dialog-title">
      {{StringsKeys.insufficientFundsPopupTitleFormat | toString : { 'currency': insufficientFundsError.owned.currency } }}
    </div>

    <div class="dialog__buy-info">
      <lpt-info-row-item
        [leftText]="StringsKeys.insufficientFundsPopupPriceYouHave | toString"
        [rightText]="(insufficientFundsError.owned.amount | number: '1.0-4') + ' '+ insufficientFundsError.owned.currency "
        [rightIconSrc]="getTokenIconByCurrencyCode(insufficientFundsError.owned.currency)"
      >
      </lpt-info-row-item>

      <lpt-info-row-item
        [leftText]="StringsKeys.insufficientFundsPopupPrice | toString"
        [rightText]="insufficientFundsError.required.amount + ' ' + insufficientFundsError.required.currency"
        [rightIconSrc]="getTokenIconByCurrencyCode(insufficientFundsError.required.currency)"
      >
      </lpt-info-row-item>
    </div>

    <div class="dialog__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="insufficient-funds-dialog-close-button" (click)="closeDialog(false)">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button class="btn btn-orange" id="insufficient-funds-dialog-transfer-button" (click)="closeDialog(true)">
        {{StringsKeys.btnTransfer | toString}}
      </button>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileHeaderComponent } from './mobile-header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BalanceHeaderModule } from '../balance-header/balance-header.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [MobileHeaderComponent],
  imports: [CommonModule, AngularSvgIconModule, BalanceHeaderModule, RouterModule],
  exports: [MobileHeaderComponent],
})
export class MobileHeaderModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsufficientFundsDialogComponent } from './insufficient-funds-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InfoRowItemModule } from '@components';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [InsufficientFundsDialogComponent],
  imports: [CommonModule, AngularSvgIconModule, InfoRowItemModule, ToStringPipeModule],
  exports: [InsufficientFundsDialogComponent],
})
export class InsufficientFundsDialogModule {}

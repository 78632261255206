import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, catchError, combineLatest, filter, map, Observable, of, pairwise, switchMap, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InsufficientFundsError, JokerModel, PriceFeeItem, ResponseDto, StringsKeys } from '@types';
import camelcaseKeys from 'camelcase-keys';
import { DialogHelperService } from '../dialog-helper.service';
import { LoaderService } from '../loader.service';
import { SpinnerService } from '../spinner.service';
import { CustomToastrService } from '../custom-toastr-service.service';
import { ToStringService } from '../to-string.service';

@Injectable({
  providedIn: 'root',
})
export class JokerService {
  private jokers: BehaviorSubject<JokerModel[]> = new BehaviorSubject<JokerModel[]>([]);
  public jokers$: Observable<JokerModel[]> = this.jokers.asObservable();

  private currentJoker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);
  public currentJoker$: Observable<JokerModel | null> = this.currentJoker.asObservable();

  public currentJokerIndex$: Observable<number> = this.currentJokerIndex();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private loaderService: LoaderService,
    private spinnerService: SpinnerService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService
  ) {
    this.subscribeForLocalJokerChange();
  }

  public hasCurrentJoker(): boolean {
    return Boolean(this.currentJoker.value);
  }

  public requestUserJokers(): Observable<any> {
    this.spinnerService.showGlobalSpinner();
    return this.http.get<ResponseDto<JokerModel[]>>(`${environment.apiUrl}/jokers`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      tap((jokers) => {
        this.spinnerService.hideGlobalSpinner();
        this.jokers.next(jokers);
      }),
      switchMap(() => this.getCurrentJoker()),
      switchMap((currentJoker: JokerModel | null) => {
        return currentJoker
          ? of(currentJoker)
          : this.jokers$.pipe(
              // set fist joker as a default one
              filter((jokers) => Boolean(jokers.length)),
              switchMap((jokers) => {
                const firstJokerToBeDefault = jokers[0];
                this.setCurrentJokerLocally(firstJokerToBeDefault);
                return this.setCurrentJoker(firstJokerToBeDefault);
              })
            );
      }),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getCurrentJoker(): Observable<JokerModel | null> {
    this.loaderService.activate();
    return this.http.get<ResponseDto<JokerModel>>(`${environment.apiUrl}/jokers/default`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      tap((joker) => {
        this.loaderService.deactivate();
        this.currentJoker.next(joker);
      }),
      catchError((e) => {
        this.loaderService.deactivate();
        if (e?.error?.code === 404) {
          return of(null);
        }
        this.dialogHelperService.openDefaultErrorDialog();
        return of(e);
      })
    );
  }

  public setCurrentJokerLocally(joker: JokerModel): void {
    this.currentJoker.next(joker);
  }

  public setCurrentJoker(joker: JokerModel): Observable<null> {
    this.loaderService.activate();
    return this.http
      .post<null>(`${environment.apiUrl}/jokers/default`, {
        jokerId: joker.tokenId,
      })
      .pipe(
        take(1),
        catchError((e) => {
          this.loaderService.deactivate();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        }),
        map(() => null),
        tap(() => this.loaderService.deactivate())
      );
  }

  public subscribeForLocalJokerChange(): void {
    this.currentJoker$
      .pipe(
        filter(Boolean),
        filter((joker) => !joker.isOnSale), // Do not set default on server if joker is on sale
        pairwise(),
        // Skip if previous and current joker is the same
        filter(([oldJoker, newJoker]) => Boolean(oldJoker.tokenId !== newJoker.tokenId)),
        map(([oldJoker, newJoker]) => newJoker),
        switchMap((value: JokerModel) => this.setCurrentJoker(value))
      )
      .subscribe();
  }

  private currentJokerIndex(): Observable<number> {
    return combineLatest([this.jokers$, this.currentJoker$.pipe(filter(Boolean))]).pipe(
      map(([jokers, currentJoker]) => {
        const index = jokers.findIndex((joker) => joker.tokenId === currentJoker?.tokenId);
        return index === -1 ? 0 : index;
      })
    );
  }

  public requestOrdinaryJoker(): Observable<ResponseDto<void>> {
    this.spinnerService.showGlobalSpinner();
    return this.http.post<ResponseDto<void>>(`${environment.apiUrl}/jokers/ordinary`, {}).pipe(
      take(1),
      tap(() => {
        this.spinnerService.hideGlobalSpinner();
        this.customToastrService.show('', this.toStringService.toString(StringsKeys.ordinaryJokerHasBeenRequested));
      }),
      catchError((e: HttpErrorResponse) => {
        this.spinnerService.hideGlobalSpinner();
        this.customToastrService.show('', e.error?.message, 3000, 'error');
        throw e;
      })
    );
  }

  public getJokerHealFee(tokenId: string): Observable<PriceFeeItem> {
    return this.http.get<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/jokers/${tokenId}/heal/fee`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public healJoker(tokenId: string, points: number): Observable<PriceFeeItem> {
    return this.http
      .post<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/jokers/${tokenId}/heal`, {
        points,
      })
      .pipe(
        take(1),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        catchError((e) => {
          const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

          if (insufficientFundsError) {
            this.dialogHelperService.openInsufficientFundsDialog(insufficientFundsError);
            throw e;
          }
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public allocateJoker(tokenId: string, data: Record<string, number>): Observable<void> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .post<void>(`${environment.apiUrl}/jokers/${tokenId}/allocate-points`, {
        ...data,
      })
      .pipe(
        take(1),
        tap(() => this.spinnerService.hideGlobalSpinner()),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public getOrdinaryJokerGamesRestoreFee(): Observable<PriceFeeItem> {
    this.spinnerService.showGlobalSpinner();
    return this.http.get<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/jokers/ordinary/restore/fee`).pipe(
      take(1),
      tap(() => this.spinnerService.hideGlobalSpinner()),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();

        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public renewOrdinaryJokerGames(): Observable<void> {
    this.spinnerService.showGlobalSpinner();
    return this.http.post<void>(`${environment.apiUrl}/jokers/ordinary/restore`, {}).pipe(
      take(1),
      tap(() => this.spinnerService.hideGlobalSpinner()),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();

        const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

        if (insufficientFundsError) {
          this.dialogHelperService.openInsufficientFundsDialog(insufficientFundsError);
          throw e;
        }
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public handleRestoreOrdinaryJokerGames(): void {
    this.getOrdinaryJokerGamesRestoreFee()
      .pipe(
        take(1),
        switchMap((priceFeeItem) => this.dialogHelperService.openBuyFreeGamesDialog(priceFeeItem)),
        filter(Boolean),
        switchMap(() => this.renewOrdinaryJokerGames())
      )
      .subscribe();
  }
}

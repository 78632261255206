import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ROUTES_ENUM } from '@constants';
import { JokerService } from '@services';

@Component({
  selector: 'lpt-no-joker',
  templateUrl: './no-joker.component.html',
  styleUrls: ['./no-joker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoJokerComponent {
  public routes = ROUTES_ENUM;

  constructor(private jokerService: JokerService) {}
  public requestOrdinaryJoker(): void {
    this.jokerService.requestOrdinaryJoker().subscribe();
  }
}

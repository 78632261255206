<svg-icon
  src="assets/images/daily-reward.svg"
  class="daily__reward-icon flex-center"
  [svgStyle]="{ width: 200, height: 200 }"
></svg-icon>

<p class="daily__reward-title">{{ StringsKeys.titleYourDailyReward | toString }}</p>

<lpt-points class="d-block" [value]="points"></lpt-points>

<lpt-daily-reward-widget class="d-block"></lpt-daily-reward-widget>

<button class="btn btn-orange w-100" id="daily-reward-dialog-ok-button" (click)="close()">
  {{ StringsKeys.btnOK | toString }}
</button>

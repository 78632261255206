import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WalletAddressComponent } from './wallet-address.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ClipboardModule } from 'ngx-clipboard';
import { HideAddressPipeModule } from '@pipes';

@NgModule({
  declarations: [WalletAddressComponent],
  imports: [CommonModule, AngularSvgIconModule, ClipboardModule, HideAddressPipeModule],
  exports: [WalletAddressComponent],
})
export class WalletAddressModule {}

<div class="text-wr">
  <div class="header flex-center">
    <div class="header__icon flex-center cursor-pointer" id="change-email-dialog-close-icon-button" (click)="closeDialog()">
      <svg-icon class="header__icon-svg" [src]="'./assets/images/arrow-left.svg'"></svg-icon>
    </div>
    <div class="header__title">{{StringsKeys.editProfileEditEmailTitle| toString}}</div>
  </div>

  <div class="text__main-section">
    <form class="signup__form h-100" [formGroup]="state.form" (ngSubmit)="submit()">
      <div class="inputs-block">
        <div *ngIf="state.form.controls['email'] as control" class="signup__form-control-wr">
          <p class="control-label">{{StringsKeys.editProfileNewEmailTitle | toString}}</p>
          <lpt-input
            formControlName="email"
            [clearable]="true"
            [valid]="control.dirty && control.valid"
            [invalid]="control.dirty && control.invalid"
            type="email"
            [placeholder]="StringsKeys.editProfileNewEmailHint | toString"
          ></lpt-input>
          <p class="shared-error">
            <ng-template [ngIf]="control.dirty && control.invalid && !!control.errors">
              <span *ngIf="control.errors['required']">{{StringsKeys.requiredFormError | toString}}</span>
              <span *ngIf="control.errors['pattern']">{{StringsKeys.fieldEmailInvalid | toString}}</span>
            </ng-template>
          </p>
        </div>

        <div *ngIf="state.form.controls['otp'] as control" class="signup__form-control-wr">
          <div class="flex-between">
            <p class="control-label">{{StringsKeys.editProfileNewCodeTitle | toString}}</p>
            <button
              *ngIf="!state.remainingTime"
              type="submit"
              class="link signup__form-sendcode cursor-pointer"
              id="change-email-dialog-send-otp-button"
              (click)="submitterId = 'sendOtp-btn'"
            >
              {{StringsKeys.loginSendCodeBtn | toString}}
            </button>
            <span *ngIf="state.remainingTime" class="link signup__form-sendcode">{{ state.remainingTime | remainingTime }}</span>
          </div>

          <lpt-input
            formControlName="otp"
            [clearable]="true"
            [valid]="control.dirty && control.valid"
            [invalid]="control.dirty && control.invalid"
            type="text"
            [mask]="mask"
            [placeholder]="StringsKeys.editProfileNewCodeHint | toString"
          ></lpt-input>

          <p class="shared-error">
            <ng-template [ngIf]="control.dirty && control.invalid && !!control.errors">
              <span *ngIf="control.errors['required']">{{StringsKeys.requiredFormError | toString}}</span>
              <span *ngIf="control.errors['otp'] || control.errors['minlength']"
                >{{StringsKeys.fieldCodeInvalid | toString}}</span
              >
            </ng-template>
          </p>
        </div>
      </div>
      <button class="btn btn-orange" id="change-email-dialog-save-button" type="submit" (click)="submitterId ='save-btn'">
        {{StringsKeys.btnSave | toString}}
      </button>
    </form>
  </div>
</div>

import { nets } from 'face-api.js';
import { FACE_API_MODELS_URI } from '@constants';

export class FaceApiModelsLoader {
  public static loadTinyFaceDetectorModel(): Promise<void> {
    return nets.tinyFaceDetector.loadFromUri(FACE_API_MODELS_URI);
  }

  public static loadFaceExpressionModel(): Promise<void> {
    return nets.faceExpressionNet.loadFromUri(FACE_API_MODELS_URI);
  }
}

import { environment } from 'src/environments/environment';

export const HAND_LANDMARKER_WASM_FILESET_LOADER_URI = environment.cloudfrontUrl + '/scripts/vision_wasm_internal.js';

export const HAND_LANDMARKER_WASM_FILESET_BINARY_URI = environment.cloudfrontUrl + '/scripts/vision_wasm_internal.wasm';

export const HAND_LANDMARKER_TASK_URI = environment.cloudfrontUrl + '/scripts/hand_landmarker.task';

/**
 * @see https://developers.google.com/mediapipe/solutions/vision/hand_landmarker/web_js#configuration_options
 */
export const MIN_HAND_DETECTION_CONFIDENCE = 0.4;

/**
 * @see https://developers.google.com/mediapipe/solutions/vision/hand_landmarker/web_js#configuration_options
 */
export const MIN_HAND_TRACKING_CONFIDENCE = 0.4;

/**
 * @see https://developers.google.com/mediapipe/solutions/vision/hand_landmarker/web_js#configuration_options
 */
export const MIN_HAND_PRESENSE_CONFIDENCE = 0.4;

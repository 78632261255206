import { Directive, ElementRef, OnInit } from '@angular/core';
import { ImageCacheService } from '@services';

@Directive({
  selector: '[lptImageCache]',
  standalone: true,
})
export class ImageCacheDirective implements OnInit {
  private readonly imageUrlRegExp = /blockchains\/BNB\/jokers\/[^/]+\/image$/;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private imageCacheService: ImageCacheService
  ) {}

  ngOnInit(): void {
    const src = this.el.nativeElement.getAttribute('src') || '';
    if (this.imageUrlRegExp.test(src)) {
      this.imageCacheService.getImage(src).then((objURL) => {
        this.el.nativeElement.src = objURL;
      });
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallTimerComponent } from './call-timer.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RemainingTimePipeModule } from '@pipes';

@NgModule({
  declarations: [CallTimerComponent],
  imports: [CommonModule, AngularSvgIconModule, RemainingTimePipeModule],
  exports: [CallTimerComponent],
})
export class CallTimerModule {}

export enum ELocalStorageKey {
  AccessToken = 'accesstoken',
  Theme = 'theme',
  Uuid = 'uuid',
  Nickname = 'nickname',
  User = 'user',
  OnboardingStep = 'onboardingstep',
}

export enum ESessionStorageKey {
  ReloadedAfterLoadingChunkFailed = 'reloaded_after_loading_chunk_failed',
}

<div class="buy-joker-games__container dialog__container">
  <svg-icon [src]="'./assets/images/light.svg'"></svg-icon>

  <h3 class="buy-joker-games__title">{{StringsKeys.popupBuyFreeGamesTitle | toString}}</h3>

  <h3 class="txt-secondary fz-14 pt-10">{{StringsKeys.popupBuyFreeGamesDescription | toString}}</h3>

  <div class="buy-joker-games__info w-100">
    <lpt-info-row-item
      class="d-block w-100"
      [leftText]="StringsKeys.popupPriceFreeGamesTitle | toString"
      [rightText]="priceInfo.price.amount + ' ' + priceInfo.price.currency"
      [rightIconSrc]="'./assets/images/'+(priceInfo.price.currency | lowercase)+ '.svg'"
    ></lpt-info-row-item>
    <lpt-info-row-item
      class="d-block w-100"
      [leftText]="StringsKeys.popupPriceTransactionFeeTitle | toString"
      [rightText]="priceInfo.fee.amount + ' ' + priceInfo.fee.currency"
      [rightIconSrc]="'./assets/images/'+(priceInfo.fee.currency | lowercase)+ '.svg'"
    ></lpt-info-row-item>
  </div>

  <div class="buy-joker-games__action-buttons w-100 flex-between">
    <button class="btn btn-orange" id="buy-games-buy-button" (click)="buyGames()">
      {{StringsKeys.btnBuyFreeGames | toString}}
    </button>

    <div class="txt-secondary fz-14 p-20">{{StringsKeys.popupBuyFreeGamesOrText | toString}}</div>

    <div class="flex-between w-100">
      <button class="btn btn-white" id="buy-games-close-button" (click)="closeDialog()">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button class="btn btn-orange" id="buy-games-to-marketplace-button" (click)="toMarketPlace()">
        {{StringsKeys.btnMarketplace | toString}}
      </button>
    </div>
  </div>
</div>

import { EGameResult } from './game.types';

export interface GamingHistoryStatItem {
  total: number;
  won: number;
  lost: number;
  draw: number;
}

export interface GamingHistoryGameItem {
  readonly duration: number;
  readonly endTime: string;
  readonly gameId: string;
  readonly gameResult: EGameResult;
  readonly myId: string;
  readonly myJokerImageUrl: string;
  readonly myNickname: string;
  readonly myTokenId: string;
  readonly opponentId: string;
  readonly opponentJokerImageUrl: string;
  readonly opponentNickname: string;
  readonly opponentTokenId: string;
  readonly reward: string;
  readonly startTime: string;
}

export enum GameRecordStatus {
  Enqueued = 'enqueued',
  Processing = 'processing',
  Failed = 'failed',
  Completed = 'completed',
}
export type GameRecordResp = {
  readonly status: GameRecordStatus;
  readonly url: string;
};

import { NavigationItem, NavigationItemIdentifier } from '@types';

export const footerNavigationItems: NavigationItem[] = [
  {
    icon: './assets/images/header-navigation/home.svg',
    activeIcon: './assets/images/header-navigation/home-active.svg',
    route: '/home',
    iconHeight: 32,
    iconWidth: 32,
    text: NavigationItemIdentifier.Dashboard,
  },
  {
    icon: './assets/images/header-navigation/earn.svg',
    activeIcon: './assets/images/header-navigation/earn-active.svg',
    route: '/home/earn',
    iconHeight: 32,
    iconWidth: 32,
    text: NavigationItemIdentifier.Earn,
  },
  {
    icon: './assets/images/header-navigation/friends.svg',
    activeIcon: './assets/images/header-navigation/friends-active.svg',
    route: '/home/friends',
    iconHeight: 32,
    iconWidth: 32,
    text: NavigationItemIdentifier.Friends,
  },
  // TODO: hidden temporarily
  // {
  //   icon: './assets/images/header-navigation/marketplace.svg',
  //   activeIcon: './assets/images/header-navigation/marketplace-active.svg',
  //   route: '/home/marketplace',
  //   iconHeight: 32,
  //   iconWidth: 32,
  //   text: NavigationItemIdentifier.Marketplace,
  // },
  {
    icon: './assets/images/header-navigation/wallet.svg',
    activeIcon: './assets/images/header-navigation/wallet-active.svg',
    route: '/home/wallet',
    iconHeight: 32,
    iconWidth: 32,
    text: NavigationItemIdentifier.Wallet,
  },
];

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangeSelectorComponent } from './range-selector.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

@NgModule({
  declarations: [RangeSelectorComponent],
  imports: [CommonModule, NgxSliderModule],
  exports: [RangeSelectorComponent],
})
export class RangeSelectorModule {}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideAddress',
})
export class HideAddressPipe implements PipeTransform {
  transform(address: string): unknown {
    const startIndexToReplace = 15; // displaying first 15 characters of address. Could  replace to dynamic argument in the future
    const endIndexToReplace = address.length - 1;

    const addressBeginning = address.slice(0, startIndexToReplace);
    const addressEnding = address.slice(address.length - 5, endIndexToReplace);

    return addressBeginning + '...' + addressEnding;
  }
}

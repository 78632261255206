import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { DailyRewardWidgetComponent } from '../daily-reward-widget/daily-reward-widget.component';
import { DialogRef } from '@ngneat/dialog';
import { GoogleAnalyticsEventsType, StringsKeys } from '@types';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToStringPipeModule } from '@pipes';
import { PointsComponent } from '../../points/points.component';
import { AsyncPipe } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'lpt-daily-reward-dialog',
  templateUrl: './daily-reward-dialog.component.html',
  styleUrls: ['./daily-reward-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DailyRewardWidgetComponent, AngularSvgIconModule, ToStringPipeModule, PointsComponent, AsyncPipe],
})
export class DailyRewardDialogComponent implements OnInit {
  public readonly gaService = inject(GoogleAnalyticsService);
  public readonly dialogRef = inject(DialogRef);
  public readonly StringsKeys = StringsKeys;
  public points!: number;

  ngOnInit(): void {
    Object.assign(this, this.dialogRef.data);
  }

  close(): void {
    this.gaService.event(GoogleAnalyticsEventsType.dailyGameRewardOk);
    this.dialogRef.close();
  }
}

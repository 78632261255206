import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationItem } from '@types';
import { isIos, isTelegramWebView } from '@helpers';

@Component({
  selector: 'lpt-navigation-panel',
  templateUrl: './navigation-panel.component.html',
  styleUrls: ['./navigation-panel.component.scss'],
})
export class NavigationPanelComponent {
  @Input() public navItems: NavigationItem[];
  @Input() public verticalAppearance = true;
  @Input() public textColor: 'txt-footer-items' | 'txt-secondary' = 'txt-secondary';

  public readonly isTelegramWebView = isTelegramWebView();
  public readonly isIos = isIos();

  constructor(public router: Router) {}
}

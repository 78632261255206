import html2canvas from 'html2canvas';

export const snapshotToBlob = (): Promise<Blob | null> => {
  return new Promise((resolve) => {
    try {
      html2canvas(document.body, {
        allowTaint: true,
        useCORS: true,
      }).then((canvas) => {
        return canvas.toBlob((blob) => resolve(blob));
      });
    } catch {
      resolve(null);
    }
  });
};

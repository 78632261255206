import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyType } from '@types';

@Pipe({
  name: 'displayCurrency',
  standalone: true,
})
export class DisplayCurrencyPipe implements PipeTransform {
  private readonly _currenciesMap = new Map<CurrencyType, string>([
    [CurrencyType.LRT, 'LOL'],
    [CurrencyType.NFT, 'NFT'],
    // TODO: LPT-1255
    // Just as we have integrated with Telegram API, need to display Telegram token instead of real BNB
    [CurrencyType.BNB, 'TON'],
  ]);

  transform(type: CurrencyType): unknown {
    return this._currenciesMap.get(type);
  }
}

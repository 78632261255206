import { FaceExpressions } from 'face-api.js';

export type SingleModeDetectionReturnType = {
  expressions: FaceExpressions | undefined;
  headBounce: number | undefined;
};

export type SingleModeDetectionProps =
  | FaceExpressions
  | {
      timestamp: number;
    };

/**
 * @note each time property defined in seconds
 */
export interface SingleModeSettingsModelProps {
  gameInterval: number;
  gameDuration: number;
  timeTillNextGame: number | null;
  multiplier: number;
  canClaimOpenAppReward: boolean;
  canClaimStartGameReward: boolean;
  lastAppOpenAtMultiplier: number;
  lastGameStartAtMultiplier: number;
}

export class SingleModeSettingsModel {
  public gameInterval!: number;
  public gameDuration!: number;
  public timeTillNextGame!: number;
  public multiplier!: number;
  public canClaimOpenAppReward!: boolean;
  public canClaimStartGameReward!: boolean;
  public lastAppOpenAtMultiplier!: number;
  public lastGameStartAtMultiplier!: number;

  public nextGameDate!: number;
  public lastGameDate!: number;

  constructor(props: SingleModeSettingsModelProps) {
    Object.assign(this, props);
    if (props.timeTillNextGame === null) {
      this.timeTillNextGame = 0;
    }
    this.lastGameDate = Date.now() - (this.gameInterval - this.timeTillNextGame) * 1e3;
    this.nextGameDate = Date.now() + this.timeTillNextGame * 1e3;
  }

  update(): void {
    const newTimeTillNextGame = (this.nextGameDate - Date.now()) / 1e3;
    this.timeTillNextGame = newTimeTillNextGame >= 0 ? Math.ceil(newTimeTillNextGame) : 0;
  }

  get timeFromLastGame(): number {
    return this.gameInterval - this.timeTillNextGame;
  }
}

export interface Points {
  pointsTotal: number;
  pointsGained: number;
}

export enum SingleModeSessionStage {
  PREPARATION,
  SMILING,
  RESULT,
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { AUTH_CODE_MASK } from '@constants';
// Should be imported via global path in order to avoid circular injection
import { SignupStateService } from '../../../services/src/signup-state.service';
import { ProfileService } from '@services';

@Component({
  selector: 'lpt-change-email-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SignupStateService],
})
export class DeleteAccountDialogComponent {
  public StringsKeys = StringsKeys;
  public readonly mask = AUTH_CODE_MASK;
  public email: string;
  public submitterId: 'send-otp' | 'delete';

  constructor(
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef,
    public state: SignupStateService,
    public profileService: ProfileService
  ) {
    this.email = this.dialogRef.data.email;
    this.state.initForm();
    this.state.form.patchValue({
      email: this.email,
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.submitterId === 'send-otp') {
      this.state.sendOtpCode('delete_account');
      return;
    }

    this.delete();
  }
  public delete(): void {
    this.state.form.markAllAsTouched();
    this.cdr.detectChanges();

    if (this.state.form.invalid || this.state.loader.isLoading) {
      return;
    }

    const value = this.state.form.getRawValue();

    this.profileService.deleteAccount(value.otp).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: ({ status }) => {
        if (status === 406) {
          this.state.form.controls['email'].setErrors({ email: true });
        }
        if (status === 422) {
          this.state.form.controls['otp'].setErrors({ otp: true });
        }
        this.cdr.detectChanges();
      },
    });
  }
}

<ng-container *ngIf="gameStateService.currentJoker$ |async as currentJoker">
  <div
    class="effects__panel flex-between w-100"
    [ngClass]="{
      'hide': (gameStateService.gameResultData$ | async),
      'grayscale': (effectsService.hasActiveTimer$ | async)
    }"
  >
    <div class="effects__panel-item">
      <ul class="flex-center">
        <ng-container *ngFor="let effectObj of currentJoker.effects">
          <ng-container *ngIf="effectObj.effect.type === eEffectType.SOUND">
            <li
              class="effects__panel-item:icon cursor-pointer"
              id="apply-sound-effect-icon-button"
              (click)="applyEffect(effectObj.effect)"
            >
              <img [src]="effectObj.effect.iconUrl" />
            </li>
          </ng-container>
        </ng-container>
      </ul>
      <p class="effects__panel-item:subtitle">{{ StringsKeys.gameSoundsTitle | toString }}</p>
    </div>
    <div class="effects__panel-divider"></div>
    <div class="effects__panel-item">
      <ng-container *ngFor="let effectObj of currentJoker.effects">
        <ng-container *ngIf="effectObj.effect.type === eEffectType.ANIMATION">
          <div
            class="effects__panel-item:icon cursor-pointer"
            id="apply-animation-effect-icon-button"
            (click)="applyEffect(effectObj.effect)"
          >
            <img [src]="effectObj.effect.iconUrl" />
          </div>
        </ng-container>
      </ng-container>
      <p class="effects__panel-item:subtitle">{{ StringsKeys.gameAnimationsTitle | toString }}</p>
    </div>
  </div>

  <ng-container *ngIf="gameStateService.gameResultData$ | async as result">
    <svg-icon
      class="effects__panel-result"
      [ngClass]="result.gameResult"
      src="./assets/images/game-result/{{result.gameResult}}.svg"
    ></svg-icon>
  </ng-container>
</ng-container>

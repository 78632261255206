import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReferralsDto, ResponseDto } from '@types';
import { catchError, map, Observable, take } from 'rxjs';
import { DialogHelperService } from './dialog-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReferralsService {
  constructor(
    private readonly http: HttpClient,
    private readonly dialogHelperService: DialogHelperService
  ) {}

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Referral%20Program/ReferralProgramController_getReferrals
   */
  public getReferrals(): Observable<ReferralsDto> {
    return this.http.get<ResponseDto<ReferralsDto>>(`${environment.apiUrl}/referral-program/referrals`).pipe(
      take(1),
      map(({ result }) => result),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Referral%20Program/ReferralProgramController_getReferralLink
   */
  public getReferralLink(): Observable<string> {
    return this.http.get<ResponseDto<{ url: string }>>(`${environment.apiUrl}/referral-program/referral-link`).pipe(
      take(1),
      map(({ result }) => result.url),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }
}

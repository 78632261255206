<ng-container *ngIf="joker | async as jokerValue">
  <div
    class="level-up-update-time__container dialog__container"
    *ngIf="getJokerAttributeByType(jokerValue, 'level') as levelAttribute"
  >
    <h3 class="level-up-update-time__title">
      {{StringsKeys.levelUpPopupTitle | toString : { currentLevel: levelAttribute.value, newLevel: +levelAttribute.value + 1 } }}
    </h3>

    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="level-up-update-time__title pt-10">#{{jokerValue.tokenId}}</h3>

    <ng-container
      *ngIf="{
    timeLeft: timer$ | async,
    allDuration: allLevelUpTime | async
    } as remainingTimeData"
    >
      <ng-container *ngIf="remainingTimeData.timeLeft && remainingTimeData.allDuration">
        <div class="w-100">
          <div class="heal-joker__craziness-title flex-between pb-10 txt-primary">
            <div>
              {{StringsKeys.levelUpPopupTitle | toString : { currentLevel:levelAttribute.value , newLevel: +levelAttribute.value +
              1, } }}
            </div>
            <div>
              {{((remainingTimeData.allDuration - remainingTimeData.timeLeft) / remainingTimeData.allDuration) | percent }}
            </div>
          </div>

          <lpt-range-selector
            [minRangeValue]="0"
            [maxRangeValue]="remainingTimeData.allDuration"
            [minValue]="remainingTimeData.allDuration - remainingTimeData.timeLeft"
            [maxValue]="remainingTimeData.allDuration - remainingTimeData.timeLeft"
            [singleSelector]="true"
            [displayMinMax]="false"
            [disabled]="true"
          ></lpt-range-selector>
        </div>

        <div class="level-up-update-time__info w-100">
          <lpt-info-row-item
            class="d-block w-100"
            [leftText]="StringsKeys.levelUpPopupRemainingTime | toString"
            [rightText]="remainingTimeData.timeLeft | remainingTime"
          ></lpt-info-row-item>
        </div>
      </ng-container>
    </ng-container>

    <div class="level-up-update-time__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="level-up-update-time-close-button" (click)="closeDialog()">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button class="btn btn-orange" id="level-up-update-time-boost-button" (click)="boost()">
        {{StringsKeys.btnBoost | toString}}
      </button>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DesktopHeaderComponent } from './desktop-header.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavigationPanelModule } from '@components';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DesktopHeaderComponent],
  imports: [CommonModule, AngularSvgIconModule, RouterModule, NavigationPanelModule],
  exports: [DesktopHeaderComponent],
})
export class DesktopHeaderModule {}

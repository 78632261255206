import { EEffectType, EJokerKlass, IJokerEffectsObject, INFTAttribute, JokerModel, SwiperItem } from '@types';
import { JOKER_CLASS_COLOR } from '@constants';
import { LocalStorageService } from '@services';

export const getSwiperJokerInfo = (joker: JokerModel): SwiperItem => {
  return {
    imageUrl: joker.metadata.image,
    label: joker.tokenId,
    isOnSale: joker.isOnSale,
  };
};

export const getJokerAttributeByType = (joker: JokerModel, traitType: string): INFTAttribute | undefined => {
  return joker.metadata.attributes.find((attribute) => attribute.traitType === traitType);
};

export const getJokerClassColor = (jokerClass: EJokerKlass): string => {
  return JOKER_CLASS_COLOR[jokerClass];
};

export const getJokerSoundEffects = (joker: JokerModel): IJokerEffectsObject[] => {
  return joker.effects.filter((eff) => eff.effect.type === EEffectType.SOUND);
};

export const getJokerAnimationEffects = (joker: JokerModel): IJokerEffectsObject[] => {
  return joker.effects.filter((eff) => eff.effect.type === EEffectType.ANIMATION);
};

export const isJokerMine = (joker: JokerModel): boolean => {
  const myId = LocalStorageService.getUser()?.id;
  if (!myId) return false;
  return joker.ownerId === myId;
};

export const isJokerOrdinary = (joker: JokerModel): boolean => joker.klass === EJokerKlass.Ordinary;

export const hasEnoughCrazinessToPlay = (joker: JokerModel): boolean =>
  Boolean(joker?.minExtraCraziness <= getJokerAttributeByType(joker, 'extra_craziness')!.value);

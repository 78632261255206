import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ROOM_ID_MASK } from '@constants';
import { isCompatibleToReadClipboardText } from '@helpers';
import { DialogRef } from '@ngneat/dialog';
import { EJoinPlayModeDialogResultType, IJoinPlayModeDialogResult, StringsKeys } from '@types';

@Component({
  selector: 'lpt-enter-room-id',
  templateUrl: './enter-room-id.component.html',
  styleUrls: ['./enter-room-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterRoomIdComponent implements OnInit {
  public readonly StringsKeys = StringsKeys;
  public readonly mask = ROOM_ID_MASK;
  public control: FormControl = new FormControl();

  constructor(
    private readonly dialogRef: DialogRef<unknown, IJoinPlayModeDialogResult>,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.control = this.fb.control('', { validators: [Validators.required, Validators.minLength(7)] });
  }

  public async paste(): Promise<void> {
    if (isCompatibleToReadClipboardText()) {
      const clipText = await navigator.clipboard.readText();
      this.control.setValue(clipText);
    } else {
      // todo: clarify issue for Firefox
      // [More]: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard#reading_from_the_clipboard
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public scan(): void {
    this.dialogRef.close({
      type: EJoinPlayModeDialogResultType.Scan,
    });
  }

  public join(): void {
    if (this.control.invalid) {
      return;
    }
    this.dialogRef.close({
      type: EJoinPlayModeDialogResultType.RoomId,
      roomId: this.control.value,
    });
  }
}

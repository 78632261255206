import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { INFTAttribute, JokerModel } from '@types';
import { getJokerAttributeByType, getJokerClassColor } from '@helpers';

@Component({
  selector: 'lpt-joker-stats',
  templateUrl: './joker-stats.component.html',
  styleUrls: ['./joker-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerStatsComponent {
  @Input() public joker: JokerModel;

  public get crazinessAttribute(): INFTAttribute | undefined {
    return getJokerAttributeByType(this.joker, 'extra_craziness');
  }

  public get levelAttribute(): INFTAttribute | undefined {
    return getJokerAttributeByType(this.joker, 'level');
  }

  public getJokerClassColor = getJokerClassColor;
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { CurrencyType, ResponseDto, Transaction, WalletInfo } from '@types';
import { environment } from '../../../../environments/environment';
import camelcaseKeys from 'camelcase-keys';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { DialogHelperService } from './dialog-helper.service';
import { SingleModeService } from './single-mode.service';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private walletInfo: Subject<WalletInfo> = new Subject<WalletInfo>();
  public walletInfo$: Observable<WalletInfo> = this.walletInfo.asObservable();

  private transactions: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>([]);
  public transactions$: Observable<Transaction[]> = this.transactions.asObservable();

  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private dialogHelperService: DialogHelperService,
    private singleModeService: SingleModeService
  ) {}

  public getUserBalance(): Observable<WalletInfo> {
    return this.http.get<ResponseDto<WalletInfo>>(`${environment.apiUrl}/blockhains/BNB`).pipe(
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      // TODO: LPT-1262
      //  Just as we have only single mode for now, instead of showing BNB balance,
      //  we need to show single mode points.
      switchMap((wallet) => {
        return this.singleModeService.getPoints().pipe(
          map((points) => {
            (wallet.balance as any).earningToken = points.pointsTotal.toString();
            return wallet;
          })
        );
      }),
      tap((walletInfo) => {
        // TODO: LPT-1255
        //  Replace wallet address to hardcoded one
        walletInfo.wallet.address = 'UQCKRK_HlsYXFWxXwdFWYeEiTFCFFjWJbsIrVxLPDHcIa5Dd';
      }),
      tap((walletInfo) => {
        this.walletInfo.next(walletInfo);
      })
    );
  }

  public getUserCryptoTransactions(coinCode: CurrencyType): Observable<Transaction[]> {
    this.spinnerService.showGlobalSpinner();
    if (coinCode === CurrencyType.NFT) {
      this.spinnerService.hideGlobalSpinner();

      return of([]).pipe(
        tap((transactions) => {
          this.transactions.next(transactions);
        })
      ); // Temporary since this token isn't supported
    }

    return this.http
      .get<ResponseDto<Transaction[]>>(`${environment.apiUrl}/transactions/${coinCode}`, {
        params: {
          // TODO add pagination
          page: 0,
          limit: 20,
        },
      })
      .pipe(
        tap(() => this.spinnerService.hideGlobalSpinner()),
        map(({ result, paging }) => camelcaseKeys(result, { deep: true })),
        tap((transactions) => {
          this.transactions.next(transactions);
        }),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }
}

export interface AppSetting {
  id: string;
  name: string;
  value: number;
}

export enum AppSettingKeys {
  ReferralInviteReward = 'REFERRAL_PROGRAM_INVITE_REWARD',
  HeadBounce = 'Head bounce',
  EarnDailyPlayGame = 'EARN_DAILY_PLAY_GAME',
  EarnDailyOpenApp = 'EARN_DAILY_OPEN_APP',
}

<div class="add-joker-points__container dialog__container">
  <h3 class="add-joker-points__title">{{StringsKeys.addPointsPopupTitle | toString}}</h3>
  <div class="available-points__amount flex-between w-100">
    <div class="fz-18 txt-secondary">{{StringsKeys.addPointsPopupAvailablePointsTitle | toString}}</div>
    <div class="fz-18 txt-primary">{{availablePoints - (formPointsSum - initialPointsSum)}}</div>
  </div>

  <div class="w-100" [formGroup]="formGroup">
    <lpt-select-points-amount
      *ngFor="let control of controls"
      [formControlName]="control[0]"
      [initialValue]="+initialAttributes[control[0]].value"
      [maxValue]="+(initialAttributes[control[0]]!.maxValue || 50)"
      [canAddPoints]="hasAvailablePoints"
      class="select-points-item w-100"
      [iconSrc]="'./assets/images/' + control[0] + '.svg'"
      [title]="control[0] | titlecase"
    ></lpt-select-points-amount>
  </div>

  <div class="add-joker-points__action-buttons w-100 flex-between">
    <button class="btn btn-white" id="add-joker-points-dialog-close-button" (click)="closeDialog()">
      {{StringsKeys.btnClose | toString}}
    </button>
    <button class="btn btn-orange" id="add-joker-points-dialog-confirm-button" (click)="confirmAddPoints()">
      {{StringsKeys.btnConfirm | toString}}
    </button>
  </div>
</div>

import { ComponentRef, Injectable } from '@angular/core';
import {
  ActivePlayerIssue,
  EGameBlurredPlayerType,
  EParticipantState,
  IWsGameTimerDataDto,
  PlayerIssueModalState,
  StringsKeys,
} from '@types';
import { DialogHelperService } from '../dialog-helper.service';
import { DialogService } from '@ngneat/dialog';
import { BehaviorSubject } from 'rxjs';
import { ToStringService } from '../to-string.service';
import { PlayerIssueDialogComponent } from '@components';

@Injectable({
  providedIn: 'root',
})
export class PlayerIssuesService {
  private readonly _blurredPlayers = new BehaviorSubject<EGameBlurredPlayerType | null>(null);
  readonly blurredPlayers$ = this._blurredPlayers.asObservable();

  private activePlayerIssue: ActivePlayerIssue | undefined;

  private localPlayerIssues?: PlayerIssueModalState;
  private opponentPlayerIssues?: PlayerIssueModalState;

  private openedIssueDialogRef?: ReturnType<DialogService['open']>;

  constructor(
    private dialogHelperService: DialogHelperService,
    private toStringService: ToStringService
  ) {}

  public handleIssues(
    props: IWsGameTimerDataDto,
    localPlayerId: string,
    opponentPlayerId: string,
    twilioRoomState: EParticipantState
  ) {
    if (twilioRoomState !== EParticipantState.CONNECTED) {
      return this.setBlurOnFullLayout();
    }

    this.localPlayerIssues = {
      ...props.playersIssues.find((issues) => issues.id === localPlayerId),
      connection: !props.activePlayerIds.includes(localPlayerId),
    };
    this.opponentPlayerIssues = {
      ...props.playersIssues.find((issues) => issues.id === opponentPlayerId),
      connection: !props.activePlayerIds.includes(opponentPlayerId),
    };

    let newActivePlayerIssue: ActivePlayerIssue | undefined;

    // Step [1]: check local brightness
    const hasOpenedLocalBrightnessIssueModal = Boolean(this.localPlayerIssues?.light);
    if (hasOpenedLocalBrightnessIssueModal) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.FullLayout,
        stringKey: StringsKeys.warningGameLighting,
      };
    }

    // Step [2]: check local face visibility
    const hasOpenedLocalFaceVisibilityIssueModal = Boolean(this.localPlayerIssues?.face);
    if (hasOpenedLocalFaceVisibilityIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.FullLayout,
        stringKey: StringsKeys.warningGameFaceNotDetected,
      };
    }

    // Step [3]: check local face bounding
    const hasOpenedLocalHeadBounceIssueModal = Boolean(this.localPlayerIssues?.headBounce);
    if (hasOpenedLocalHeadBounceIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.FullLayout,
        stringKey: StringsKeys.warningGameFaceDistance,
      };
    }

    // Step [4]: check local hand is on frame
    const hasOpenedLocalHandInFrameIssueModal = Boolean(this.localPlayerIssues?.handInFrame);
    if (hasOpenedLocalHandInFrameIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.FullLayout,
        stringKey: StringsKeys.warningGameHandInFrame,
      };
    }

    // Step [5]: check opponent connection
    const isOpponentConnected = props.activePlayerIds.includes(opponentPlayerId);
    const hasOpenedOpponentConnectionIssueModal = !isOpponentConnected;
    if (hasOpenedOpponentConnectionIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.OnlyOpponent,
        stringKey: StringsKeys.warningGameOpponentIsConnecting,
      };
    }

    // Step [6]: check opponent brightness
    const hasOpenedOpponentBrightnessIssueModal = Boolean(this.opponentPlayerIssues?.light);
    if (hasOpenedOpponentBrightnessIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.OnlyOpponent,
        stringKey: StringsKeys.warningGameOpponentLighting,
      };
    }

    // Step [7]: check opponent face visibility
    const hasOpenedOpponentFaceVisibilityIssueModal = Boolean(this.opponentPlayerIssues?.face);
    if (hasOpenedOpponentFaceVisibilityIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.OnlyOpponent,
        stringKey: StringsKeys.warningGameOpponentFaceNotDetected,
      };
    }

    // Step [8]: check opponent face bounding
    const hasOpenedOpponentHeadBounceIssueModal = Boolean(this.opponentPlayerIssues?.headBounce);
    if (hasOpenedOpponentHeadBounceIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.OnlyOpponent,
        stringKey: StringsKeys.warningGameOpponentFaceDistance,
      };
    }

    // Step [9]: check opponent's hand is on frame
    const hasOpenedOpponentHandInFrameIssueModal = Boolean(this.opponentPlayerIssues?.handInFrame);
    if (hasOpenedOpponentHandInFrameIssueModal && !newActivePlayerIssue) {
      newActivePlayerIssue = {
        blurOn: EGameBlurredPlayerType.OnlyOpponent,
        stringKey: StringsKeys.warningGameOpponentHandInFrame,
      };
    }

    this.handleNewPlayerIssue(newActivePlayerIssue);
  }

  private handleNewPlayerIssue(newIssue: ActivePlayerIssue | undefined): void {
    if (newIssue) {
      // ↑ has new issue
      if (this.activePlayerIssue) {
        // ↑ has old issue
        if (newIssue.stringKey !== this.activePlayerIssue.stringKey) {
          // ↑ new issue is different from old issue
          this.activePlayerIssue = newIssue;
          this.activePlayerIssue.blurOn === EGameBlurredPlayerType.OnlyOpponent
            ? this.setBlurOnOpponent()
            : this.setBlurOnFullLayout();
          const playerIssueDialogComponentRef = <ComponentRef<PlayerIssueDialogComponent>>this.openedIssueDialogRef!.ref;
          playerIssueDialogComponentRef.instance.setNewText(this.toStringService.toString(this.activePlayerIssue.stringKey));
        } else {
          // ↑ do nothing. proper issue is already displayed
        }
      } else {
        // ↑ no old issue
        this.activePlayerIssue = newIssue;
        this.activePlayerIssue.blurOn === EGameBlurredPlayerType.OnlyOpponent
          ? this.setBlurOnOpponent()
          : this.setBlurOnFullLayout();
        this.openedIssueDialogRef = this.dialogHelperService.openPlayerIssueDialogWithText(
          this.toStringService.toString(this.activePlayerIssue.stringKey)
        );
      }
    } else {
      // ↑ no new issues;
      if (this.activePlayerIssue) {
        // ↑ has old issue
        this.activePlayerIssue = undefined;
        this.closeOpenedIssueDialogRefWithCatchStatement();
        this.resetBlur();
      }
    }
  }

  public resetBlur(): void {
    if (this._blurredPlayers.value !== null) {
      this._blurredPlayers.next(null);
    }
  }

  public setBlurOnFullLayout(): void {
    if (this._blurredPlayers.value !== EGameBlurredPlayerType.FullLayout) {
      this._blurredPlayers.next(EGameBlurredPlayerType.FullLayout);
    }
  }

  public setBlurOnOpponent(): void {
    if (this._blurredPlayers.value !== EGameBlurredPlayerType.OnlyOpponent) {
      this._blurredPlayers.next(EGameBlurredPlayerType.OnlyOpponent);
    }
  }

  public handleLocalDisconnectionIssue(): void {
    if (this.openedIssueDialogRef?.ref) {
      this.closeOpenedIssueDialogRefWithCatchStatement();
    }
    this.setBlurOnFullLayout();
    this.openedIssueDialogRef = this.dialogHelperService.openPlayerIssueDialogWithText(
      this.toStringService.toString(StringsKeys.warningGameConnectionLost)
    );
  }

  private closeOpenedIssueDialogRefWithCatchStatement(): void {
    try {
      // todo: it makes sense to investigate the root of issue for the future
      // The problem is:
      // execution of DialogRef 'close' method sometimes throws unexpected error.
      // execution of 'closeAllDialogs' within catchable block is not good solution.
      this.openedIssueDialogRef?.close();
    } catch (error) {
      this.dialogHelperService.closeAllDialogs();
    }
  }

  public reset(): void {
    this.resetBlur();
    this.localPlayerIssues = undefined;
    this.opponentPlayerIssues = undefined;
    this.closeOpenedIssueDialogRefWithCatchStatement();
    this.openedIssueDialogRef = undefined;
    this.activePlayerIssue = undefined;
  }
}

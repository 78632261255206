<header class="flex-end w-100">
  <button class="close" (click)="dialogRef.close()"></button>
</header>

<img src="./assets/images/multiplication.png" alt="Multiplication" />

<p>{{ StringsKeys.textSingleModeMultiplicationPopup | toString }}</p>

<button class="btn btn-white w-100" id="multiplication-dialog-ok-button" (click)="dialogRef.close()">
  {{ StringsKeys.btnOK | toString }}
</button>

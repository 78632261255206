import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EReportType } from '@types';
import { Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}

  public report(type: EReportType, opponentId: string, blob?: Blob): Observable<unknown> {
    const formData = new FormData();

    formData.append('defendantId', opponentId);
    if (blob) {
      formData.append('screen', blob);
    }

    return this.http.post(`${environment.apiUrl}/reports/${type}`, formData).pipe(take(1));
  }
}

import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalStorageService, NavigationService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private navigationService: NavigationService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return LocalStorageService.hasAccessToken() || this.navigationService.toLogin().then(() => false);
  }
}

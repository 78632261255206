<ng-container *ngIf="isMarketRouteActive$ | async">
  <nav>
    <lpt-balance-header></lpt-balance-header>
  </nav>
</ng-container>

<ng-container *ngIf="isHomeRouteActive$ | async">
  <nav>
    <ul class="flex-between">
      <li>
        <a class="cursor-pointer pos-rel d-block">
          <div class="unread-icon" *ngIf="hasUnreadNotifications$ | async"></div>
          <svg-icon
            class="icon"
            id="header-to-notifications-icon-button"
            src="./assets/images/notification.svg"
            (click)="toNotifications()"
          ></svg-icon>
        </a>
      </li>
      <li>
        <a class="cursor-pointer">
          <svg-icon
            class="icon"
            id="header-to-profile-icon-button"
            src="./assets/images/header-navigation/profile.svg"
            (click)="toProfile()"
          ></svg-icon>
        </a>
      </li>
    </ul>
  </nav>
</ng-container>

import { NavigationItem } from '@types';

export const headerNavigationItems: NavigationItem[] = [
  {
    icon: './assets/images/header-navigation/home.svg',
    activeIcon: './assets/images/header-navigation/home-active.svg',
    route: '/home',
    iconHeight: 32,
    iconWidth: 32,
    text: 'Dashboard',
  },
  {
    icon: './assets/images/header-navigation/marketplace.svg',
    activeIcon: './assets/images/header-navigation/marketplace-active.svg',
    route: '/home/marketplace',
    iconHeight: 32,
    iconWidth: 32,
    text: 'Marketplace',
  },
  {
    icon: './assets/images/header-navigation/wallet.svg',
    activeIcon: './assets/images/header-navigation/wallet-active.svg',
    route: '/home/wallet',
    iconHeight: 32,
    iconWidth: 32,
    text: 'Wallet',
  },
];

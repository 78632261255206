import { Injectable } from '@angular/core';
import {
  connect,
  createLocalTracks,
  LocalAudioTrack,
  LocalParticipant,
  LocalTrack,
  LocalVideoTrack,
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteTrack,
  RemoteVideoTrack,
  Room,
} from 'twilio-video';
import { DialogHelperService } from '../dialog-helper.service';
import { GameStageService } from './stage.service';
import { EGameStage } from '@types';
import { NavigationService } from '../navigation.service';
import { VIDEO_MEDIA_TRACK_CONSTRAINTS } from '@constants';
import { GameLayoutStateService } from './state.service';
import { PlayerIssuesService } from './player-issues.service';

@Injectable()
export class GameTwilioService {
  private _room?: Room;

  public localParticipant: LocalParticipant | null;
  public remoteParticipant: RemoteParticipant | null;

  public localVideoTrack?: LocalVideoTrack | null;
  public localAudioTrack?: LocalAudioTrack | null;

  public remoteVideoTrack?: RemoteVideoTrack | null;
  public remoteAudioTrack?: RemoteAudioTrack | null;

  constructor(
    private dialogHelperService: DialogHelperService,
    private stageService: GameStageService,
    private gameStateService: GameLayoutStateService,
    private navigationService: NavigationService,
    private playerIssuesService: PlayerIssuesService
  ) {}

  public set room(instance: Room | undefined) {
    this._room = instance;
  }

  public get room(): Room | undefined {
    return this._room;
  }

  public async connect(twilioAccessToken: string, roomSid: string): Promise<void> {
    try {
      const tracks = await createLocalTracks({
        audio: true,
        video: VIDEO_MEDIA_TRACK_CONSTRAINTS,
      });

      if (!this.isValidLocalTracks(tracks)) {
        throw new Error('Invalid tracks');
      }

      this.localAudioTrack = (<LocalAudioTrack>tracks[0]).enable();
      this.localVideoTrack = (<LocalVideoTrack>tracks[1]).enable();

      const room = await connect(twilioAccessToken, {
        name: roomSid,
        audio: true,
        video: true,
        tracks: tracks,
      });

      this._room = room;

      this.localParticipant = room.localParticipant;
      this.remoteParticipant = room.participants.values().next().value;
      if (this.remoteParticipant) {
        this.initRemoteParticipantListeners(this.remoteParticipant);
      }
      this.initLocalParticipantListeners(this.localParticipant);
      this.initRoomParticipantListeners();

      return this.stageService.setStage(EGameStage.Call);
    } catch (error: any) {
      if ('message' in error) {
        this.dialogHelperService.openErrorDialogWithText(error.message);
      } else {
        this.dialogHelperService.openDefaultErrorDialog();
      }
      this.navigationService.toDashboard();
    }
  }

  public disconnect(): void {
    this._room?.disconnect();
    if (this.localAudioTrack) {
      this.localAudioTrack.stop();
      this.localAudioTrack.detach();
    }
    if (this.localVideoTrack) {
      this.localVideoTrack.stop();
      this.localVideoTrack.detach();
    }
  }

  private isValidLocalTracks(_tracks: LocalTrack[]): boolean {
    return _tracks.length === 2 && _tracks[0] instanceof LocalAudioTrack && _tracks[1] instanceof LocalVideoTrack;
  }

  private initLocalParticipantListeners(_participant: LocalParticipant): void {
    let isLocalReconnecting = false;

    _participant.on('reconnecting', () => {
      if (!this.gameStateService.isGameFinished()) {
        isLocalReconnecting = true;
        this.playerIssuesService.handleLocalDisconnectionIssue();
      }
    });
    _participant.on('reconnected', () => {
      this.dialogHelperService.closeAllDialogs();
      if (isLocalReconnecting) {
        isLocalReconnecting = false;
        _participant.tracks.forEach((track) => {
          _participant.publishTrack(track.track);
        });
      }
    });
    _participant.on('disconnected', () => {
      if (!this.gameStateService.isGameFinished()) {
        this.playerIssuesService.handleLocalDisconnectionIssue();
      }
    });
  }

  private initRemoteParticipantListeners(_participant: RemoteParticipant): void {
    _participant.on('trackSubscribed', (track) => {
      this.assignRemoteTrack(track);
    });
    _participant.on('trackUnsubscribed', (track) => {
      this.assignRemoteTrack(track);
    });
    _participant.on('trackPublished', ({ track }) => {
      !track || this.assignRemoteTrack(track);
    });
  }

  private initRoomParticipantListeners(): void {
    this._room?.on('participantConnected', (remoteParticipant) => {
      this.remoteParticipant = remoteParticipant;
      this.initRemoteParticipantListeners(this.remoteParticipant);
    });
  }

  private assignRemoteTrack(track: RemoteTrack): void {
    if (track.kind === 'audio') {
      this.remoteAudioTrack = track;
    }
    if (track.kind === 'video') {
      this.remoteVideoTrack = track;
    }
  }
}

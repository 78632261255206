import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { LocalStorageService, NavigationService } from '@services';
import { EHttpHeaderKey } from '../../types/src/http-header-keys.types';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private navigationService: NavigationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isIgnore = request.headers.has(EHttpHeaderKey.IgnoreByInterceptor);
    const hasAccessToken = LocalStorageService.hasAccessToken();

    if (!isIgnore && hasAccessToken) {
      request = request.clone({
        setHeaders: {
          [EHttpHeaderKey.Authorization]: `Bearer ${LocalStorageService.getAccessToken()!}`,
          [EHttpHeaderKey.CacheControl]: 'max-age=1800', // 0.5h
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          void this.navigationService.toLogin();
        }
        return throwError(() => error);
      })
    );
  }
}

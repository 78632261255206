<div class="choose-mode__container">
  <svg-icon class="choose-mode__icon" [src]="'./assets/images/smiling-man.svg'"> </svg-icon>
  <div class="choose-mode__title">{{StringsKeys.playOptionsPopupTitle | toString}}</div>
  <div class="choose-mode__buttons">
    <button class="btn btn-orange" id="choose-play-mode-random-button" (click)="close(playMode.Play)">
      {{StringsKeys.playOptionsPopupPlayRandom | toString}}
    </button>
    <button class="btn btn-orange" id="choose-play-mode-create-button" (click)="close(playMode.Create)">
      {{StringsKeys.playOptionsPopupFriendsCreateRoom | toString}}
    </button>
    <button class="btn btn-orange" id="choose-play-mode-join-button" (click)="close(playMode.Join)">
      {{StringsKeys.playOptionsPopupFriendsJoinRoom | toString}}
    </button>
  </div>
</div>

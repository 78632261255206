export interface IEarnItem {
  id: string;
  type: EarnTaskType;
  title: string;
  actionTitle: string;
  actionLink: string;
  state: EarnTaskState;
  status: EarnTaskStatus;
  reward: number;
  url: string;
}

export interface IEarnItemsDto {
  tasks: Array<IEarnItem>;
  totalEarned: number | null;
}

export enum EarnTaskState {
  Active = 'ACTIVE',
  // Disabled = 'DISABLED',
}

export enum EarnTaskStatus {
  // Unavailable = 'UNAVAILABLE',
  Pending = 'PENDING',
  Activated = 'ACTIVATED',
  Completed = 'COMPLETED',
}

export enum EarnTaskType {
  OpenLink = 'OPEN_LINK',
  FollowTelegramChannel = 'FOLLOW_TELEGRAM_CHANNEL',
}

export const earningTypeIconsSrcMap = new Map([
  [EarnTaskType.OpenLink, './assets/images/earning-open-link.svg'],
  [EarnTaskType.FollowTelegramChannel, './assets/images/earning-follow-channel.svg'],
]);

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NavigatorService {
  public share(title: string, text: string, url: string): void {
    if (navigator.share) {
      navigator.share({
        title,
        text,
        url,
      });
    }
  }
}

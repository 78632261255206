<ng-container *ngIf="joker | async as jokerValue">
  <div class="level-up-joker__container dialog__container">
    <h3 class="level-up-joker__title">{{StringsKeys.jokerLevelUpTitle | toString}}</h3>

    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="level-up-joker__title pt-10">#{{jokerValue.tokenId}}</h3>

    <div class="level-up-joker__info w-100" *ngIf="levelUpInfo | async as levelUpInfoItem">
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="StringsKeys.popupPricePriceTitle | toString"
        [rightText]="levelUpInfoItem.price.amount + ' ' + levelUpInfoItem.price.currency"
        [rightIconSrc]="getTokenIconByCurrencyCode(levelUpInfoItem.price.currency)"
      ></lpt-info-row-item>
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="StringsKeys.popupPriceTransactionFeeTitle | toString"
        [rightText]="(levelUpInfoItem.fee.amount | number: '1.0-5') + ' ' + levelUpInfoItem.fee.currency"
        [rightIconSrc]="getTokenIconByCurrencyCode(levelUpInfoItem.fee.currency)"
      ></lpt-info-row-item>
      <lpt-info-row-item
        *ngIf="timerCooldown | async as timerCooldownItem"
        class="d-block w-100"
        [leftText]="StringsKeys.levelUpPopupTime | toString"
        [rightText]="(timerCooldownItem | timeWithPostfix)"
      ></lpt-info-row-item>
    </div>

    <div class="level-up-joker__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="joker-level-up-close-button" (click)="closeDialog()">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button class="btn btn-orange" id="joker-level-up-confirm-button" (click)="confirmLevelUp()">
        {{StringsKeys.btnConfirm | toString}}
      </button>
    </div>
  </div>
</ng-container>

export interface WalletInfo {
  readonly balance: BalanceAmount;
  readonly wallet: {
    address: string;
  };
}
export interface BalanceAmount {
  readonly baseAsset: string;
  readonly earningToken: string;
  readonly governanceToken: string;
}

export interface BalanceLoopItem {
  readonly name: keyof BalanceAmount;
  readonly precision: string;
  readonly icon: string;
}

export const enum OperationType {
  in = 'in',
  out = 'out',
  transfer = 'transfer',
}

export const enum StatusType {
  confirmed = 'confirmed',
  failed = 'failed',
  canceled = 'canceled',
}

export enum CurrencyType {
  LRT = 'LRT',
  NFT = 'LYT',
  BNB = 'BNB',
}

export interface Transaction {
  readonly date: string;
  readonly amount: {
    value: string;
    currency: CurrencyType;
  };
  readonly operation: OperationType;
  readonly status: StatusType;
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsPanelComponent } from './effects-panel.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [EffectsPanelComponent],
  imports: [CommonModule, AngularSvgIconModule, ToStringPipeModule],
  exports: [EffectsPanelComponent],
})
export class EffectsPanelModule {}

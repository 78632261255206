import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES_ENUM } from '@constants';
import { DesktopGuard } from '@guards';
import { TelegramLoginRedirectComponent } from './modules/auth-layout/children/telegram-login-redirect/telegram-login-redirect.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [DesktopGuard],
    canActivateChild: [DesktopGuard],
    children: [
      {
        path: ROUTES_ENUM.HOME,
        loadChildren: () => import('./modules/home-layout/home-layout.module').then((m) => m.HomeLayoutModule),
      },
      {
        path: ROUTES_ENUM.GAME,
        loadChildren: () => import('./modules/game-layout/game-layout.module').then((m) => m.GameLayoutModule),
      },
      {
        path: ROUTES_ENUM.LOGIN,
        loadChildren: () => import('./modules/auth-layout/auth-layout.module').then((m) => m.AuthLayoutModule),
      },
      {
        path: ROUTES_ENUM.TELEGRAM_LOGIN_REDIRECT,
        component: TelegramLoginRedirectComponent,
      },
      {
        path: ROUTES_ENUM.JOKERS,
        loadChildren: () => import('./modules/jokers-layout/jokers-layout.module').then((m) => m.JokersLayoutModule),
      },
      {
        path: ROUTES_ENUM.PROFILE,
        loadChildren: () => import('./modules/profile-layout/profile-layout.module').then((m) => m.ProfileLayoutModule),
      },
      {
        path: ROUTES_ENUM.ROOMSMAKING,
        loadChildren: () =>
          import('./modules/roomsmaking-layout/roomsmaking-layout.module').then((m) => m.RoomsmakingLayoutModule),
      },
      // todo: clarify if ready to remove
      // Entities within this module were added for testing detection models and may be removed in any time.
      {
        path: 'faceapitest',
        loadChildren: () =>
          import('./modules/ml-kit-investigation/ml-kit-investigation.module').then((m) => m.MlKitInvestigationModule),
      },
      {
        path: ROUTES_ENUM.NOTIFICATIONS,
        loadChildren: () =>
          import('./modules/notifications-layout/notification-layout.module').then((m) => m.NotificationLayoutModule),
      },
      {
        path: ROUTES_ENUM.ONBOARDING,
        loadChildren: () => import('./modules/onboarding/onboarding.module').then((m) => m.OnboardingModule),
      },
      {
        path: ROUTES_ENUM.SINGLE_MODE,
        loadChildren: () =>
          import('./modules/single-mode-layout/single-mode-layout.module').then((m) => m.SingleModeLayoutModule),
      },
      {
        path: '**',
        redirectTo: ROUTES_ENUM.HOME,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

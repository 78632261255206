import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketFilterParams } from '@types';
import { Colors, isDefaultParams } from '@constants';

@Component({
  selector: 'lpt-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterPanelComponent {
  @Input() public filterParams: MarketFilterParams;
  @Output() public sortIconClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() public filterIconClicked: EventEmitter<void> = new EventEmitter<void>();

  get getFilterColor(): string {
    return isDefaultParams(this.filterParams) ? Colors.Text_Secondary : Colors.Orange;
  }

  get getSortColor(): string {
    return this.filterParams.sortDirection ? Colors.Orange : Colors.Text_Secondary;
  }
}

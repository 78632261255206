export function isCompatibleToVideoCall(): boolean {
  return !!(
    navigator.mediaDevices &&
    (navigator.mediaDevices.getUserMedia ||
      (navigator.mediaDevices as any).webkitGetUserMedia ||
      (navigator.mediaDevices as any).mozGetUserMedia ||
      (navigator.mediaDevices as any).msGetUserMedia)
  );
}

export function isCompatibleToReadClipboardText(): boolean {
  return !!(navigator.clipboard && navigator.clipboard.readText);
}

export function isCompatibleToShare(): boolean {
  return Boolean(navigator.share);
}

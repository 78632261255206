import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MarketOrder, StringsKeys } from '@types';
import { getJokerAttributeByType, getJokerClassColor } from '@helpers';

@Component({
  selector: 'lpt-joker-market-card',
  templateUrl: './joker-market-card.component.html',
  styleUrls: ['./joker-market-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerMarketCardComponent {
  public StringsKeys = StringsKeys;
  @Input() public marketOrder: MarketOrder;
  @Output() public revokeMyOrder: EventEmitter<MarketOrder> = new EventEmitter<MarketOrder>();
  @Output() public buyJoker: EventEmitter<MarketOrder> = new EventEmitter<MarketOrder>();

  public getJokerAttributeByType = getJokerAttributeByType;
  public getJokerClassColor = getJokerClassColor;

  public buyJokerClick($event: MouseEvent, marketOrder: MarketOrder): void {
    $event.stopPropagation();
    this.buyJoker.emit(marketOrder);
  }

  public revokeJokerClick($event: MouseEvent, marketOrder: MarketOrder): void {
    $event.stopPropagation();
    this.revokeMyOrder.emit(marketOrder);
  }
}

<div class="joker__container dialog__container">
  <svg-icon *ngIf="icon" [src]="icon" class="pb-10"></svg-icon>
  <h3 class="joker__title" *ngIf="title">{{title}}</h3>
  <h3 class="txt-secondary p-10" *ngIf="subTitle">{{subTitle}}</h3>

  <ng-container *ngIf="joker | async as jokerValue">
    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="joker__title pt-10" *ngIf="jokerValue.tokenId">#{{jokerValue.tokenId}}</h3>
  </ng-container>

  <div class="joker__info w-100" *ngIf="rows | async as rowsItem">
    <lpt-info-row-item
      *ngFor="let row of rowsItem"
      class="d-block w-100"
      [leftIconSrc]="row.leftIconSrc"
      [leftText]="row.leftText"
      [rightText]="row.rightText"
      [rightIconSrc]="row.rightIconSrc"
      [backgroundColor]="row.backgroundColor"
    ></lpt-info-row-item>
  </div>

  <div class="joker__action-buttons w-100 flex-between">
    <button
      class="btn btn-white"
      id="generic-joker-confirm-dialog-close-button"
      (click)="dialogRef.close(false)"
      *ngIf="closeBtn"
    >
      {{closeBtn}}
    </button>
    <button
      class="btn btn-orange"
      id="generic-joker-confirm-dialog-confirm-button"
      (click)="dialogRef.close(true)"
      *ngIf="confirmBtn"
    >
      {{confirmBtn}}
    </button>
  </div>
</div>

<ng-container *ngIf="joker | async as jokerValue">
  <div class="sell-joker__container dialog__container">
    <h3 class="sell-joker__title">Sell</h3>

    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="sell-joker__title pt-10">#{{jokerValue.tokenId}}</h3>

    <lpt-joker-stats class="pt-10" [joker]="jokerValue"></lpt-joker-stats>

    <div class="sell-joker__amount" *ngIf="!(sellingInfo | async);else sellingInfoTemp">
      <div class="sell-joker__amount-label">Selling price</div>
      <lpt-input
        [formControl]="sellAmountControl"
        [type]="'number'"
        [placeholder]='"Enter selling price"'
        [clearable]="true"
        [invalid]="!sellAmountControl.valid && sellAmountControl.touched"
      ></lpt-input>
      <div
        class="shared-error"
        [style.visibility]="(!sellAmountControl.valid && sellAmountControl.touched) ? 'visible' : 'hidden'"
      >
        {{getValidationError}}
      </div>
    </div>

    <ng-template #sellingInfoTemp>
      <div class="sell-joker__info w-100" *ngIf="sellingInfo | async as sellingInfoItem">
        <lpt-info-row-item
          class="d-block w-100"
          [leftText]="'Selling price'"
          [rightText]="sellingInfoItem.sellingPrice + ' ' + sellingInfoItem.asset"
          [rightIconSrc]="'./assets/images/bnb.svg'"
        ></lpt-info-row-item>
        <lpt-info-row-item
          class="d-block w-100"
          [leftText]="'Commission'"
          [rightText]="sellingInfoItem.fee + ' ' + sellingInfoItem.asset"
          [rightIconSrc]="'./assets/images/bnb.svg'"
        ></lpt-info-row-item>
      </div>
    </ng-template>
    <div class="sell-joker__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="joker-selling-close-button" (click)="closeDialog()">Close</button>
      <button
        class="btn btn-orange"
        id="joker-selling-next-button"
        *ngIf="!(sellingInfo | async);else confirmButtonTemp"
        (click)="validateForm()"
      >
        Next
      </button>
      <ng-template #confirmButtonTemp>
        <button class="btn btn-orange" id="joker-selling-confirm-button" (click)="confirmSelling()">Confirm</button>
      </ng-template>
    </div>
  </div>
</ng-container>

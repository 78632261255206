import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { ValueLabelItem } from '@types';

@Component({
  selector: 'lpt-choose-item-dialog',
  templateUrl: './choose-item-dialog.component.html',
  styleUrls: ['./choose-item-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseItemDialogComponent {
  public title: string;
  public options: ValueLabelItem<string>[];
  public currentValue: string;

  constructor(private dialogRef: DialogRef) {
    this.title = this.dialogRef.data.title;
    this.options = this.dialogRef.data.options;
    this.currentValue = this.dialogRef.data.currentValue;
  }

  public sortChanged(value: string): void {
    this.dialogRef.close(this.currentValue === value ? '' : value);
  }
}

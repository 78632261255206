import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsupportedDeviceDialogComponent } from './unsupported-device-dialog.component';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [UnsupportedDeviceDialogComponent],
  imports: [CommonModule, ToStringPipeModule],
  exports: [UnsupportedDeviceDialogComponent],
})
export class UnsupportedDeviceDialogModule {}

<div class="switcher__container">
  <button
    class="switcher__item cursor-pointer"
    *ngFor="let switchItem of switcherItems"
    [class.active]="switchItem.value === active"
    [class.with-counters]="withCounters"
    id="switcher-item-button"
    (click)="activeChanged(switchItem)"
  >
    {{switchItem.label}}
    <span *ngIf="withCounters && switchItem.counter" class="counter">{{ switchItem.counter }}</span>
  </button>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { JokerModel, SellingInfo, StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { MarketService, ToStringService } from '@services';

@Component({
  selector: 'lpt-sell-joker-dialog',
  templateUrl: './sell-joker-dialog.component.html',
  styleUrls: ['./sell-joker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SellJokerDialogComponent {
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);

  public sellingInfo: BehaviorSubject<SellingInfo | null> = new BehaviorSubject<SellingInfo | null>(null);

  public sellAmountControl: FormControl = new FormControl<string>('', {
    validators: [Validators.required, Validators.min(0.051)],
  });

  constructor(
    private dialogRef: DialogRef,
    private marketService: MarketService,
    private toStringService: ToStringService,
    private cdr: ChangeDetectorRef
  ) {
    this.joker.next(this.dialogRef.data.joker);
  }

  public get getValidationError(): string {
    if (this.sellAmountControl.errors?.['required']) return 'Please enter amount';
    if (this.sellAmountControl.errors?.['min'])
      return this.toStringService.toString(StringsKeys.errorSellAmountShouldBeGreaterThen);
    return '';
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public async validateForm(): Promise<void> {
    this.sellAmountControl.markAllAsTouched();
    this.cdr.detectChanges();
    if (!this.sellAmountControl.valid) return;

    const value = this.sellAmountControl.value;

    const { fee, asset } = await firstValueFrom(this.marketService.getSellingFee(value));

    this.sellingInfo.next({ sellingPrice: value, fee, asset });
  }

  public confirmSelling(): void {
    const joker = this.joker.value;
    const sellingInfo = this.sellingInfo.value;

    if (!joker || !sellingInfo) return;

    this.marketService.postOrder(joker.tokenId, sellingInfo.sellingPrice).subscribe((value) => {
      this.dialogRef.close(true);
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerLevelUpComponent } from './joker-level-up.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { RemainingTimePipeModule, TimeWithPostfixModule, ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [JokerLevelUpComponent],
  imports: [
    CommonModule,
    JokerImageModule,
    InfoRowItemModule,
    ToStringPipeModule,
    RemainingTimePipeModule,
    TimeWithPostfixModule,
  ],
  exports: [JokerLevelUpComponent],
})
export class JokerLevelUpModule {}

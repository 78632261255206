import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { AUTH_CODE_MASK } from '@constants';

// Should be imported via global path in order to avoid circular injection
import { SignupStateService } from '../../../services/src/signup-state.service';
import { ProfileService } from '@services';

@Component({
  selector: 'lpt-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SignupStateService],
})
export class ChangeEmailDialogComponent {
  public StringsKeys = StringsKeys;
  public readonly mask = AUTH_CODE_MASK;

  public submitterId: 'sendOtp-btn' | 'save-btn';

  constructor(
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef,
    public state: SignupStateService,
    public profileService: ProfileService
  ) {
    this.state.initForm();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submit(): void {
    if (this.submitterId === 'sendOtp-btn') {
      this.state.sendOtpCode('update_email');
      return;
    }
    this.saveValue();
  }

  public saveValue(): void {
    this.state.form.markAllAsTouched();
    this.cdr.detectChanges();

    if (this.state.form.invalid || this.state.loader.isLoading) {
      return;
    }

    const value = this.state.form.getRawValue();

    this.profileService.updateProfileEmail(value).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: ({ status }) => {
        if (status === 406) {
          this.state.form.controls['email'].setErrors({ email: true });
        }
        if (status === 422) {
          this.state.form.controls['otp'].setErrors({ otp: true });
        }
        this.cdr.detectChanges();
      },
    });
  }
}

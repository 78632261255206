import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultComponent } from './result.component';
import { CallTimerModule, JokerImageModule } from '@components';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToFixedModule, ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [ResultComponent],
  imports: [CommonModule, CallTimerModule, AngularSvgIconModule, JokerImageModule, ToStringPipeModule, ToFixedModule],
  exports: [ResultComponent],
})
export class ResultModule {}

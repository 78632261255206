import { Component } from '@angular/core';
import { spinnerTemp } from '@constants';
import { ThemeService } from '@services';
import { PLAYBACK_VIDEO_ELEMENT_ID } from '@helpers';

@Component({
  selector: 'lpt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public globalSpinnerTemplate = spinnerTemp();
  public readonly playbackVideoElementId = PLAYBACK_VIDEO_ELEMENT_ID;

  constructor(private themeService: ThemeService) {
    this.themeService.initTheme();
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ROUTES_ENUM } from '@constants';
import { filter, switchMap } from 'rxjs';
import { NavigationService, NotificationsService } from '@services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticsEventsType } from '@types';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'lpt-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileHeaderComponent {
  public hasUnreadNotifications$ = this.notificationsService.hasUnreadNotifications$;
  public isHomeRouteActive$ = this.navigationService.isRouteActive$(ROUTES_ENUM.HOME, true);
  public isMarketRouteActive$ = this.navigationService.isRouteActive$(ROUTES_ENUM.MARKETPLACE);
  constructor(
    private readonly navigationService: NavigationService,
    private readonly notificationsService: NotificationsService,
    private readonly gaService: GoogleAnalyticsService
  ) {
    this.subscribeForUnreadCount();
  }

  public toProfile(): void {
    this.gaService.event(GoogleAnalyticsEventsType.profileOpened);
    void this.navigationService.toProfile();
  }

  public toNotifications(): void {
    this.gaService.event(GoogleAnalyticsEventsType.notificationsOpened);
    void this.navigationService.toNotifications();
  }

  private subscribeForUnreadCount(): void {
    this.isHomeRouteActive$
      .pipe(
        filter(Boolean),
        untilDestroyed(this),
        switchMap(() => {
          return this.notificationsService.getNotificationsUnreadCount();
        })
      )
      .subscribe();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, take, tap } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Points, SingleModeSettingsModelProps, ResponseDto, SingleModeSettingsModel } from '@types';
import { DialogHelperService } from './dialog-helper.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SingleModeService {
  private readonly _isSingleModeReady = new BehaviorSubject<boolean>(false);
  public readonly isSingleModeReady$ = this._isSingleModeReady.asObservable();

  public readonly settings = new BehaviorSubject<SingleModeSettingsModel | null>(null);
  public readonly settings$ = this.settings.asObservable();

  public readonly points = new BehaviorSubject<Points | null>(null);
  public readonly points$ = this.points.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService
  ) {}

  public resetState(): void {
    this._isSingleModeReady.next(false);
    this.settings.next(null);
    this.points.next(null);
  }

  public markSingleModeAsReady(): void {
    this._isSingleModeReady.next(true);
  }

  public markSingleModeAsUnready(): void {
    this._isSingleModeReady.next(false);
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/PointsController_points
   */
  public getPoints(): Observable<Points> {
    return this.http.get<ResponseDto<Points>>(`${environment.apiUrl}/single-mode/points`).pipe(
      take(1),
      map(({ result }) => result),
      tap((points) => this.points.next(points)),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/SingleModeSettingsController_getSettings
   */
  public getSingleModeSettings(): Observable<SingleModeSettingsModel> {
    return this.http.get<ResponseDto<SingleModeSettingsModelProps>>(`${environment.apiUrl}/single-mode/settings`).pipe(
      take(1),
      map(({ result }) => new SingleModeSettingsModel(result)),
      tap((result) => this.settings.next(result)),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/RewardsController_openAppReward
   */
  public claimOpenAppReward(): Observable<Points> {
    return this.http.post<ResponseDto<Points>>(`${environment.apiUrl}/single-mode/open-app-reward/claim`, {}).pipe(
      take(1),
      map(({ result }) => result),
      tap(() => {
        const settings = this.settings.getValue()!;
        settings.canClaimOpenAppReward = false;
        this.settings.next(settings);
      }),
      catchError((e: HttpErrorResponse) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/RewardsController_openGameReward
   */
  public claimOpenGameReward(): Observable<Points> {
    return this.http.post<ResponseDto<Points>>(`${environment.apiUrl}/single-mode/open-game-reward/claim`, {}).pipe(
      take(1),
      map(({ result }) => result),
      tap(() => {
        const settings = this.settings.getValue()!;
        settings.canClaimStartGameReward = false;
        this.settings.next(settings);
      }),
      catchError((e: HttpErrorResponse) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { IWsGameFinishedDataDto, JokerModel, OpponentModel } from '@types';
import { BehaviorSubject, forkJoin, switchMap } from 'rxjs';
import { LocalStorageService } from '../local-storage.service';
import { GamingHistoryService } from '../gaming-history.service';
import { DialogHelperService } from '../dialog-helper.service';
import { ProfileService } from '../profile.service';

@Injectable()
export class GameLayoutStateService {
  public readonly uuid = LocalStorageService.getAuthDataUuid();
  public readonly nickname = LocalStorageService.getAuthDataNickname();

  private readonly _opponent = new BehaviorSubject<OpponentModel | null>(null);
  readonly opponent$ = this._opponent.asObservable();

  private readonly _currentJoker = new BehaviorSubject<JokerModel | null>(null);
  readonly currentJoker$ = this._currentJoker.asObservable();

  private readonly _gameId = new BehaviorSubject<string | null>(null);
  readonly gameId$ = this._gameId.asObservable();

  private _gameResultData = new BehaviorSubject<IWsGameFinishedDataDto | null>(null);
  readonly gameResultData$ = this._gameResultData.asObservable();

  private _isGameFinishedManually = false;

  constructor(
    private readonly gamingHistoryService: GamingHistoryService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly profileService: ProfileService
  ) {}

  public setOpponent(obj: OpponentModel | null): void {
    this._opponent.next(obj);
  }

  public setCurrentJoker(obj: JokerModel): void {
    this._currentJoker.next(obj);
  }

  public setGameId(id: string | null): void {
    this._gameId.next(id);
  }

  public setGameResultData(data: IWsGameFinishedDataDto | null): void {
    this._gameResultData.next(data);
  }

  public setGameAsManuallyFinished(): void {
    this._isGameFinishedManually = true;
  }

  public isGameFinished(): boolean {
    return Boolean(this._gameResultData.value) || this._isGameFinishedManually;
  }

  public getOpponentId(): string | undefined {
    return this._opponent.value?.id;
  }

  public showOpponentStat(opponent: OpponentModel): void {
    forkJoin({
      statistic: this.gamingHistoryService.getUserGameStatistics(opponent.id),
      instagram: this.profileService.getOpponentInstagram(opponent.id),
    })
      .pipe(
        switchMap(({ statistic, instagram }) =>
          this.dialogHelperService.getJokerStatDialog(statistic, opponent.joker.metadata.image, opponent.nickname, instagram)
        )
      )
      .subscribe();
  }
}

<div class="flex-center dialog__container">
  <lpt-joker-image *ngIf="jokerImage" [image]="jokerImage" [circleAppearance]="true" size="sm"></lpt-joker-image>

  <h3 class="fz-18 txt-primary p-20" *ngIf="title">{{title}}</h3>

  <div class="header__gaming-stats flex-around w-100 pt-20" *ngIf="userStats.length">
    <lpt-gaming-history-stat-item
      *ngFor="let statItem of userStats"
      [statName]="statItem[0] | titlecase"
      [statValue]="statItem[1]"
      [iconSrc]="'./assets/images/gaming-history/'+ statItem[0] +'.svg'"
    >
    </lpt-gaming-history-stat-item>
  </div>

  <a *ngIf="instagram" [href]="instagram" class="user__stats-instagram w-100 d-block" target="_blank">
    <lpt-info-row-item
      class="w-100"
      [leftIconSrc]="'./assets/images/instagram.svg'"
      [leftText]="StringsKeys.editProfileInstagramTitle | toString"
      [rightIconSrc]="'./assets/images/arrow-right-small.svg'"
    ></lpt-info-row-item>
  </a>
</div>

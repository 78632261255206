import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LevelUpUpdateTimeComponent } from './level-up-update-time.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { RangeSelectorModule } from '../range-selector/range-selector.module';
import { RemainingTimePipeModule, ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [LevelUpUpdateTimeComponent],
  imports: [CommonModule, JokerImageModule, InfoRowItemModule, RangeSelectorModule, RemainingTimePipeModule, ToStringPipeModule],
  exports: [LevelUpUpdateTimeComponent],
})
export class LevelUpUpdateTimeModule {}

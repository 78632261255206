import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParticipantComponent } from './participant.component';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';

@NgModule({
  declarations: [ParticipantComponent],
  imports: [CommonModule, LottieModule, LottieCacheModule],
  exports: [ParticipantComponent],
})
export class ParticipantModule {}

<div class="widget__balance flex-center">
  <span class="widget__balance-points">{{ (singleModeService.points$ | async)?.pointsTotal | points }}</span>
  <svg-icon src="./assets/images/earn-coin-3d.svg"></svg-icon>
</div>

<ng-container *ngIf="singleModeService.settings$ | async as settings">
  <div class="widget__resources flex-center">
    <div class="w-100">
      <div class="flex-between">
        <span class="widget__resources-title">{{ StringsKeys.textAvailableIn | toString }}</span>
        <span *ngIf="settings.timeTillNextGame" class="widget__resources-timer"
          >{{ settings.timeTillNextGame | remainingTime:'hours:minutes:seconds': 'hours' }}
        </span>
      </div>
      <div class="widget__resources-progress flex-between">
        <svg-icon src="./assets/images/stamina-sm.svg"></svg-icon>
        <lpt-progress-bar
          [minValue]="0"
          [maxValue]="settings.gameInterval"
          [currentValue]="settings.timeFromLastGame"
        ></lpt-progress-bar>
      </div>
    </div>

    <div class="widget__resources-actions flex-between w-100">
      <button
        id="earn-button"
        class="btn btn-orange flex-1"
        [disabled]="(loader.isLoading$ | async) || settings.timeTillNextGame"
        (click)="earn()"
      >
        {{ StringsKeys.btnPlay | toString }}
      </button>
      <button class="widget__resources-multiplication btn btn-white flex-center" (click)="multiplication()">
        <span>x{{ settings.multiplier }}</span>
        <img src="./assets/images/tokens/token-blue.png" alt="Token" />
      </button>
    </div>
  </div>
</ng-container>

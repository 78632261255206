/**
 * @note The value is given in milliseconds
 */
export const SINGLE_MODE_FACE_DETECTION_INTERVAL = 250;

/**
 * @note The value is given in seconds
 */
export const SINGLE_MODE_GAINED_ANIMATION_DURATION = 2.26;

export const SINGLE_MODE_GAINED_ANIMATION_PATH = './assets/lottie/animation-gained.json';

export const SINGLE_MODE_PREPARATION_ANIMATION_PATH = './assets/lottie/animation-321.json';

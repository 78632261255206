import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyFreeGamesDialogComponent } from './buy-free-games-dialog.component';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [BuyFreeGamesDialogComponent],
  imports: [CommonModule, InfoRowItemModule, AngularSvgIconModule, ToStringPipeModule],
  exports: [BuyFreeGamesDialogComponent],
})
export class BuyFreeGamesDialogModule {}

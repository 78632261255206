import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StringsKeys } from '@types';
import { ToStringService } from '@services';
import { NgFor, NgIf } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PointsPipeModule } from '@pipes';

@Component({
  selector: 'lpt-daily-reward-widget',
  templateUrl: './daily-reward-widget.component.html',
  styleUrls: ['./daily-reward-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, NgIf, AngularSvgIconModule, PointsPipeModule],
})
export class DailyRewardWidgetComponent {
  private readonly toStringService = inject(ToStringService);

  public readonly rewards = [
    { reward: 2500, multiplicator: 0, day: this.toStringService.toString(StringsKeys.titleOnboardingStepDay1) },
    { reward: 5000, multiplicator: 2, day: this.toStringService.toString(StringsKeys.titleOnboardingStepDay2) },
    { reward: 17500, multiplicator: 7, day: this.toStringService.toString(StringsKeys.titleOnboardingStepDay3) },
  ];
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { DialogHelperService, LoaderService, ProfileService } from '@services';
import { StringsKeys } from '@types';

@Component({
  selector: 'lpt-identify-gender',
  templateUrl: './identify-gender.component.html',
  styleUrls: ['./identify-gender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdentifyGenderComponent implements OnInit {
  public StringsKeys = StringsKeys;
  @Input() accessToken?: string;

  constructor(
    private dialogRef: DialogRef,
    private dialogHelperService: DialogHelperService,
    private profileService: ProfileService,
    private loader: LoaderService
  ) {}

  ngOnInit() {
    this.accessToken = this.dialogRef.data?.accessToken;
  }

  public next(gender: string): void {
    if (this.loader.isLoading) {
      return;
    }

    this.loader.activate();
    this.profileService.updateGender(gender, this.accessToken).subscribe({
      next: () => {
        this.dialogRef.close();
        this.loader.deactivate();
      },
      error: () => {
        this.dialogHelperService.openDefaultErrorDialog();
        this.loader.deactivate();
      },
    });
  }
}

export enum PlayMode {
  Play = 'play',
  Create = 'create',
  Join = 'join',
}

export interface IJoinPlayModeDialogResult {
  roomId?: string;
  type: EJoinPlayModeDialogResultType;
}

export enum EJoinPlayModeDialogResultType {
  Scan,
  RoomId,
}

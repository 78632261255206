import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerMarketCardComponent } from './joker-market-card.component';
import { JokerImageModule } from '@components';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [JokerMarketCardComponent],
  imports: [CommonModule, JokerImageModule, AngularSvgIconModule, ToStringPipeModule],
  exports: [JokerMarketCardComponent],
})
export class JokerMarketCardModule {}

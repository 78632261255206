import { Injectable } from '@angular/core';
import { InsufficientFundsError, JokerModel, MarketFilterParams, MarketOrder, ResponseDto, StringsKeys } from '@types';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, catchError, filter, firstValueFrom, map, Observable, switchMap, take, tap } from 'rxjs';
import camelcaseKeys from 'camelcase-keys';
import { DialogHelperService } from './dialog-helper.service';
import { BuyJokerDialogComponent, RevokeJokerDialogComponent } from '@components';
import { CustomToastrService } from './custom-toastr-service.service';
import { ToStringService } from './to-string.service';
import { SpinnerService } from './spinner.service';
import { defaultFilterParams } from '@constants';
import { LoaderService } from './loader.service';
import { removeEmptyValues } from '@helpers';

// Initial value wouldn't be used here only
const initialValue: MarketFilterParams = {
  take: 14,
  ...defaultFilterParams,
  after: undefined,
  sortDirection: undefined,
};

@Injectable({
  providedIn: 'root',
})
export class MarketService {
  public marketPlaceOrders: BehaviorSubject<MarketOrder[]> = new BehaviorSubject<MarketOrder[]>([]);
  public marketPlaceOrders$: Observable<MarketOrder[]> = this.marketPlaceOrders.asObservable();

  private marketFilterParams: BehaviorSubject<MarketFilterParams> = new BehaviorSubject<MarketFilterParams>(initialValue);
  public marketFilterParams$: Observable<MarketFilterParams> = this.marketFilterParams.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService,
    private spinnerService: SpinnerService,
    private loaderService: LoaderService
  ) {}

  public resetMarketOrders(): void {
    this.marketPlaceOrders.next([]);
  }

  public resetMarketFilterParams(): void {
    this.marketFilterParams.next(initialValue);
  }

  public getMarketplaceOrders(
    params?: MarketFilterParams,
    displayGlobalSpinner = true,
    pushToTheList = false
  ): Observable<ResponseDto<MarketOrder[]>> {
    if (displayGlobalSpinner) this.loaderService.activate();
    if (params) this.marketFilterParams.next({ ...this.marketFilterParams.value, ...params });

    const queryParams = new HttpParams({
      fromObject: removeEmptyValues(this.marketFilterParams.value),
    });

    return this.http
      .get<ResponseDto<MarketOrder[]>>(`${environment.apiUrl}/marketplace/orders`, {
        params: queryParams,
      })
      .pipe(
        tap(() => this.loaderService.deactivate()),
        map((resp) => camelcaseKeys(resp, { deep: true })),
        tap(({ result, paging }) => {
          // TODO: hidden temporarily (see: https://agilie.atlassian.net/browse/LPT-1251)
          //  Based on the ticket above, we need to temporarily show empty marketplace.
          result = [];

          this.marketFilterParams.next({ ...this.marketFilterParams.value, after: paging?.next || '' });

          // Regular request that require new list of orders to be set
          if (!pushToTheList) {
            this.marketPlaceOrders.next(result);
            return;
          }

          // Request with pagination, we need to push new elements to the end
          const newMarketItems = [...this.marketPlaceOrders.value, ...result];

          this.marketPlaceOrders.next(newMarketItems);
        }),
        catchError((e) => {
          this.loaderService.deactivate();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public getMarketOrderById(orderId: string): Observable<MarketOrder> {
    this.spinnerService.showGlobalSpinner();
    return this.http.get<ResponseDto<MarketOrder>>(`${environment.apiUrl}/marketplace/orders/${orderId}`).pipe(
      tap(() => this.spinnerService.hideGlobalSpinner()),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }
  public getSellingFee(priceOfSelling: string): Observable<{ fee: string; asset: string }> {
    return this.http
      .get<ResponseDto<{ fee: string; asset: string }>>(`${environment.apiUrl}/marketplace/orders/selling-fee`, {
        params: {
          price: priceOfSelling,
        },
      })
      .pipe(map(({ result }) => camelcaseKeys(result, { deep: true })));
  }

  public getBuyingFee(): Observable<{ fee: string; asset: string }> {
    return this.http
      .get<ResponseDto<{ fee: string; asset: string }>>(`${environment.apiUrl}/marketplace/orders/buying-fee`, {})
      .pipe(map(({ result }) => camelcaseKeys(result, { deep: true })));
  }

  public postOrder(jokerId: string, price: string): Observable<void> {
    return this.http
      .post<ResponseDto<any>>(`${environment.apiUrl}/marketplace/orders`, {
        price,
        jokerId,
      })
      .pipe(
        take(1),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        catchError((e) => {
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public buyJoker(jokerId: string): Observable<void> {
    return this.http.post<ResponseDto<any>>(`${environment.apiUrl}/marketplace/orders/${jokerId}/buy`, {}).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        throw e;
      })
    );
  }

  public deleteOrder(joker: JokerModel): Observable<void> {
    return this.dialogHelperService
      .openDialogComponent<RevokeJokerDialogComponent, boolean>(RevokeJokerDialogComponent, {
        joker,
      })
      .pipe(
        filter(Boolean),
        tap(() => this.spinnerService.showGlobalSpinner()),
        switchMap(() =>
          this.http
            .delete<ResponseDto<any>>(`${environment.apiUrl}/marketplace/orders/${joker.tokenId}`, {
              params: {
                jokerId: joker.tokenId,
              },
            })
            .pipe(
              take(1),
              tap(() => {
                this.spinnerService.hideGlobalSpinner();
                this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketMarketSellRevoked));
              }),
              map(({ result }) => camelcaseKeys(result, { deep: true })),
              catchError((e) => {
                this.spinnerService.hideGlobalSpinner();
                this.dialogHelperService.openDefaultErrorDialog();
                throw e;
              })
            )
        )
      );
  }

  public async processBuyingJoker(marketOrder: MarketOrder): Promise<void> {
    const result = await firstValueFrom(
      this.dialogHelperService.openDialogComponent<BuyJokerDialogComponent, boolean | InsufficientFundsError>(
        BuyJokerDialogComponent,
        {
          marketOrder,
        }
      )
    );

    if (typeof result === 'boolean' && !result) {
      // Means there is a server error while buying
      this.dialogHelperService.openDefaultErrorDialog();
      return;
    }

    if (typeof result === 'boolean' && result) {
      this.dialogHelperService.openJokerPurchaseInProgressDialog();
      this.getMarketplaceOrders().subscribe();
    }

    if (typeof result === 'object') {
      // Means insufficient funds error
      this.dialogHelperService.openInsufficientFundsDialog(result);
    }
  }
}

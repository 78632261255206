import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import { GameRecordResp, GameRecordStatus, GamingHistoryGameItem, GamingHistoryStatItem, ResponseDto, StringsKeys } from '@types';
import camelcaseKeys from 'camelcase-keys';
import { CustomToastrService, DialogHelperService, NavigatorService, SpinnerService, ToStringService } from '@services';
import { ClipboardService } from 'ngx-clipboard';
import { isCompatibleToShare } from '@helpers';

@Injectable({
  providedIn: 'root',
})
export class GamingHistoryService {
  private gamingHistoryStat: BehaviorSubject<GamingHistoryStatItem | null> = new BehaviorSubject<GamingHistoryStatItem | null>(
    null
  );

  public gamingHistoryStat$: Observable<GamingHistoryStatItem | null> = this.gamingHistoryStat.asObservable();

  public gamingHistoryList: BehaviorSubject<GamingHistoryGameItem[]> = new BehaviorSubject<GamingHistoryGameItem[]>([]);

  public gamingHistoryList$: Observable<GamingHistoryGameItem[]> = this.gamingHistoryList.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private spinnerService: SpinnerService,
    private toStringService: ToStringService,
    private clipboardService: ClipboardService,
    private customToastrService: CustomToastrService,
    private navigatorService: NavigatorService
  ) {}

  public getGameHistory(): Observable<GamingHistoryGameItem[]> {
    return this.http.get<ResponseDto<GamingHistoryGameItem[]>>(`${environment.apiUrl}/history`).pipe(
      map(({ result, paging }) => camelcaseKeys(result, { deep: true })),
      map((list) => list.reverse()), // in order to be sorted by desc
      tap((list) => {
        this.gamingHistoryList.next(list);
      }),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getGameById(gameId: string): Observable<GamingHistoryGameItem> {
    return this.http.get<ResponseDto<GamingHistoryGameItem>>(`${environment.apiUrl}/history/${gameId}`).pipe(
      map(({ result, paging }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getUserGameStatistics(userId: string): Observable<GamingHistoryStatItem> {
    this.spinnerService.showGlobalSpinner();
    return this.http.get<ResponseDto<GamingHistoryStatItem[]>>(`${environment.apiUrl}/statistics/${userId}`).pipe(
      tap(() => this.spinnerService.hideGlobalSpinner()),
      map(({ result, paging }) => camelcaseKeys(result[0], { deep: true })),
      tap((statItem) => this.gamingHistoryStat.next(statItem)),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getOrSaveGameRecord(gameId: string): Observable<GameRecordResp> {
    this.spinnerService.showGlobalSpinner();
    return this.http.get<ResponseDto<GameRecordResp>>(`${environment.apiUrl}/games/${gameId}/record`).pipe(
      tap(() => this.spinnerService.hideGlobalSpinner()),
      map(({ result, paging }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public uploadGame(gameId: string): void {
    this.getOrSaveGameRecord(gameId)
      .pipe(
        tap((result) => {
          if (
            !result ||
            result.status === GameRecordStatus.Failed ||
            result.status === GameRecordStatus.Enqueued ||
            result.status === GameRecordStatus.Processing
          ) {
            this.dialogHelperService.openErrorDialogWithText(
              this.toStringService.toString(StringsKeys.downloadPopupHistorySubtitle),
              this.toStringService.toString(StringsKeys.downloadPopupTitle)
            );
            return;
          }

          if (isCompatibleToShare() && result.url) {
            this.navigatorService.share('', '', result.url);
            return;
          }

          this.clipboardService.copy(result.url);
          this.customToastrService.show('', this.toStringService.toString(StringsKeys.gameShareUrlIsCopied));
        })
      )
      .subscribe();
  }
}

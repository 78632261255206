import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lpt-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() public maxValue: number;
  @Input() public minValue: number;
  @Input() public currentValue: number;
  @Input() public stepped: boolean;
  @Input() public color: string;

  public get steps(): Array<number> {
    if (!this.maxValue) return [];
    return [...Array(this.maxValue - this.minValue).keys()];
  }

  public get dividers(): Array<number> {
    //    For dividers, we need one less divider than actual steps
    if (!this.steps.length) return [];
    return this.steps.slice(0, this.steps.length - 1);
  }

  public get filledPercentage(): number {
    if (!this.maxValue || !this.currentValue) return 0;
    return this.currentValue / this.maxValue;
  }
}

import { Injectable } from '@angular/core';
import { ELocalStorageKey, ESessionStorageKey, SessionData, ThemeMode } from '@types';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public static getAccessToken(): string | null {
    return localStorage.getItem(ELocalStorageKey.AccessToken);
  }

  public static setAccessToken(value: string): void {
    localStorage.setItem(ELocalStorageKey.AccessToken, value);
  }

  public static deleteAccessToken(): void {
    localStorage.removeItem(ELocalStorageKey.AccessToken);
  }

  public static hasAccessToken(): boolean {
    return Boolean(localStorage.getItem(ELocalStorageKey.AccessToken));
  }

  public static setAuthData(user: SessionData): void {
    localStorage.setItem(ELocalStorageKey.Uuid, user.id);
    localStorage.setItem(ELocalStorageKey.Nickname, user.nickname);
    this.setUser(user);
  }

  public static getAuthDataUuid(): string | null {
    return localStorage.getItem(ELocalStorageKey.Uuid);
  }

  public static getAuthDataNickname(): string | null {
    return localStorage.getItem(ELocalStorageKey.Nickname);
  }

  public static setUser(user: SessionData): void {
    localStorage.setItem(ELocalStorageKey.User, JSON.stringify(user));
  }

  public static getUser(): SessionData | null {
    const storedUser = localStorage.getItem(ELocalStorageKey.User);
    return storedUser ? JSON.parse(storedUser) : null;
  }

  public static deleteAuthData(): void {
    localStorage.removeItem(ELocalStorageKey.Uuid);
    localStorage.removeItem(ELocalStorageKey.Nickname);
    localStorage.removeItem(ELocalStorageKey.User);
  }

  public static setThemeMode(theme: ThemeMode): void {
    localStorage.setItem(ELocalStorageKey.Theme, theme);
  }

  public static getThemeMode(): string | null {
    return localStorage.getItem(ELocalStorageKey.Theme);
  }

  public static setOnboardingStep(step: number): void {
    localStorage.setItem(ELocalStorageKey.OnboardingStep, String(step));
  }

  public static getOnboardingStep(): number {
    const step = localStorage.getItem(ELocalStorageKey.OnboardingStep);
    return step === null ? 0 : +step;
  }

  public static deleteOnboardingStep(): void {
    localStorage.removeItem(ELocalStorageKey.OnboardingStep);
  }
  public static setReloadedAfterLoadingChunkFailed(): void {
    sessionStorage.setItem(ESessionStorageKey.ReloadedAfterLoadingChunkFailed, String(true));
  }

  public static getReloadedAfterLoadingChunkFailed(): boolean {
    return Boolean(sessionStorage.getItem(ESessionStorageKey.ReloadedAfterLoadingChunkFailed));
  }
}

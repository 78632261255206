import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SingleModeWidgetComponent } from './single-mode-widget.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PointsPipeModule, RemainingTimePipeModule, ToStringPipeModule } from '@pipes';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';
import { SingleModeMultiplicationDialogComponent } from '../single-mode-multiplication-dialog/single-mode-multiplication-dialog.component';

@NgModule({
  declarations: [SingleModeWidgetComponent, SingleModeMultiplicationDialogComponent],
  exports: [SingleModeWidgetComponent, SingleModeMultiplicationDialogComponent],
  imports: [CommonModule, AngularSvgIconModule, ToStringPipeModule, ProgressBarModule, RemainingTimePipeModule, PointsPipeModule],
})
export class SingleModeWidgetModule {}

type AveragerItems = [number | undefined, number | undefined, number | undefined];

export class DetectionPropertyAverager {
  private _values: AveragerItems;

  constructor() {
    this._values = new Array(3).fill(undefined) as AveragerItems;
  }

  public setNewValue(value: number | undefined): DetectionPropertyAverager {
    this._values.unshift(value);
    this._values.pop();
    return this;
  }

  public getAverageValue(): number | undefined {
    const values = <number[]>this._values.filter((v) => typeof v == 'number').sort();

    switch (values.length) {
      case 0:
        return undefined;
      case 1:
        return values[0];
      case 2:
        return (values[0]! + values[1]!) / 2;
      default:
        return values[1];
    }
  }
}

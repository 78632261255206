import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RevokeJokerDialogComponent } from './revoke-joker-dialog.component';
import { JokerStatsModule } from '../joker-stats/joker-stats.module';
import { JokerImageModule } from '../joker-image/joker-image.module';

@NgModule({
  declarations: [RevokeJokerDialogComponent],
  imports: [CommonModule, JokerStatsModule, JokerImageModule],
  exports: [RevokeJokerDialogComponent],
})
export class RevokeJokerDialogModule {}

<svg-icon
  src="assets/images/pre-game-reward.svg"
  class="daily__reward-icon flex-center"
  [svgStyle]="{ width: 200, height: 200 }"
></svg-icon>

<p class="daily__reward-title">{{ StringsKeys.titleYourPreGameReward | toString }}</p>

<lpt-points class="d-block" [value]="points"></lpt-points>

<button class="btn btn-orange w-100" id="pregame-reward-dialog-ok-button" (click)="close()">
  {{ StringsKeys.btnOK | toString }}
</button>

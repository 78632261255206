export const PLAYBACK_VIDEO_ELEMENT_ID = 'video-track';

export function getPlaybackVideoElement(): HTMLVideoElement {
  return document.getElementById(PLAYBACK_VIDEO_ELEMENT_ID) as HTMLVideoElement;
}

export function playbackTrack(target: HTMLAudioElement | HTMLVideoElement, stream: MediaStream): Promise<MediaStream | void> {
  target.srcObject = stream;
  try {
    target.load();
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    return target.play().then(() => stream);
  }
}

export function getEffectAudioElement(): HTMLAudioElement {
  return document.querySelector('#effect-audio') as HTMLAudioElement;
}

export function getEffectSourceElement(): HTMLSourceElement {
  return document.querySelector('#effect-source') as HTMLSourceElement;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { GenericSimpleChange } from '@types';

interface RangeSelectorComponentProps extends SimpleChanges {
  minValue: GenericSimpleChange<number>;
  maxValue: GenericSimpleChange<number>;

  minRangeValue: GenericSimpleChange<number>;
  maxRangeValue: GenericSimpleChange<number>;
  singleSelector: GenericSimpleChange<boolean>;
  displayMinMax: GenericSimpleChange<boolean>;
}

@Component({
  selector: 'lpt-range-selector',
  templateUrl: './range-selector.component.html',
  styleUrls: ['./range-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RangeSelectorComponent implements OnChanges {
  @Input() public minValue = 0;
  @Input() public maxValue = 30;

  @Input() public minRangeValue = 0;
  @Input() public maxRangeValue = 30;

  @Input() public singleSelector = false;
  @Input() public displayMinMax = true;
  @Input() public disabled = false;
  @Input() public minLimit: number;

  @Output() public rangeChanged: EventEmitter<{ min: number; max: number }> = new EventEmitter<{ min: number; max: number }>();

  public options: Options;

  public currentMinValue: number = this.minValue;
  public currentMaxValue: number = this.maxValue;

  ngOnChanges(changes: RangeSelectorComponentProps) {
    if (changes['minValue'].currentValue || changes['maxValue'].currentValue) {
      this.currentMinValue = changes['minValue'].currentValue;
      this.currentMaxValue = changes['maxValue'].currentValue;
    }

    this.updateOptions();
  }

  public updateOptions(): void {
    this.options = {
      floor: this.minRangeValue,
      ceil: this.maxRangeValue,
      step: 1,
      showTicks: false,
      hidePointerLabels: true,
      hideLimitLabels: true,
      disabled: this.disabled,
      minLimit: this.minLimit,
    };
  }

  public minValueChange($event: number): void {
    this.currentMinValue = $event;
    this.rangeChanged.emit({ min: this.currentMinValue, max: this.currentMaxValue });
  }

  public maxValueChange($event: number): void {
    this.currentMaxValue = $event;
    this.rangeChanged.emit({ min: this.currentMinValue, max: this.currentMaxValue });
  }
}

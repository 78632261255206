import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerPurchasePublishedDialogComponent } from './joker-purchase-published-dialog.component';
import { ToStringPipeModule } from '@pipes';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [JokerPurchasePublishedDialogComponent],
  imports: [CommonModule, ToStringPipeModule, AngularSvgIconModule],
  exports: [JokerPurchasePublishedDialogComponent],
})
export class JokerPurchasePublishedDialogModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectPointsAmountComponent } from './select-points-amount.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [SelectPointsAmountComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [SelectPointsAmountComponent],
})
export class SelectPointsAmountModule {}

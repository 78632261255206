import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { EReportType, StringsKeys } from '@types';

@Component({
  selector: 'lpt-report-type-selection',
  templateUrl: './report-type-selection.component.html',
  styleUrls: ['./report-type-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTypeSelectionComponent {
  public readonly eReportType: typeof EReportType = EReportType;
  public readonly StringsKeys = StringsKeys;

  constructor(private dialogRef: DialogRef) {}

  public next(gender: string): void {
    this.dialogRef.close(gender);
  }
}

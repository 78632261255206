export const AUTH_CODE_MASK = '000000';

export const ROOM_ID_MASK = '0000000';

export const TELEGRAM_BOT_URL = 'https://t.me/LolyptoBot';

export const DISCORD_INVITE_URL = 'https://discord.gg/lolypto';

export const TERMS_OF_USE_URL = 'https://www.iubenda.com/terms-and-conditions/42044378';

export const PRIVACY_POLICY_URL = 'https://www.iubenda.com/privacy-policy/42044378';

/**
 * @note The value is given in seconds
 */
export const CALL_ROUND_TIMEOUT = 120;

/**
 * @note The value is given in seconds
 */
export const CALL_POST_FINISHED_ROUND_TIMEOUT = 15;

/**
 * @note The value is given in milliseconds
 */
export const CALL_POST_FINISHED_ROUND_TIMER_INTERVAL = 1000;

/**
 * @note The value is given in milliseconds
 */
export const CALL_OUTPUT_WS_EVENT_INTERVAL = 250;

/**
 * @note The value is given in milliseconds
 */
export const CALL_APPLIED_EFFECT_TIMEOUT = 1e4;

export const TELEGRAM_LOGIN_REDIRECT_PATH = 'auth2/telegram/redirect';

export const TELEGRAM_LOGIN_WIDGET_URL = 'https://telegram.org/js/telegram-widget.js?22';

export const TELEGRAM_SHARE_URL = 'https://telegram.me/share/url?url=<URL>';

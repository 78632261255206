<div class="report">
  <h2 class="report__title">{{ StringsKeys.gameReportTitle | toString }}</h2>
  <div>
    <div class="report__option cursor-pointer" id="report-fraud-button" (click)="next(eReportType.fraud)">
      <span>{{ StringsKeys.gameFraudReportTitle | toString }}</span>
    </div>
    <div class="report__option cursor-pointer" id="report-abuse-button" (click)="next(eReportType.abuse)">
      <span>{{ StringsKeys.gameAbuseReportTitle | toString }}</span>
    </div>
    <div class="report__option cursor-pointer" id="report-nudity-button" (click)="next(eReportType.nudity)">
      <span>{{ StringsKeys.gameNudityReportTitle | toString }}</span>
    </div>
  </div>
</div>

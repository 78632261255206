import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, finalize, map, Observable, Subscription, take, takeWhile, tap, timer } from 'rxjs';
import {
  AuthService,
  DialogHelperService,
  LoaderService,
  LocalStorageService,
  NavigationService,
  ToStringService,
} from '@services';
import { DialogService } from '@ngneat/dialog';
import { IdentifyGenderComponent } from '@components';
import { ILoginDto, StringsKeys } from '@types';
import { EMAIL_REGEXP } from '@constants';

@Injectable()
export class SignupStateService implements OnDestroy {
  public form!: FormGroup;
  public activationCodeControl?: FormControl;
  public readonly activationCodeStep$ = new BehaviorSubject<boolean>(false);
  public tempILoginDto?: ILoginDto;
  private readonly intervalDuration = 1e3;
  private timer?: Observable<number> = new Observable<number>();
  public remainingTime: number | null = null;
  private timerSubscription: Subscription | undefined;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,
    private dialogHelperService: DialogHelperService,
    private toStringService: ToStringService,
    public loader: LoaderService,
    private navigationService: NavigationService
  ) {}

  public initForm(): void {
    this.form = this.fb.group(
      {
        email: ['', [Validators.required, Validators.pattern(EMAIL_REGEXP)]],
        otp: ['', [Validators.required, Validators.minLength(6)]],
      },
      {
        updateOn: 'submit',
      }
    );
  }

  public sendOtpCode(intent: string | undefined = undefined): void {
    this.form.get('email')?.markAsDirty();
    this.form.get('email')?.markAsTouched();
    this.cd.detectChanges();
    if (this.form.get('email')?.invalid || this.loader.isLoading) {
      return;
    }

    this.loader.activate();
    this.authService.sendOtpCode(this.form.value['email'], intent).subscribe({
      next: ({ mutexTime }) => {
        this.timerSubscription = this.runTimer(mutexTime);
        this.cd.markForCheck();
        this.loader.deactivate();
      },
      error: () => {
        this.dialogHelperService.openDefaultErrorDialog();
        this.loader.deactivate();
      },
    });
  }

  private runTimer(mutexTime: number): Subscription {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timer = timer(0, this.intervalDuration).pipe(
      takeWhile((time) => time <= mutexTime),
      map((time) => mutexTime - time),
      tap((time) => {
        this.remainingTime = time;
        this.cd.markForCheck();
      }),
      finalize(() => {
        this.remainingTime = null;
        this.timerSubscription = undefined;
        this.cd.markForCheck();
      })
    );

    return this.timer.subscribe();
  }

  private stopTimer(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.timer = undefined;
  }

  public login(): void {
    this.form.markAllAsTouched();
    this.cd.detectChanges();
    if (this.form.invalid || this.loader.isLoading) {
      return;
    }

    this.loader.activate();
    const { email, otp } = this.form.getRawValue();
    this.authService.login(email, otp).subscribe({
      next: (res) => {
        this.loader.deactivate();
        this.tempILoginDto = res;
        // TODO: Temporarily Hide activation code step [LPT-1241].
        // if (!res.user.isActivated) {
        //   return this.goToActivationCode();
        // }
        if (!res.user.gender) {
          return this.goToGenderSelection();
        }
        LocalStorageService.setAccessToken(res.token.accessToken);
        LocalStorageService.setAuthData(res.user);
        this.navigationService.toDashboard();
      },
      error: ({ status }) => {
        if (status === 406) {
          this.form.controls['email'].setErrors({ email: true });
        }
        if (status === 422) {
          this.form.controls['otp'].setErrors({ otp: true });
        }
        this.loader.deactivate();
        this.cd.markForCheck();
      },
    });
  }

  public goToActivationCode(): void {
    this.activationCodeControl = this.fb.control('', {
      validators: [Validators.required, Validators.minLength(6)],
    });
    this.activationCodeStep$.next(true);
  }

  public goToGenderSelection(): void {
    if (this.tempILoginDto?.token.accessToken) {
      const dialogProps = {
        accessToken: this.tempILoginDto.token.accessToken,
      };

      this.dialogService
        .open(IdentifyGenderComponent, { data: dialogProps })
        .afterClosed$.pipe(take(1))
        .subscribe({
          next: () => {
            if (this.tempILoginDto) {
              LocalStorageService.setAccessToken(this.tempILoginDto.token.accessToken);
              LocalStorageService.setAuthData(this.tempILoginDto.user);

              void this.navigationService.toDashboard();
            }
          },
          error: () => {
            this.dialogHelperService.openDefaultErrorDialog();
            this.cd.markForCheck();
          },
        });
    }
  }

  public backToOtpCode(): void {
    this.activationCodeStep$.next(false);
  }

  public activateCode(): void {
    if (this.activationCodeControl?.invalid || this.loader.isLoading || !this.tempILoginDto?.token.accessToken) {
      return;
    }

    this.loader.activate();
    const token = this.tempILoginDto.token.accessToken;
    this.authService.activateActivationCode(this.activationCodeControl?.value, token).subscribe({
      next: () => {
        this.loader.deactivate();
        if (!this.tempILoginDto?.user.gender) {
          return this.goToGenderSelection();
        }
        LocalStorageService.setAccessToken(this.tempILoginDto.token.accessToken);
        LocalStorageService.setAuthData(this.tempILoginDto.user);
        this.navigationService.toDashboard();
      },
      error: (e) => {
        if (e.status === 406) {
          this.dialogHelperService.openErrorDialogWithText(this.toStringService.toString(StringsKeys.fieldCodeInvalid));
        } else {
          this.dialogHelperService.openDefaultErrorDialog();
        }
        this.loader.deactivate();
        this.cd.markForCheck();
      },
    });
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }
}

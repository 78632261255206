import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Colors } from '@constants';

@Component({
  selector: 'lpt-info-row-item',
  templateUrl: './info-row-item.component.html',
  styleUrls: ['./info-row-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoRowItemComponent {
  @Input() public leftIconSrc: string | undefined;
  @Input() public noSvgleftIconSrc = false;
  @Input() public leftText: string | undefined;
  @Input() public leftSubText: string | undefined;
  @Input() public rightText: string | undefined;
  @Input() public rightIconSrc: string | undefined;
  @Input() public backgroundColor: 'primary' | 'secondary' | undefined = 'primary';

  @Input() public leftTextColor: string;
  @Input() public rightTextColor: string;

  @Input() public leftSubTextColor: string;

  @Input() public leftIconFillColor: string;
  @Input() public rightIconFillColor: string;

  get backGroundColor(): string {
    return !this.backgroundColor || this.backgroundColor === 'primary' ? Colors.Background_Primary : Colors.Background_Secondary;
  }
}

import {
  HAND_LANDMARKER_TASK_URI,
  MIN_HAND_DETECTION_CONFIDENCE,
  MIN_HAND_PRESENSE_CONFIDENCE,
  MIN_HAND_TRACKING_CONFIDENCE,
} from '@constants';
import { HandLandmarkerOptions } from '@mediapipe/tasks-vision';

export function BuildHandLandmarkerOptions(): HandLandmarkerOptions {
  return {
    baseOptions: {
      modelAssetPath: HAND_LANDMARKER_TASK_URI,
      delegate: 'GPU',
    },
    numHands: 1,
    runningMode: 'VIDEO',
    minHandDetectionConfidence: MIN_HAND_DETECTION_CONFIDENCE,
    minTrackingConfidence: MIN_HAND_TRACKING_CONFIDENCE,
    minHandPresenceConfidence: MIN_HAND_PRESENSE_CONFIDENCE,
  };
}

import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lpt-select-points-amount',
  templateUrl: './select-points-amount.component.html',
  styleUrls: ['./select-points-amount.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPointsAmountComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPointsAmountComponent implements ControlValueAccessor {
  @Input() public iconSrc: string;
  @Input() public title: string;
  @Input() public initialValue: number;
  @Input() public maxValue: number;
  @Input() public canAddPoints: boolean;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouch: any = () => {};

  val = 0;
  set value(val: number) {
    this.val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  addPoint(): void {
    if (!this.canAddPoints || this.val >= this.maxValue) return;
    this.val += 1;
    this.onChange(this.val);
  }

  removePoint(): void {
    if (!this.val || this.val <= this.initialValue) return;
    this.val -= 1;
    this.onChange(this.val);
  }
}

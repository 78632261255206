import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterRoomIdComponent } from './enter-room-id.component';
import { ToStringPipeModule } from '@pipes';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ReactiveFormsModule } from '@angular/forms';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [EnterRoomIdComponent],
  imports: [CommonModule, ToStringPipeModule, AngularSvgIconModule, ReactiveFormsModule, InputModule],
  exports: [EnterRoomIdComponent],
})
export class EnterRoomIdModule {}

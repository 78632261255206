import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerResourceComponent } from './joker-resource.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ProgressBarModule } from '../progress-bar/progress-bar.module';

@NgModule({
  declarations: [JokerResourceComponent],
  imports: [CommonModule, AngularSvgIconModule, ProgressBarModule],
  exports: [JokerResourceComponent],
})
export class JokerResourceModule {}

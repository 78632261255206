import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileFooterComponent } from './mobile-footer.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { NavigationPanelModule } from '@components';

@NgModule({
  declarations: [MobileFooterComponent],
  imports: [CommonModule, AngularSvgIconModule, RouterModule, NavigationPanelModule],
  exports: [MobileFooterComponent],
})
export class MobileFooterModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealJokerDialogComponent } from './heal-joker-dialog.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { RangeSelectorModule } from '../range-selector/range-selector.module';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [HealJokerDialogComponent],
  imports: [CommonModule, JokerImageModule, InfoRowItemModule, RangeSelectorModule, ToStringPipeModule],
  exports: [HealJokerDialogComponent],
})
export class HealJokerDialogModule {}

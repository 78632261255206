import { Injectable } from '@angular/core';
import { MarketSocketService } from './market-socket.service';
import { JokerSocketService } from './joker/joker-socket.service';
import { NotificationsSocketService } from './notifications-socket.service';

@Injectable({
  providedIn: 'root',
})
export class SocketSubscriptionService {
  constructor(
    private jokerSocketService: JokerSocketService,
    private marketSocketService: MarketSocketService,
    private notificationsSocketService: NotificationsSocketService
  ) {}

  public initAppSocketSubscriptions(): void {
    //TODO: turn off temporarily
    // Just as marketplace features are hidden, it is no make sense to interact with sockets.

    // this.marketSocketService.subscribeForWsOrdersUpdate();
    // this.jokerSocketService.subscribeJokersSocketUpdate();
    this.notificationsSocketService.subscribeForWsNotifications();
  }

  public unsubscribeFromAppSockets(): void {
    //TODO: turn off temporarily
    // Just as marketplace features are hidden, it is no make sense to interact with sockets.

    // this.marketSocketService.unsubscribeForWsOrdersUpdate();
    // this.jokerSocketService.unsubscribeOrdinaryJokerWsUpdate();
    this.notificationsSocketService.unsubscribeFromWsNotifications();
  }
}

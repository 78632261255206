import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INFTAttribute, JokerModel, PriceFeeItem, StringsKeys } from '@types';
import { BehaviorSubject, combineLatest, filter, map } from 'rxjs';
import { DialogRef } from '@ngneat/dialog';
import { getJokerAttributeByType } from '@helpers';
import { JokerService, SpinnerService } from '@services';

@Component({
  selector: 'lpt-heal-joker-dialog',
  templateUrl: './heal-joker-dialog.component.html',
  styleUrls: ['./heal-joker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealJokerDialogComponent {
  public StringsKeys = StringsKeys;
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);
  public extraCraziness: BehaviorSubject<INFTAttribute | undefined> = new BehaviorSubject<INFTAttribute | undefined>(undefined);

  public healInfo: BehaviorSubject<PriceFeeItem | null> = new BehaviorSubject<PriceFeeItem | null>(null);

  public healCrazinessAmount: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public pointsToHeal = combineLatest([this.extraCraziness, this.healCrazinessAmount]).pipe(
    filter(([extraCraziness]) => Boolean(extraCraziness)),
    map(([extraCraziness, healCrazinessAmount]) => Number(extraCraziness?.value) - healCrazinessAmount)
  );

  constructor(
    private dialogRef: DialogRef,
    private spinnerService: SpinnerService,
    private jokerService: JokerService
  ) {
    const joker = this.dialogRef.data.joker;

    this.joker.next(joker);
    this.extraCraziness.next(getJokerAttributeByType(joker, 'extra_craziness'));
    this.healInfo.next(this.dialogRef.data.healInfo);

    this.setDefaultCrazinessAmount();
  }

  public setDefaultCrazinessAmount(): void {
    const craziness = this.extraCraziness.value;
    if (!craziness) return;

    this.healCrazinessAmount.next(Number(craziness.value));
  }

  public get healPrice(): number {
    const lrtPerHeal = Number(this.healInfo.value!.price.amount);
    const amountOfHeal = this.healCrazinessAmount.value;
    const currentCraziness = Number(this.extraCraziness.value?.value);

    if (!amountOfHeal) return 0;
    return (amountOfHeal - currentCraziness) * lrtPerHeal;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public confirmHealing(): void {
    const joker = this.joker.value;
    const amountOfHeal = this.healCrazinessAmount.value;
    const currentCraziness = Number(this.extraCraziness.value?.value);
    const pointsToHeal = amountOfHeal - currentCraziness;

    this.spinnerService.showGlobalSpinner();

    this.jokerService.healJoker(joker!.tokenId, pointsToHeal).subscribe({
      next: () => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogRef.close(true);
      },
      error: () => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogRef.close(false);
      },
    });
  }
}

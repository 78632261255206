import {
  BuyFreeGamesDialogComponent,
  ChooseItemDialogComponent,
  DialogComponent,
  EnterTextDialogComponent,
  GenericJokerConfirmDialogComponent,
  InsufficientFundsDialogComponent,
  JokerPurchasePublishedDialogComponent,
  PlayerIssueDialogComponent,
  SingleModePreparationDialogComponent,
  UnsupportedDeviceDialogComponent,
  UserStatsDialogComponent,
} from '@components';
import { Injectable, Type } from '@angular/core';
import { DialogRef, DialogService } from '@ngneat/dialog';
import { Observable, tap } from 'rxjs';
import {
  DefaultDialogProps,
  GamingHistoryStatItem,
  InsufficientFundsError,
  PriceFeeItem,
  StringsKeys,
  ThemeMode,
  ValueLabelItem,
} from '@types';
import { ToStringService } from './to-string.service';
import { getTokenIconByCurrencyCode } from '@helpers';
import { Validators } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root',
})
export class DialogHelperService {
  constructor(
    private dialogService: DialogService,
    private toStringService: ToStringService,
    private navigationService: NavigationService,
    private decimalPipe: DecimalPipe
  ) {}

  public openDialogComponentWithRef<C>(component: Type<C>, data?: any): DialogRef<C> {
    return this.dialogService.open(component as Type<C>, { data }) as DialogRef<C>;
  }

  public openDialogComponent<C, ReturnType>(
    component: Type<C>,
    data?: any,
    closable = true,
    windowClass?: string
  ): Observable<ReturnType> {
    return this.dialogService.open(component as Type<any>, {
      data,
      enableClose: closable,
      windowClass,
    }).afterClosed$ as Observable<ReturnType>;
  }

  public openDefaultErrorDialog(ignoreOpenedDialogs = false): void {
    if (!ignoreOpenedDialogs && this.dialogService.hasOpenDialogs()) return;

    this.dialogService.open(DialogComponent, {
      data: {
        desc: this.toStringService.toString(StringsKeys.responseErrorUnknown),
      } as DefaultDialogProps,
    });
  }

  public openErrorDialogWithText(text: string, title?: string): void {
    this.dialogService.open(DialogComponent, {
      data: { desc: text, title } as DefaultDialogProps,
    });
  }

  public openUnsupportedDeviceDialog(): void {
    this.openDialogComponent(UnsupportedDeviceDialogComponent, undefined, false);
  }

  public openDefaultDialogWithText(
    desc: string,
    title?: string,
    acceptButton?: string,
    declineButton?: string,
    icon?: string
  ): Observable<boolean> {
    return this.dialogService.open(DialogComponent, {
      data: { desc, title, acceptButton, declineButton, icon } as DefaultDialogProps,
      windowClass: 'default',
    }).afterClosed$ as Observable<boolean>;
  }

  public openPlayerIssueDialogWithText(text: string): ReturnType<DialogService['open']> {
    return this.dialogService.open(PlayerIssueDialogComponent, {
      windowClass: 'sm',
      enableClose: false,
      closeButton: false,
      data: { text } as DefaultDialogProps,
    });
  }

  public openSingleModePreparationDialog(): ReturnType<DialogService['open']> {
    return this.dialogService.open(SingleModePreparationDialogComponent, {
      windowClass: 'sm single-mode-preparation',
      enableClose: false,
      closeButton: false,
    });
  }

  public closeAllDialogs(): void {
    if (this.dialogService.hasOpenDialogs()) {
      this.dialogService.closeAll();
    }
  }

  public openInsufficientFundsDialog(insufficientFundsError: InsufficientFundsError): void {
    this.openDialogComponent<InsufficientFundsDialogComponent, boolean>(InsufficientFundsDialogComponent, {
      insufficientFundsError,
    })
      .pipe(
        tap((res) => {
          if (res) this.navigationService.toWallet();
        })
      )
      .subscribe();
  }

  public getChangeProfileConfirmDialog(feeInfo: PriceFeeItem, titleKey: StringsKeys): Observable<boolean> {
    return this.openDialogComponent<GenericJokerConfirmDialogComponent, boolean>(GenericJokerConfirmDialogComponent, {
      title: this.toStringService.toString(titleKey),
      closeBtn: this.toStringService.toString(StringsKeys.btnClose),
      confirmBtn: this.toStringService.toString(StringsKeys.btnConfirm),
      icon: './assets/images/question-icon.svg',
      rows: [
        {
          leftText: this.toStringService.toString(StringsKeys.popupPricePriceTitle),
          rightText: this.decimalPipe.transform(feeInfo.price.amount, '1.0-2') + ' ' + feeInfo.price.currency,
          rightIconSrc: getTokenIconByCurrencyCode(feeInfo.price.currency),
        },
        {
          leftText: this.toStringService.toString(StringsKeys.popupPriceTransactionFeeTitle),
          rightText: this.decimalPipe.transform(feeInfo.fee.amount, '1.0-5') + ' ' + feeInfo.fee.currency,
          rightIconSrc: getTokenIconByCurrencyCode(feeInfo.fee.currency),
        },
      ],
    });
  }

  public getNickNameChangeDialog(currentValue: string | undefined): Observable<string | false> {
    return this.openDialogComponent(EnterTextDialogComponent, {
      title: StringsKeys.editProfileEditNameTitle,
      inputLabel: StringsKeys.editProfileNickname,
      initialFormValue: currentValue,
      confirmButtonText: StringsKeys.btnSave,
      validators: [Validators.required, Validators.minLength(1), Validators.maxLength(11)],
    });
  }

  public getGenderChangeDialog(currentValue: string | undefined): Observable<string | false> {
    return this.openDialogComponent(ChooseItemDialogComponent, {
      title: this.toStringService.toString(StringsKeys.editProfileGender),
      currentValue: currentValue,
      options: <ValueLabelItem<string>[]>[
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderMale),
          value: 'male',
        },
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderFemale),
          value: 'female',
        },
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderOther),
          value: 'other',
        },
      ],
    });
  }

  public getPlayWithChangeDialog(currentValue: string | undefined): Observable<string | false> {
    return this.openDialogComponent(ChooseItemDialogComponent, {
      title: this.toStringService.toString(StringsKeys.editProfileGenderWhoToPlayWith),
      currentValue: currentValue,
      options: <ValueLabelItem<string>[]>[
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderMale),
          value: 'male',
        },
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderFemale),
          value: 'female',
        },
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderOther),
          value: 'other',
        },
        {
          label: this.toStringService.toString(StringsKeys.editProfileGenderNoMatter),
          value: 'all',
        },
      ],
    });
  }

  public getInstNickNameChangeDialog(currentValue: string | undefined): Observable<string | undefined> {
    return this.openDialogComponent(EnterTextDialogComponent, {
      title: StringsKeys.editProfileInstagramTitle,
      inputLabel: StringsKeys.editProfileInstagramNicknameTitle,
      initialFormValue: currentValue,
      clearable: true,
      confirmButtonText: StringsKeys.btnSave,
    });
  }

  public getSupportRequestDialog(): Observable<string | false> {
    return this.openDialogComponent(EnterTextDialogComponent, {
      title: StringsKeys.supportScreenTitle,
      placeholder: StringsKeys.supportScreenHint,
      confirmButtonText: StringsKeys.btnSave,
      isTextarea: true,
      customHeight: 100,
    });
  }

  public getLogoutConfirmDialog(): Observable<boolean> {
    return this.openDialogComponent<GenericJokerConfirmDialogComponent, boolean>(GenericJokerConfirmDialogComponent, {
      title: this.toStringService.toString(StringsKeys.logoutTitle),
      subTitle: this.toStringService.toString(StringsKeys.logoutQuestion),
      closeBtn: this.toStringService.toString(StringsKeys.btnCancel),
      confirmBtn: this.toStringService.toString(StringsKeys.btnLogout),
      icon: './assets/images/light.svg',
    });
  }

  public getChangeThemeDialog(currentValue: ThemeMode): Observable<ThemeMode | false> {
    return this.openDialogComponent(ChooseItemDialogComponent, {
      title: this.toStringService.toString(StringsKeys.profileDayNightPopupTitle),
      currentValue,
      options: <ValueLabelItem<ThemeMode>[]>[
        {
          label: this.toStringService.toString(StringsKeys.profileDark),
          value: 'theme-dark',
        },
        {
          label: this.toStringService.toString(StringsKeys.profileLight),
          value: 'theme-light',
        },
        {
          label: this.toStringService.toString(StringsKeys.profileDayNightAuto),
          value: 'auto',
        },
      ],
    });
  }

  public getJokerStatDialog(
    stat: GamingHistoryStatItem,
    jokerImage: string,
    title: string,
    instagram?: string | null
  ): Observable<boolean> {
    return this.openDialogComponent<UserStatsDialogComponent, boolean>(UserStatsDialogComponent, {
      jokerImage,
      title,
      userStats: stat,
      instagram,
    });
  }

  public openBuyFreeGamesDialog(priceFeeItem: PriceFeeItem): Observable<boolean> {
    return this.openDialogComponent<BuyFreeGamesDialogComponent, boolean>(BuyFreeGamesDialogComponent, {
      priceInfo: priceFeeItem,
    });
  }

  public openJokerPurchaseInProgressDialog(): void {
    this.openDialogComponent(JokerPurchasePublishedDialogComponent).subscribe();
  }

  /**
   * @todo It makes sense to investigate the root of issue for the future
   * The problem is:
   * execution of DialogRef 'close' method sometimes throws unexpected error.
   * execution of 'closeAllDialogs' within catchable block is not good solution.
   */
  public catchableClose(dialogRef: ReturnType<DialogService['open']>, withValue?: unknown): void {
    try {
      if (dialogRef.ref) {
        dialogRef.close(withValue);
      }
    } catch (error) {
      this.closeAllDialogs();
    }
  }
}

import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../../../environments/environment';
import {
  CustomToastrService,
  DialogHelperService,
  JokerService,
  LocalStorageService,
  NavigationService,
  ToStringService,
} from '@services';
import { IWsErrorDto, JokerWsEvent, ResponseDto, StringsKeys, WsJokerResponse } from '@types';
import { ROUTES_ENUM } from '@constants';
import { filter, switchMap, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JokerSocketService {
  jokerSocketConnection: Socket;
  constructor(
    private jokerService: JokerService,
    private dialogHelperService: DialogHelperService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService,
    private navigationService: NavigationService
  ) {}

  public subscribeJokersSocketUpdate(): void {
    this.jokerSocketConnection = io(`${environment.wsUrl}/joker`, {
      auth: {
        token: LocalStorageService.getAccessToken(),
      },
      withCredentials: true,
      transports: ['websocket'],
    });

    this.jokerSocketConnection.on('connect', () => {
      this.jokerSocketConnection.onAny((e: string, response: ResponseDto<WsJokerResponse> | IWsErrorDto) => {
        if ('error' in response) {
          this.dialogHelperService.openDefaultErrorDialog();
          return;
        }

        const result = response.result;

        switch (result.type) {
          case JokerWsEvent.ORDINARY_JOKER_MINTED: {
            this.handleOrdinaryJokerMinted();
            return;
          }
          case JokerWsEvent.ORDINARY_JOKER_MINT_FAILED: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketJokerOrdinaryMintFailed));
            return;
          }
          case JokerWsEvent.ORDINARY_JOKER_RENEWED: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketJokerOrdinaryRenewed));
            this.jokerService.requestUserJokers().subscribe();
            return;
          }
          case JokerWsEvent.ORDINARY_JOKER_RENEW_FAILED: {
            this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketJokerOrdinaryRenewFailed));
            return;
          }
        }
      });
    });
  }

  private handleOrdinaryJokerMinted(): void {
    this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketJokerOrdinaryMinted));

    this.navigationService
      .isRouteActive$(ROUTES_ENUM.HOME, true)
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          return this.jokerService.requestUserJokers();
        })
      )
      .subscribe();
  }

  public unsubscribeOrdinaryJokerWsUpdate(): void {
    this.jokerSocketConnection?.disconnect();
  }
}

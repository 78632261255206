import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { DefaultDialogProps, StringsKeys } from '@types';

@Component({
  selector: 'lpt-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent implements OnInit {
  public readonly StringsKeys = StringsKeys;

  public isContent = false;
  public icon?: string;
  public title?: string;
  public desc?: string;
  public declineButton?: string;
  public acceptButton?: string;

  constructor(private dialogRef: DialogRef<DefaultDialogProps>) {}

  ngOnInit() {
    if (this.dialogRef.data.isContent) {
      this.isContent = true;
    }
    if (this.isContent) {
      return;
    }

    Object.keys(this.dialogRef.data).forEach((key) => {
      if ((<any>this.dialogRef.data)[key]) {
        (<any>this)[key] = (<any>this.dialogRef.data)[key];
      }
    });
  }

  public accept(): void {
    this.dialogRef.close(true);
  }

  public decline(): void {
    this.dialogRef.close(false);
  }
}

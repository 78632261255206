<div class="filter__container flex-between">
  <lpt-items-switcher></lpt-items-switcher>

  <div class="filter__right-section flex-center">
    <svg-icon
      class="filter__right-section-item cursor-pointer"
      src="./assets/images/sort-icon.svg"
      [svgStyle]="{ 'fill': getSortColor }"
      id="filter-panel-sort-icon-button"
      (click)="sortIconClicked.emit()"
    ></svg-icon>
    <svg-icon
      class="filter__right-section-item cursor-pointer"
      src="./assets/images/filter-icon.svg"
      [svgStyle]="{ 'fill': getFilterColor }"
      id="filter-panel-filter-icon-button"
      (click)="filterIconClicked.emit()"
    ></svg-icon>
  </div>
</div>

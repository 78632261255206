import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { BehaviorSubject } from 'rxjs';
import { JokerModel } from '@types';

@Component({
  selector: 'lpt-revoke-joker-dialog',
  templateUrl: './revoke-joker-dialog.component.html',
  styleUrls: ['./revoke-joker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RevokeJokerDialogComponent {
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);

  constructor(private dialogRef: DialogRef) {
    this.joker.next(this.dialogRef.data.joker);
  }
  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirmSelling(): void {
    this.dialogRef.close(true);
  }
}

<div class="text-wr">
  <div class="header flex-center">
    <div class="header__icon flex-center cursor-pointer" id="delete-account-dialog-close-button" (click)="closeDialog()">
      <svg-icon class="header__icon-svg" [src]="'./assets/images/arrow-left.svg'"></svg-icon>
    </div>
    <div class="header__title">{{StringsKeys.editProfileDeleteAccount| toString}}</div>
  </div>

  <div class="text__main-section">
    <form class="signup__form h-100" [formGroup]="state.form" (ngSubmit)="submit()">
      <div class="inputs-block">
        <div *ngIf="state.form.controls['otp'] as control" class="signup__form-control-wr">
          <div class="flex-between">
            <p class="control-label">{{StringsKeys.loginCodeTitle | toString}}</p>
            <button
              *ngIf="!state.remainingTime"
              type="submit"
              class="link signup__form-sendcode cursor-pointer"
              id="delete-account-dialog-send-otp-button"
              (click)="submitterId = 'send-otp'"
            >
              {{StringsKeys.loginSendCodeBtn | toString}}
            </button>
            <span *ngIf="state.remainingTime" class="link signup__form-sendcode">{{ state.remainingTime | remainingTime }}</span>
          </div>

          <lpt-input
            formControlName="otp"
            [clearable]="true"
            [valid]="control.dirty && control.valid"
            [invalid]="control.dirty && control.invalid"
            type="text"
            [mask]="mask"
            [placeholder]="StringsKeys.deleteAccountOtpInputPlaceholder | toString"
          ></lpt-input>

          <p class="shared-error">
            <ng-template [ngIf]="control.dirty && control.invalid && !!control.errors">
              <span *ngIf="control.errors['required']">{{StringsKeys.requiredFormError | toString}}</span>
              <span *ngIf="control.errors['otp'] || control.errors['minlength']"
                >{{StringsKeys.fieldCodeInvalid | toString}}</span
              >
            </ng-template>
          </p>
        </div>
        <div class="txt-secondary">
          {{StringsKeys.deleteAccountConfirmationTextPart1 | toString}}
          <span class="txt-primary account-email">{{email}}</span>
          {{StringsKeys.deleteAccountConfirmationTextPart2 | toString}}
        </div>
      </div>

      <button class="btn btn-orange" id="delete-account-dialog-delete-button" type="submit" (click)="submitterId = 'delete'">
        {{StringsKeys.btnDelete | toString}}
      </button>
    </form>
  </div>
</div>

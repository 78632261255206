import { Injectable } from '@angular/core';
import { MobileService } from './mobile.service';
import { AppSettingsService } from './app-settings.service';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { expandTelegramWebView, isTelegramWebView } from '@helpers';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class InitializerService {
  constructor(
    private mobileService: MobileService,
    private appSettingsService: AppSettingsService
  ) {}

  init(): Promise<void> {
    if (environment.production) {
      this.sentryInit();
    }

    if (isTelegramWebView()) {
      expandTelegramWebView();
    }

    this.appSettingsService.getAppSettings().subscribe();
    return Promise.resolve(this.mobileService.initVisualViewportResizeHandler());
  }

  private sentryInit(): void {
    Sentry.init({
      dsn: environment.sentryDsn,
      environment: 'production',
      release: environment.appVersion,
      beforeSend: (event) => {
        if (event.exception && event.exception.values) {
          for (const exception of event.exception.values) {
            // [*] Ignore events with 401 status code errors
            if (exception.value && /\s401\s/.test(exception.value)) {
              return null;
            }

            // [*] Ignore "Loading chunk [\d]+ failed" and reload instead
            const isLoadingChunkFailedCase = exception.value && /Loading chunk [\d]+ failed/.test(exception.value);
            const canBeReloaded = window.navigator.onLine && !LocalStorageService.getReloadedAfterLoadingChunkFailed();
            if (isLoadingChunkFailedCase && canBeReloaded) {
              LocalStorageService.setReloadedAfterLoadingChunkFailed();
              setTimeout(() => window.location.reload(), 2e3);
              return null;
            }

            // TODO: Other exceptions to ignore
            //  ...
          }
        }
        return event;
      },
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationPanelComponent } from './navigation-panel.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterLink, RouterLinkActive, RouterModule } from '@angular/router';

@NgModule({
  declarations: [NavigationPanelComponent],
  imports: [CommonModule, AngularSvgIconModule, RouterModule, RouterLink, RouterLinkActive],
  exports: [NavigationPanelComponent],
})
export class NavigationPanelModule {}

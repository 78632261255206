<ng-container *ngIf="joker | async as jokerValue">
  <div class="sell-joker__container dialog__container">
    <h3 class="sell-joker__title">Revoke</h3>

    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="sell-joker__title pt-10">#{{jokerValue.tokenId}}</h3>

    <lpt-joker-stats class="pt-10" [joker]="jokerValue"></lpt-joker-stats>

    <div class="sell-joker__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="revoke-joker-close-button" (click)="closeDialog()">Close</button>
      <button class="btn btn-orange" id="revoke-joker-confirm-button" (click)="confirmSelling()">Confirm</button>
    </div>
  </div>
</ng-container>

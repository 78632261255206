export const VIDEO_MEDIA_TRACK_CONSTRAINTS: MediaTrackConstraints = {
  facingMode: {
    ideal: 'user',
  },
  width: {
    ideal: window.innerHeight,
  },
  height: {
    ideal: window.innerWidth,
  },
};

export const QR_SCAN_VIDEO_MEDIA_TRACK_CONSTRAINTS: MediaTrackConstraints = {
  frameRate: 20,
  facingMode: {
    ideal: 'environment',
  },
};

<div class="filters__container">
  <div class="filters__dialog-title pos-rel">
    {{title}}

    <div
      class="filters__dialog-title__clear-btn"
      id="market-filter-dialog-clear-button"
      *ngIf="!isDefaultParams(newParams)"
      (click)="clearFilter()"
    >
      {{StringsKeys.marketPopupClearFilters | toString}}
    </div>
  </div>

  <div class="filters__type__container">
    <div class="filters__type-label">{{StringsKeys.marketPopupTypeTitle | toString}}</div>
    <div class="filters__type-options flex-between">
      <div
        class="filters__type-options__option"
        *ngFor="let type of types"
        [class.selected]="isInTheList(type)"
        [style.color]="getJokerClassColor(type)"
        id="market-filter-toggle"
        (click)="toggleClassInFilters(type)"
      >
        {{type | titlecase}}
      </div>
    </div>
  </div>

  <div class="filters__level__container">
    <div class="filters__level-label">{{StringsKeys.marketPopupLevelTitle | toString}}</div>
    <lpt-range-selector
      *ngIf="currentParams"
      [minValue]="currentParams.minLevel || 0"
      [maxValue]="currentParams.maxLevel || 30"
      [minRangeValue]="0"
      [maxRangeValue]="30"
      (rangeChanged)="levelRangeChanged($event)"
    ></lpt-range-selector>
  </div>

  <div class="flex-center">
    <button class="btn btn-orange" id="market-filter-dialog-apply-button" (click)="applyFilter()">
      {{StringsKeys.btnConfirm | toString}}
    </button>
  </div>
</div>

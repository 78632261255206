import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EEffectType, EffectModel, ResponseDto } from '@types';
import { BehaviorSubject, Observable, OperatorFunction, finalize, map, take, tap, timer } from 'rxjs';
import { CALL_APPLIED_EFFECT_TIMEOUT } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class EffectsService {
  private readonly _effects = new BehaviorSubject<EffectModel[] | null>(null);
  readonly effects$ = this._effects.asObservable();

  private readonly _localSoundAppliedEffect = new BehaviorSubject<EffectModel | null>(null);
  readonly localSoundAppliedEffect$ = this._localSoundAppliedEffect.asObservable();

  private readonly _localAnimationAppliedEffect = new BehaviorSubject<EffectModel | null>(null);
  readonly localAnimationAppliedEffect$ = this._localAnimationAppliedEffect.asObservable();

  private readonly _opponentAppliedSoundEffect = new BehaviorSubject<EffectModel | null>(null);
  readonly opponentAppliedSoundEffect$ = this._opponentAppliedSoundEffect.asObservable();

  private readonly _opponentAppliedAnimationEffect = new BehaviorSubject<EffectModel | null>(null);
  readonly opponentAppliedAnimationEffect$ = this._opponentAppliedAnimationEffect.asObservable();

  private readonly _hasActiveTimer = new BehaviorSubject<boolean>(false);
  readonly hasActiveTimer$ = this._hasActiveTimer.asObservable();

  constructor(private http: HttpClient) {}

  public getAllEffects(): Observable<EffectModel[]> {
    if (this._effects.value) {
      return <Observable<EffectModel[]>>this.effects$;
    }
    return this.http.get<ResponseDto<EffectModel[]>>(`${environment.apiUrl}/effects/all`).pipe(
      take(1),
      map(({ result }) => result),
      tap((effects) => this._effects.next(effects))
    );
  }

  public hasActiveTimer(): boolean {
    return this._hasActiveTimer.value;
  }

  public applyLocalEffect(effect: EffectModel | undefined, type: EEffectType): void {
    this._appliedEffectDistributor(effect, type, true);
  }

  public applyRemoteEffect(effect: EffectModel | undefined, type: EEffectType): void {
    this._appliedEffectDistributor(effect, type, false);
  }

  private _appliedEffectDistributor(e: EffectModel | undefined, type: EEffectType, isLocalEffect: boolean): void {
    switch (true) {
      case type === EEffectType.ANIMATION && isLocalEffect:
        this._localAnimationAppliedEffect.next(e || null);
        break;
      case type === EEffectType.SOUND && isLocalEffect:
        this._localSoundAppliedEffect.next(e || null);
        break;
      case type === EEffectType.ANIMATION && !isLocalEffect:
        this._opponentAppliedAnimationEffect.next(e || null);
        break;
      case type === EEffectType.SOUND && !isLocalEffect:
        this._opponentAppliedSoundEffect.next(e || null);
        break;
    }
  }

  public initAppliedLocalEffectTimeout(untilDestroyedCallback: () => OperatorFunction<0, unknown>): void {
    if (this._hasActiveTimer.value) {
      return;
    }

    this._hasActiveTimer.next(true);
    timer(CALL_APPLIED_EFFECT_TIMEOUT)
      .pipe(
        untilDestroyedCallback(),
        finalize(() => this.reset(true))
      )
      .subscribe();
  }

  public reset(onlyLocal = false): void {
    this._hasActiveTimer.next(false);
    this._localSoundAppliedEffect.next(null);
    this._localAnimationAppliedEffect.next(null);
    if (!onlyLocal) {
      this._opponentAppliedSoundEffect.next(null);
      this._opponentAppliedAnimationEffect.next(null);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SellJokerDialogComponent } from './sell-joker-dialog.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { JokerStatsModule } from '../joker-stats/joker-stats.module';
import { InputModule } from '../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';

@NgModule({
  declarations: [SellJokerDialogComponent],
  imports: [CommonModule, JokerImageModule, JokerStatsModule, InputModule, ReactiveFormsModule, InfoRowItemModule],
  exports: [SellJokerDialogComponent],
})
export class SellJokerDialogModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PlaybackMediaTracksService {
  private readonly videoStream = new BehaviorSubject<MediaStream | null | undefined>(undefined);
  readonly videoStream$ = this.videoStream.asObservable();
  //TODO: add the same logic for audio stream
  // It is better to add audio and video streams from game to this service for consistency.

  storeVideoStream(stream: MediaStream): void {
    this.videoStream.next(stream);
  }

  isVideoStreamDetached(): boolean {
    return this.videoStream.getValue() === null;
  }

  detachVideoStream(): void {
    this.detachVideo();
    this.videoStream.next(null);
  }

  private detachVideo(): void {
    if (this.videoStream?.value?.getTracks) {
      this.videoStream.value.getTracks().forEach((track) => track.stop());
    }
  }
}

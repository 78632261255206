/// <reference types="telegram-web-app" />

export function isTelegramWebView(): boolean {
  const tgInstance = window.Telegram;
  if (!tgInstance) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const { WebView, WebApp } = tgInstance;
  if (!WebView && !WebApp) {
    return false;
  }
  return (WebView && WebView.isIframe) || (WebApp && WebApp.initData);
}

export function closeTelegramWebView(): void {
  const tgInstance = window.Telegram;
  tgInstance.WebApp.close();
}

export function expandTelegramWebView(): void {
  const tgInstance = window.Telegram;
  tgInstance.WebApp.expand();
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { MarketOrder, SellingInfo, StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { MarketService, SpinnerService } from '@services';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'lpt-buy-joker-dialog',
  templateUrl: './buy-joker-dialog.component.html',
  styleUrls: ['./buy-joker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyJokerDialogComponent implements OnInit {
  public StringsKeys = StringsKeys;
  public marketOrder: BehaviorSubject<MarketOrder | null> = new BehaviorSubject<MarketOrder | null>(null);

  public buyingInfo: BehaviorSubject<SellingInfo | null> = new BehaviorSubject<SellingInfo | null>(null);

  constructor(
    private dialogRef: DialogRef,
    private marketService: MarketService,
    private spinnerService: SpinnerService
  ) {
    this.marketOrder.next(this.dialogRef.data.marketOrder);
  }

  async ngOnInit() {
    await this.getBuyingInfo();
  }

  private async getBuyingInfo(): Promise<void> {
    const feeObj = await firstValueFrom(this.marketService.getBuyingFee());
    if (!this.marketOrder.value) return;

    this.buyingInfo.next({
      sellingPrice: this.marketOrder.value.price,
      fee: feeObj.fee,
      asset: feeObj.asset,
    });
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public confirmBuying(jokerId: string): void {
    this.spinnerService.showGlobalSpinner();
    this.marketService.buyJoker(jokerId).subscribe({
      next: () => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogRef.close(true);
      },
      error: (err: HttpErrorResponse) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogRef.close(err.error.payload || false);
      },
    });
  }
}

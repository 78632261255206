import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, take, tap } from 'rxjs';
import { JokerModel, ResponseDto } from '@types';
import { environment } from '../../../../../environments/environment';
import camelcaseKeys from 'camelcase-keys';
import { DialogHelperService } from '../dialog-helper.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner.service';

@Injectable({
  providedIn: 'root',
})
export class JokerDetailsService {
  private jokerDetails: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);

  public jokerDetails$: Observable<JokerModel | null> = this.jokerDetails.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private spinnerService: SpinnerService
  ) {}

  public setJokerDetails(joker: JokerModel | null): void {
    this.jokerDetails.next(joker);
  }

  public getJokerDetails(tokenId: string, displaySpinner = true): Observable<JokerModel> {
    if (displaySpinner) this.spinnerService.showGlobalSpinner();

    return this.http.get<ResponseDto<JokerModel>>(`${environment.apiUrl}/jokers/${tokenId}`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      tap((joker) => {
        this.spinnerService.hideGlobalSpinner();
        this.setJokerDetails(joker);
      }),
      catchError((e) => {
        this.spinnerService.hideGlobalSpinner();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, catchError, take, tap, map, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TinyFaceDetectorOptions } from 'face-api.js';
import { AppSetting, AppSettingKeys, ResponseDto } from '@types';
import { BuildFaceDetectorOptions } from '@helpers';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  private settings: Array<AppSetting> = [];

  private _faceapiMinimumConfidenceThreshold!: number;
  private _faceapiProcessedImageSize!: number;

  constructor(private http: HttpClient) {}

  public getAppSettings(): Observable<AppSetting[]> {
    if (this.settings.length) {
      return of(this.settings);
    }

    if (!LocalStorageService.hasAccessToken()) {
      return of([]);
    }

    return this.http.get<ResponseDto<AppSetting[]>>(`${environment.apiUrl}/application-settings`).pipe(
      take(1),
      map(({ result }) => result),
      tap((settings) => {
        this.settings = settings;
        // TODO: to be announced
        // Target of these definitions is: using not-hardcoded TinyFaceDetectorOptions values and load them from application settings.
        //
        // this._faceapiProcessedImageSize = settings.faceapi_input_size;
        // this._faceapiMinimumConfidenceThreshold = settings.faceapi_score_threshold;
      }),
      catchError(() => EMPTY)
    );
  }

  public getAppSettingByKey(key: AppSettingKeys): Observable<AppSetting> {
    return this.getAppSettings().pipe(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      map((settings) => settings.find((s) => s.name === key)!)
    );
  }

  public getLoadedFaceDetectorOptions(): TinyFaceDetectorOptions {
    return BuildFaceDetectorOptions(this._faceapiProcessedImageSize, this._faceapiMinimumConfidenceThreshold);
  }
}

<div class="stats__container">
  <div class="stats__item">
    <div class="stats__item-type">Class</div>
    <div class="stats__item-value" [style.color]="getJokerClassColor(joker.klass)">{{joker.klass | titlecase}}</div>
  </div>

  <div class="stats__divider"></div>

  <div class="stats__item">
    <div class="stats__item-type">Craziness</div>
    <div class="stats__item-value" *ngIf="crazinessAttribute">{{crazinessAttribute.value}}/{{crazinessAttribute.maxValue}}</div>
  </div>

  <div class="stats__divider"></div>

  <div class="stats__item">
    <div class="stats__item-type">Level</div>
    <div class="stats__item-value" *ngIf="levelAttribute">{{levelAttribute.value}}</div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'timeWithPostfix',
})
export class TimeWithPostfixPipe implements PipeTransform {
  // should be amount in seconds
  transform(value: number): string {
    const duration = intervalToDuration({
      start: 0,
      end: value * 1000, // to milliseconds
    });

    const formatDistanceLocale = { xSeconds: '{{count}} sec', xMinutes: '{{count}} min', xHours: '{{count}} h' };
    const shortEnLocale = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      formatDistance: (token: any, count: any) => formatDistanceLocale[token].replace('{{count}}', count),
    };

    return formatDuration(duration, { format: ['hours', 'minutes', 'seconds'], locale: shortEnLocale });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserStatsDialogComponent } from './user-stats-dialog.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { GamingHistoryStatItemModule } from '../gaming-history-stat-item/gaming-history-stat-item.module';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [UserStatsDialogComponent],
  imports: [CommonModule, JokerImageModule, GamingHistoryStatItemModule, InfoRowItemModule, ToStringPipeModule],
})
export class UserStatsDialogModule {}

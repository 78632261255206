export enum ROUTES_ENUM {
  HOME = 'home',
  LOGIN = 'login',
  TELEGRAM_LOGIN_REDIRECT = 'auth/telegram/redirect',
  DASHBOARD = 'dashboard',
  MARKETPLACE = 'marketplace',
  WALLET = 'wallet',
  GAME = 'game',
  JOKERS = 'jokers',
  PROFILE = 'profile',
  EDIT_PROFILE = 'edit-profile',
  GAMING_HISTORY = 'gaming-history',
  NOTIFICATIONS = 'notifications',
  ROOMSMAKING = 'roomsmaking',
  ONBOARDING = 'onboarding',
  SINGLE_MODE = 'single-mode',
  EARN = 'earn',
  FRIENDS = 'friends',
}

export enum ROOMSMAKING_ROUTES_SCOPE {
  CREATE_ROOM = 'create',
  JOIN_ROOM = 'join',
}

<ng-container *ngIf="swiperItems.length && currentIndex!==null">
  <swiper [config]="config" [initialSlide]="currentIndex" (slideChangeTransitionEnd)="onSlideChange($event)">
    <ng-container *ngFor="let slide of swiperItems; trackBy: jokersTrackBy">
      <ng-template swiperSlide>
        <div class="joker-image-container" (click)="jokerClicked.emit(currentIndex)">
          <lpt-joker-image
            [image]="slide.imageUrl"
            [nickname]="slide.label"
            [useNumberSign]="true"
            [size]="'md'"
            [customMediaClass]="'default-md'"
            [hasSellingLabel]="slide.isOnSale"
          ></lpt-joker-image>
        </div>
      </ng-template>
    </ng-container>
  </swiper>
</ng-container>

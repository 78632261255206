<div class="joker__img-wr" [ngClass]="[size ,customMediaClass]" [class.circle]="circleAppearance">
  <span *ngIf="hasSellingLabel" class="joker__img-selling-label txt-primary">{{StringsKeys.jokerIsOnSaleTitle | toString}}</span>
  <img
    *ngIf="image; else defaultImage"
    class="joker__img w-100 h-100"
    [class.no-bottom-radius]="!hasBottomRadius"
    [class.circle]="circleAppearance"
    [src]="image"
    loading="lazy"
    (load)="markImageAsLoaded()"
    alt="Art"
    lptImageCache
  />

  <ng-template [ngIf]="useLazyLoadingSpinner">
    <div *ngIf="!isImageFullyLoaded" class="joker__img-spinnerloader pos-abs w-100 h-100">
      <ngx-spinner
        name="market-loker-spinnerloader"
        [fullScreen]="false"
        [bdColor]="Colors.Transparent"
        [template]="jokerImageLoadingSpinner"
      ></ngx-spinner>
      <ng-template *ngTemplateOutlet="defaultImage"></ng-template>
    </div>
  </ng-template>

  <ng-template #defaultImage>
    <svg-icon
      class="joker__img w-100 h-100"
      [class.no-bottom-radius]="!hasBottomRadius"
      [class.circle]="circleAppearance"
      [src]="useSilhouettes ? silhouettePath : (defaultNoImagePath$ | async) || ''"
      alt="Art"
    />
  </ng-template>

  <div class="joker__img-overlay w-100 h-100"></div>

  <div *ngIf="nickname" class="joker__img-nickname">
    <span><span *ngIf="useNumberSign">#</span>{{ nickname }}</span>
  </div>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { StringsKeys } from '@types';

@Component({
  selector: 'lpt-user-stats-dialog',
  templateUrl: './user-stats-dialog.component.html',
  styleUrls: ['./user-stats-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatsDialogComponent {
  public readonly StringsKeys = StringsKeys;

  public jokerImage: string;
  public title: string;
  public userStats: [string, string];
  public instagram?: string;

  constructor(public dialogRef: DialogRef) {
    this.jokerImage = this.dialogRef.data.jokerImage;
    this.title = this.dialogRef.data.title;
    this.userStats = this.dialogRef.data.userStats && Object.entries(this.dialogRef.data.userStats);
    this.instagram = this.dialogRef.data.instagram;
  }
}

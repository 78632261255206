import { CurrencyType } from '@types';

export const getTokenIconByCurrencyCode = (currencyCode: CurrencyType): string => {
  let iconName = '';
  if (currencyCode === CurrencyType.BNB) iconName = 'bnb.svg';
  if (currencyCode === CurrencyType.LRT) iconName = 'earning-token.svg';
  if (currencyCode === CurrencyType.NFT) iconName = 'governance-token.svg';

  return `./assets/images/${iconName}`;
};

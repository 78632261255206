import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomToastrService, DialogHelperService, NavigationService, SpinnerService, ToStringService } from '@services';
import { catchError, combineLatest, filter, map, Observable, repeat, switchMap, take, tap } from 'rxjs';
import { InsufficientFundsError, JokerModel, PriceFeeItem, ResponseDto, StringsKeys } from '@types';
import { environment } from '../../../../../environments/environment';
import camelcaseKeys from 'camelcase-keys';
import {
  GenericJokerConfirmDialogComponent,
  InsufficientFundsDialogComponent,
  JokerLevelUpComponent,
  LevelUpUpdateTimeComponent,
} from '@components';
import { getTokenIconByCurrencyCode } from '@helpers';
import { DecimalPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class JokerLevelUpService {
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private dialogHelperService: DialogHelperService,
    private navigationService: NavigationService,
    private toStringService: ToStringService,
    private decimalPipe: DecimalPipe,
    private customToastrService: CustomToastrService
  ) {}

  public getJokerLevelUpFee(tokenId: string): Observable<PriceFeeItem> {
    return this.http.get<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/jokers/${tokenId}/level-up/fee`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

        if (insufficientFundsError) {
          this.openInsufficientFundsDialog(insufficientFundsError);
          throw e;
        }

        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getJokerRemainingCooldown(tokenId: string): Observable<{ cooldownTimer: number }> {
    return this.http
      .get<ResponseDto<{ cooldownTimer: number }>>(`${environment.apiUrl}/jokers/${tokenId}/cooldown/remaining`)
      .pipe(
        take(1),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        catchError((e) => {
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public getJokerLevelUpCooldown(tokenId: string): Observable<{ cooldownTimer: number }> {
    return this.http.get<ResponseDto<{ cooldownTimer: number }>>(`${environment.apiUrl}/jokers/${tokenId}/cooldown`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getJokerLevelUpBoostFee(tokenId: string): Observable<PriceFeeItem> {
    return this.http.get<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/jokers/${tokenId}/boost/fee`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      catchError((e) => {
        const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

        if (insufficientFundsError) {
          this.dialogHelperService.openInsufficientFundsDialog(insufficientFundsError);
          throw e;
        }
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public boostJokerLevelUp(tokenId: string): Observable<null> {
    return this.http
      .post<null>(
        `${environment.apiUrl}/jokers/${tokenId}/boost`,
        {},
        {
          params: { tokenId },
        }
      )
      .pipe(
        take(1),
        catchError((e) => {
          const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

          if (insufficientFundsError) {
            this.openInsufficientFundsDialog(insufficientFundsError);
            throw e;
          }

          this.customToastrService.show('', this.toStringService.toString(StringsKeys.socketTokenomicsBoostFail), 2000, 'error');
          throw e;
        })
      );
  }

  public levelUpJoker(tokenId: string): Observable<null> {
    this.spinnerService.showGlobalSpinner();

    return this.http
      .post<ResponseDto<any>>(
        `${environment.apiUrl}/jokers/${tokenId}/level-up`,
        {},
        {
          params: {
            tokenId,
          },
        }
      )
      .pipe(
        take(1),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        tap(() => this.spinnerService.hideGlobalSpinner()),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public handleLevelUp(joker: JokerModel): void {
    this.spinnerService.showGlobalSpinner();

    this.getRemainingAndAllLevelUpTime(joker)
      .pipe(
        switchMap(({ remainingTime, allLevelUpTime }) => {
          if (remainingTime) {
            this.spinnerService.hideGlobalSpinner();
            return this.handleRemainingTimeCase(joker, allLevelUpTime, remainingTime);
          }
          return this.handleLevelUpInfoCase(joker, allLevelUpTime);
        })
      )
      .subscribe({
        next: () => this.spinnerService.hideGlobalSpinner(),
        error: () => this.spinnerService.hideGlobalSpinner(),
      });
  }

  private handleLevelUpInfoCase(joker: JokerModel, timerCooldown: number) {
    return this.getJokerLevelUpFee(joker.tokenId).pipe(
      tap(() => this.spinnerService.hideGlobalSpinner()),
      switchMap((levelUpInfo) =>
        this.dialogHelperService.openDialogComponent<JokerLevelUpComponent, boolean>(JokerLevelUpComponent, {
          joker,
          levelUpInfo,
          timerCooldown,
        })
      ),
      filter(Boolean),
      switchMap(() => this.levelUpJoker(joker.tokenId)),
      tap(() => this.spinnerService.showGlobalSpinner()),
      switchMap(() => this.getRemainingAndAllLevelUpTime(joker, true)),
      tap(() => this.spinnerService.hideGlobalSpinner()),
      switchMap(({ remainingTime, allLevelUpTime }) => this.handleRemainingTimeCase(joker, allLevelUpTime, remainingTime))
    );
  }

  private handleRemainingTimeCase(joker: JokerModel, allLevelUpTime: number, remainingTime: number): Observable<boolean | null> {
    return this.dialogHelperService
      .openDialogComponent<LevelUpUpdateTimeComponent, boolean>(LevelUpUpdateTimeComponent, {
        joker,
        allLevelUpTime: allLevelUpTime,
        remainingTime: remainingTime,
      })
      .pipe(
        filter(Boolean),
        tap(() => this.spinnerService.showGlobalSpinner()),
        switchMap(() => this.getJokerLevelUpBoostFee(joker.tokenId)),
        tap(() => this.spinnerService.hideGlobalSpinner()),
        switchMap((feeInfo) => this.getBoostDialog(joker, feeInfo)),
        filter(Boolean),
        tap(() => this.spinnerService.showGlobalSpinner()),
        switchMap(() => this.boostJokerLevelUp(joker.tokenId)),
        tap(() => {
          this.spinnerService.hideGlobalSpinner();
          this.customToastrService.show('', this.toStringService.toString(StringsKeys.toastrBoostHasBeenRequested));
        })
      );
  }

  private getBoostDialog(joker: JokerModel, feeInfo: PriceFeeItem): Observable<boolean> {
    return this.dialogHelperService.openDialogComponent<GenericJokerConfirmDialogComponent, boolean>(
      GenericJokerConfirmDialogComponent,
      {
        joker,
        title: this.toStringService.toString(StringsKeys.levelUpPopupBoostTitle),
        closeBtn: this.toStringService.toString(StringsKeys.btnClose),
        confirmBtn: this.toStringService.toString(StringsKeys.btnBoost),
        rows: [
          {
            leftText: this.toStringService.toString(StringsKeys.popupPricePriceTitle),
            rightText: this.decimalPipe.transform(feeInfo.price.amount, '1.0-2') + ' ' + feeInfo.price.currency,
            rightIconSrc: getTokenIconByCurrencyCode(feeInfo.price.currency),
          },
          {
            leftText: this.toStringService.toString(StringsKeys.popupPriceTransactionFeeTitle),
            rightText: this.decimalPipe.transform(feeInfo.fee.amount, '1.0-5') + ' ' + feeInfo.fee.currency,
            rightIconSrc: getTokenIconByCurrencyCode(feeInfo.fee.currency),
          },
        ],
      }
    );
  }

  private getRemainingAndAllLevelUpTime(
    joker: JokerModel,
    retryRemainingTimeRequest = false
  ): Observable<{ remainingTime: number; allLevelUpTime: number }> {
    return combineLatest([
      // Make refetch each 2s if remaining cooldown isn't updated after level up post request
      retryRemainingTimeRequest ? this.getRemainingCooldownWithRefetch(joker) : this.getJokerRemainingCooldown(joker.tokenId),
      this.getJokerLevelUpCooldown(joker.tokenId),
    ]).pipe(
      map(([{ cooldownTimer: remainingTime }, { cooldownTimer: allLevelUpTime }]) => ({
        remainingTime: remainingTime / 1000, // to seconds
        allLevelUpTime: allLevelUpTime / 1000, // to seconds
      }))
    );
  }

  private getRemainingCooldownWithRefetch(joker: JokerModel): Observable<{ cooldownTimer: number }> {
    return this.getJokerRemainingCooldown(joker.tokenId).pipe(
      repeat({ delay: 2000 }),
      filter(({ cooldownTimer }) => Boolean(cooldownTimer)),
      take(1)
    );
  }

  private openInsufficientFundsDialog(insufficientFundsError: InsufficientFundsError): void {
    this.dialogHelperService
      .openDialogComponent<InsufficientFundsDialogComponent, boolean>(InsufficientFundsDialogComponent, {
        insufficientFundsError,
      })
      .pipe(
        tap((res) => {
          if (res) this.navigationService.toWallet();
        })
      )
      .subscribe();
  }
}

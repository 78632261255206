import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isIos } from '@helpers';

@Component({
  selector: 'lpt-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() inputValue!: string | number;
  @Input() clearable = false;
  @Input() disabled = false;
  @Input() focus = false;
  @Input() valid = false;
  @Input() invalid = false;
  @Input() placeholder = '';
  @Input() type: HTMLInputElement['type'] = 'text';
  @Input() maxLength = 256;
  @Input() mask?: string;
  @Input() isTextarea = false;

  @Output() clearOutput = new EventEmitter<void>();

  public onChange!: (value: string | number) => void;
  public onTouched!: () => void;

  public navigationKeys: Array<string> = ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter']; //Add keys as per requirement

  constructor(protected cdr: ChangeDetectorRef) {}

  @ViewChild('input') inputElement!: ElementRef<HTMLInputElement>;

  changeValue(value: string | number): void {
    this.inputValue = value;
    this.onChange(value);
    this.cdr.detectChanges();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string | number): void {
    if (this.type === 'number' && value) {
      value = value.toString().replace(/\s/g, '');
      value = Number(value);
    }
    this.inputValue = value || '';
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  public handleNumberTypeEvents(e: KeyboardEvent): void {
    if (this.type !== 'number') return;
    if (this.inputValue.toString().includes('.') && e.key === '.') {
      e.preventDefault();
      return;
    }
    if (
      // Allow: Delete, Backspace, Tab, Escape, Enter, etc
      this.navigationKeys.indexOf(e.key) > -1 ||
      (e.key === 'a' && e.ctrlKey) ||
      (e.key === 'c' && e.ctrlKey) ||
      (e.key === 'v' && e.ctrlKey) ||
      (e.key === 'x' && e.ctrlKey) ||
      (e.key === 'a' && e.metaKey) ||
      (e.key === 'c' && e.metaKey) ||
      (e.key === 'v' && e.metaKey) ||
      (e.key === 'x' && e.metaKey) ||
      e.key === '.' // allow . for decimals
    ) {
      return; // let it happen, don't do anything
    }
    // Ensure that it is a number and stop the keypress
    if (e.key === ' ' || isNaN(Number(e.key))) {
      e.preventDefault();
    }
  }

  onFocus(): void {
    // Handling ios keyboard autoscrolling
    if (isIos()) {
      setTimeout(() => {
        const minPaddingFromTop = 50;
        const y = this.inputElement.nativeElement.getBoundingClientRect().y;
        if (y < minPaddingFromTop) {
          window.scrollBy(0, -minPaddingFromTop + y);
        }
      }, 200);
    }
  }

  clear(): void {
    this.clearOutput.emit();
    if (this.disabled) {
      return;
    }
    this.inputValue = '';
    this.changeValue(this.inputValue);
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.inputElement.nativeElement.focus();
    }
  }

  onBlur(): void {
    if (this.hasOnlySpaces(this.inputValue)) {
      this.clear();
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private hasOnlySpaces(value: string | number): boolean {
    if (typeof value === 'number') {
      return String(value).trim() === '';
    }
    return value.trim() === '';
  }
}

<ng-container *ngIf="marketOrder | async as marketOrder">
  <div class="buy-joker__container dialog__container">
    <h3 class="buy-joker__title">{{StringsKeys.popupBuyTitle | toString}}</h3>

    <lpt-joker-image [image]="marketOrder.joker.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="buy-joker__title pt-10">#{{marketOrder.joker.tokenId}}</h3>

    <lpt-joker-stats class="pt-10" [joker]="marketOrder.joker"></lpt-joker-stats>

    <div class="buy-joker__info w-100" *ngIf="buyingInfo | async as sellingInfoItem">
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="'Selling price'"
        [rightText]="sellingInfoItem.sellingPrice + ' ' + sellingInfoItem.asset"
        [rightIconSrc]="'./assets/images/bnb.svg'"
      ></lpt-info-row-item>
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="'Commission'"
        [rightText]="sellingInfoItem.fee + ' ' + sellingInfoItem.asset"
        [rightIconSrc]="'./assets/images/bnb.svg'"
      ></lpt-info-row-item>
    </div>

    <div class="buy-joker__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="buy-joker-close-button" (click)="closeDialog()">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button class="btn btn-orange" id="buy-joker-confirm-button" (click)="confirmBuying(marketOrder.joker.tokenId)">
        {{StringsKeys.btnConfirm | toString}}
      </button>
    </div>
  </div>
</ng-container>

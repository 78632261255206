<div class="balance__container flex-center">
  <div class="balance__item" *ngFor="let token of tokens">
    <ng-container *ngIf="walletService.walletInfo$ | async as walletInfo; else emptyAmountTemplate">
      <div class="balance__item-amount">{{ walletInfo.balance[token.name] | number:token.precision }}</div>
    </ng-container>
    <svg-icon class="balance__item-icon" [src]="token.icon"></svg-icon>
  </div>
</div>

<ng-template #emptyAmountTemplate>
  <span class="balance__item-amount">-</span>
</ng-template>

import { Injectable } from '@angular/core';
import { debounceTime, fromEvent } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private lastViewportHeight = 0;
  private readonly visualViewportResizeDebounceTime = 100;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public initVisualViewportResizeHandler(): void {
    this.setVhProperty();

    fromEvent(window.visualViewport || window, 'resize')
      .pipe(debounceTime(this.visualViewportResizeDebounceTime))
      .subscribe(() => {
        const height = window.visualViewport?.height || window.innerHeight;
        if (this.lastViewportHeight !== height) {
          this.lastViewportHeight = height;
          this.setVhProperty();
        }
      });
  }

  private setVhProperty(): void {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
  }

  public initHistoryPopStatePreventer(): void {
    window.history.pushState(null, document.title, location.href);
    window.addEventListener('popstate', this.onPopStateEventListener);
  }

  private onPopStateEventListener() {
    window.history.pushState(null, document.title, location.href);
  }

  public removeHistoryPopStatePreventer(): void {
    window.removeEventListener('popstate', this.onPopStateEventListener);
  }
}

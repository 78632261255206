import { Pipe, PipeTransform, inject } from '@angular/core';
import { ToStringService } from '@services';
import { StringsKeys } from '@types';

@Pipe({
  name: 'toString',
})
export class ToStringPipe implements PipeTransform {
  private readonly toStringsService = inject(ToStringService);

  transform(key: StringsKeys, props?: Record<string, any>): string {
    return this.toStringsService.toString(key, props);
  }
}

<div class="custom-slider">
  <div class="current-value" *ngIf="displayMinMax">
    <div class="current-value__min">{{currentMinValue}}</div>
    <div class="current-value__max">{{currentMaxValue}}</div>
  </div>

  <ngx-slider
    *ngIf="singleSelector;else doubleSelector"
    [value]="minValue"
    [options]="options"
    (valueChange)="minValueChange($event)"
    (highValueChange)="maxValueChange($event)"
  ></ngx-slider>

  <ng-template #doubleSelector>
    <ngx-slider
      [value]="minValue"
      [highValue]="maxValue"
      [options]="options"
      (valueChange)="minValueChange($event)"
      (highValueChange)="maxValueChange($event)"
    ></ngx-slider>
  </ng-template>
</div>

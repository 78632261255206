<div class="joker-purchased__container dialog__container">
  <svg-icon [src]="'./assets/images/ok-icon.svg'"></svg-icon>

  <h3 class="joker-purchased__title">{{StringsKeys.jokerSuccessBuyRequest | toString}}</h3>

  <div class="joker-purchased__action-buttons w-100 flex-between">
    <button class="btn btn-white" id="joker-purchase-published-dialog-close-button" (click)="closeDialog()">
      {{StringsKeys.btnClose | toString}}
    </button>
  </div>
</div>

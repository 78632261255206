import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject, fromEvent, map, Observable, Subscription } from 'rxjs';
import { Theme, ThemeMode } from '@types';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private readonly themeLight = 'theme-light';
  private readonly themeDark = 'theme-dark';

  private currentThemeMode: BehaviorSubject<ThemeMode> = new BehaviorSubject<ThemeMode>('auto');

  public currentThemeMode$: Observable<ThemeMode> = this.currentThemeMode.asObservable();

  private currentThemeColor: BehaviorSubject<Theme> = new BehaviorSubject<Theme>('theme-light');

  public currentColorTheme$ = this.currentThemeColor.asObservable();

  public systemThemeSub: Subscription;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public setThemeMode(themeMode: ThemeMode): void {
    LocalStorageService.setThemeMode(themeMode);

    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

    if (themeMode === 'auto') {
      this.handleAutoThemeMode(themeMode, prefersDark);
      return;
    }

    this.unsubscribeToSystemThemeChangeEvent(prefersDark);
    this.currentThemeColor.next(themeMode);
    this.currentThemeMode.next(themeMode);
    this.applyTheme(themeMode);
  }

  private handleAutoThemeMode(theme: ThemeMode, prefersDark: MediaQueryList): void {
    const newTheme: Theme = prefersDark.matches ? 'theme-dark' : 'theme-light';

    this.currentThemeColor.next(newTheme);
    this.currentThemeMode.next(theme);

    this.applyTheme(newTheme);

    this.subscribeToSystemThemeChangeEvent(prefersDark);
  }

  public subscribeToSystemThemeChangeEvent(prefersDark: MediaQueryList): void {
    this.systemThemeSub = fromEvent<MediaQueryListEvent>(prefersDark, 'change').subscribe((event: MediaQueryListEvent) => {
      this.themeChangeEventListener(event);
    });
  }

  public unsubscribeToSystemThemeChangeEvent(prefersDark: MediaQueryList): void {
    this.systemThemeSub?.unsubscribe();
  }

  public themeChangeEventListener = (event: MediaQueryListEvent) => {
    const newTheme: Theme = event.matches ? 'theme-dark' : 'theme-light';
    this.currentThemeColor.next(newTheme);
    this.applyTheme(newTheme);
  };

  private applyTheme(theme: Theme) {
    this.document.body.classList.remove(this.themeLight, this.themeDark);
    this.document.body.classList.add(theme);
  }

  public initTheme(): void {
    const localStorageTheme = LocalStorageService.getThemeMode() as ThemeMode | null;

    this.setThemeMode(localStorageTheme || 'auto');
  }

  public get isCurrentThemeLight$(): Observable<boolean> {
    return this.currentColorTheme$.pipe(map((value) => value === 'theme-light'));
  }
}

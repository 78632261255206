import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { isMobile, isTablet } from '@helpers';
import { DialogHelperService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class DesktopGuard implements CanActivateChild {
  constructor(private dialogHelperService: DialogHelperService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!isMobile() || isTablet()) {
      this.dialogHelperService.openUnsupportedDeviceDialog();
      return false;
    }
    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!isMobile() || isTablet()) {
      this.dialogHelperService.openUnsupportedDeviceDialog();
      return false;
    }
    return true;
  }
}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { StringsKeys } from '@types';
import { SpinnerService, ThemeService } from '@services';
import { map } from 'rxjs';
import { Colors, spinnerTemp } from '@constants';

@Component({
  selector: 'lpt-joker-image',
  templateUrl: './joker-image.component.html',
  styleUrls: ['./joker-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerImageComponent implements OnInit {
  @Input() image?: string;
  @Input() nickname: string | null | undefined;
  @Input() size: 'sm' | 'md' | 'lg' = 'md';
  @Input() customMediaClass: 'default-md' | 'resizable-md' | 'custom-media-1' = 'resizable-md';
  @Input() useNumberSign = false;
  @Input() useSilhouettes = false;
  // todo: will be announced
  // Animation of random jokers during the pairing time.
  @Input() animateSilhouettes = false;
  @Input() hasBottomRadius = true;
  @Input() circleAppearance = false;
  @Input() hasSellingLabel = false;
  @Input() useLazyLoadingSpinner = false;

  public readonly StringsKeys = StringsKeys;
  public readonly Colors = Colors;
  public readonly jokerImageLoadingSpinner = spinnerTemp();
  public isImageFullyLoaded = false;

  public readonly defaultNoImagePath$ = this.themeService.isCurrentThemeLight$.pipe(
    map((isLight) => {
      const theme = isLight ? 'light' : 'dark';
      return `./assets/images/joker-fallback-image-${theme}.svg`;
    })
  );
  public readonly silhouettePath = './assets/images/silhouettes/silhouette_1.svg';

  constructor(
    private themeService: ThemeService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    if (this.image) this.spinnerService.showLocalSpinner('market-loker-spinnerloader');
  }

  public markImageAsLoaded(): void {
    if (this.useLazyLoadingSpinner) this.isImageFullyLoaded = true;
  }
}

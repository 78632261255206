<div class="select-points__wr flex-between">
  <div class="select-points__left-block flex-center">
    <svg-icon [src]="iconSrc"></svg-icon>
    <div class="txt-primary fz-14">{{title}}</div>
  </div>
  <div class="select-points__right-block flex-center fz-14 txt-primary">
    <div class="minus-btn txt-secondary fz-32 cursor-pointer" id="remove-point-button" (click)="removePoint()">-</div>
    <input class="select-points__input" [value]="val" [readOnly]="true" />
    <div class="plus-btn txt-secondary fz-32 cursor-pointer" id="add-point-button" (click)="addPoint()">+</div>
  </div>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { PlayMode, StringsKeys } from '@types';

@Component({
  selector: 'lpt-choose-play-mode',
  templateUrl: './choose-play-mode.component.html',
  styleUrls: ['./choose-play-mode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoosePlayModeComponent {
  public StringsKeys = StringsKeys;
  public playMode = PlayMode;
  constructor(private dialogRef: DialogRef) {}

  public close(playMode: PlayMode): void {
    this.dialogRef.close(playMode);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { InputComponent } from './input.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, NgxMaskModule.forChild(), AngularSvgIconModule],
  exports: [InputComponent],
})
export class InputModule {}

import { Injectable } from '@angular/core';
import { GamePreflightService, NavigationService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class RoomsmakingGuard {
  constructor(
    private readonly preflightService: GamePreflightService,
    private readonly navigationService: NavigationService
  ) {}

  canActivate(): Promise<boolean> | boolean {
    return this.preflightService.isReadyToMountRoomsmakingLayout() || this.navigationService.toDashboard().then(() => false);
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenericSimpleChange, ValueLabelItem } from '@types';

interface ItemsSwitcherComponentInputs extends SimpleChanges {
  switcherItems: GenericSimpleChange<ValueLabelItem<string>[]>;
}

@Component({
  selector: 'lpt-items-switcher',
  templateUrl: './items-switcher.component.html',
  styleUrls: ['./items-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsSwitcherComponent implements OnChanges {
  @Input() public switcherItems: ValueLabelItem<string>[] = [
    {
      label: 'Jokers',
      value: 'jokers',
    },
    {
      label: 'Items',
      value: 'items',
    },
  ];
  @Input() public withCounters = false;
  @Output() public activeChangedEmit: EventEmitter<ValueLabelItem<string>> = new EventEmitter<ValueLabelItem<string>>();

  ngOnChanges(changes: ItemsSwitcherComponentInputs) {
    if (changes.switcherItems.currentValue && changes.switcherItems.firstChange) {
      this.active = changes.switcherItems.currentValue[0].value;
    }
  }

  public active: string = this.switcherItems?.[0].value;

  public activeChanged(item: ValueLabelItem<string>): void {
    this.active = item.value;
    this.activeChangedEmit.emit(item);
  }
}

import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GoogleAnalyticsEventsType, NavigationItem, NavigationItemIdentifier } from '@types';

const navigationItemGoogleAnalyticsEventsType = new Map([
  [NavigationItemIdentifier.Dashboard, GoogleAnalyticsEventsType.tabDashboard],
  [NavigationItemIdentifier.Earn, GoogleAnalyticsEventsType.tabEarn],
  [NavigationItemIdentifier.Friends, GoogleAnalyticsEventsType.tabReferral],
  [NavigationItemIdentifier.Wallet, GoogleAnalyticsEventsType.tabWallet],
]);

export function bindGoogleAnalyticsNavigationActions(gaService: GoogleAnalyticsService, navigationItems: NavigationItem[]) {
  return navigationItems.map((nav) => {
    const gaEventsType = navigationItemGoogleAnalyticsEventsType.get(<NavigationItemIdentifier>nav.text);
    if (gaEventsType) {
      nav.action = () => gaService.event(gaEventsType);
    }
    return nav;
  });
}

import { Injectable } from '@angular/core';
import { Notification, ResponseDto } from '@types';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import { environment } from '../../../../environments/environment';
import camelcaseKeys from 'camelcase-keys';
import { HttpClient } from '@angular/common/http';
import { DialogHelperService, LoaderService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  private notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);
  public notifications$: Observable<Notification[]> = this.notifications.asObservable();

  private hasUnreadNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasUnreadNotifications$: Observable<boolean> = this.hasUnreadNotifications.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private loaderService: LoaderService
  ) {}

  public getNotifications(): Observable<Notification[]> {
    this.loaderService.activate();
    return this.http.get<ResponseDto<Notification[]>>(`${environment.apiUrl}/notifications`).pipe(
      map(({ result, paging }) => {
        return camelcaseKeys(result, { deep: true });
      }),
      tap((result) => {
        this.loaderService.deactivate();
        this.notifications.next(result);
      }),
      catchError((e) => {
        this.loaderService.deactivate();
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getNotificationsUnreadCount(): Observable<{ unread: number }> {
    return this.http.get<ResponseDto<{ unread: number }>>(`${environment.apiUrl}/notifications/unread/count`).pipe(
      map(({ result, paging }) => camelcaseKeys(result, { deep: true })),
      tap(({ unread }) => {
        this.hasUnreadNotifications.next(Boolean(unread));
      }),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public markNotificationsAsUnread(ids: string[]): Observable<null> {
    return this.http
      .post<null>(`${environment.apiUrl}/notifications/unread/mark`, {
        ids,
      })
      .pipe(
        catchError((e) => {
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }
}

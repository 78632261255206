import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lpt-call-timer',
  templateUrl: './call-timer.component.html',
  styleUrls: ['./call-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallTimerComponent {
  public remainingSeconds!: number;
  public matchTime = 0;
  public isStopped = false;

  @Input() set total(seconds: number) {
    this.matchTime = seconds;
    this.isStopped = true;
  }

  @Input() set tick(remaining: number | null) {
    if (this.isStopped) {
      return;
    }
    if (remaining === null || remaining <= 0) {
      return;
    }
    if (this.remainingSeconds !== remaining) {
      this.remainingSeconds = remaining;
    }
  }

  @Output() readonly closeOutput = new EventEmitter<void>();
}

import { CurrencyType, EJokerKlass, JokerModel } from '@types';

export interface MarketOrder {
  readonly id: string;
  readonly isMyOrder: boolean;
  readonly joker: JokerModel;
  readonly price: string;
}

export interface MarketFilterParams {
  minLevel?: number;
  maxLevel?: number;
  classes?: EJokerKlass[];
  sortField?: string; // always price for now
  sortDirection?: 'asc' | 'desc';
  after?: string;
  before?: string;
  take?: number;
}

export interface SellingInfo {
  readonly fee: string;
  readonly sellingPrice: string;
  readonly asset: string;
}

export interface CurrencyItem {
  readonly currency: CurrencyType;
  readonly amount: string;
}

export interface InsufficientFundsError {
  readonly required: CurrencyItem;
  readonly owned: CurrencyItem;
}

export interface PriceFeeItem {
  readonly price: CurrencyItem;
  readonly fee: CurrencyItem;
}

export const MarketplaceNamespace = 'marketplace';

export enum MarketplaceWSEvent {
  MARKETPLACE_ORDER_CREATED = `marketplace.order.created`,
  MARKETPLACE_ORDER_REVOKED = `marketplace.order.revoked`,
  MARKETPLACE_ORDER_ACCEPTED = `marketplace.apply.accepted`,
  MARKETPLACE_ORDER_REJECTED = `marketplace.apply.rejected`,
  MARKETPLACE_ORDER_PROCESSED = `marketplace.apply.processed`,
}

export interface WsMarketplaceResponse {
  type: MarketplaceWSEvent;
  data: WsMarketOrder;
}

export interface WsMarketOrder {
  buyerId: string | null;
  createdAt: string;
  id: string;
  joker: { tokenId: string };
  jokerId: string;
  payload: null;
  price: string;
  state: string;
  updatedAt: string;
  userId: string;
}

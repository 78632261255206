import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  AfterViewInit,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { lottieAnimationOptionsFactory } from '@helpers';
import { EffectModel, IOpponentOnChanges } from '@types';
import { AnimationOptions } from 'ngx-lottie';
import { VideoTrack, AudioTrack } from 'twilio-video';

@Component({
  selector: 'lpt-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantComponent implements OnChanges, AfterViewInit {
  @Input() isLocalParticipant!: boolean;
  @Input() audio?: AudioTrack | null;
  @Input() video?: VideoTrack | null;
  @Input() effect?: EffectModel | null;

  @Output() readonly localVideoElementAttached = new EventEmitter<HTMLVideoElement | null>();
  @Output() readonly remoteVideoElementAttached = new EventEmitter<void>();

  @ViewChild('mediaContainer') mediaContainer!: ElementRef;

  public lottieAnimationOptions?: AnimationOptions;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.mediaContainer && this.audio && this.video) {
      this.setTracks(this.mediaContainer, this.audio, this.video);
    }
  }

  ngOnChanges(changes: IOpponentOnChanges): void {
    if (this.mediaContainer) {
      if (changes.audio?.currentValue) {
        this.setAudioTrack(this.mediaContainer, changes.audio.currentValue);
      }
      if (changes.video?.currentValue) {
        this.setVideoTrack(this.mediaContainer, changes.video.currentValue);
      }
      if (changes.effect?.currentValue) {
        this.setAnimationOptions(changes.effect.currentValue.url);
      }
    }
  }

  public animationCompleted(): void {
    this.lottieAnimationOptions = undefined;
  }

  private setAnimationOptions(path: string): void {
    this.lottieAnimationOptions = lottieAnimationOptionsFactory(path);
  }

  private setTracks(parentElement: ElementRef, audio: AudioTrack, video: VideoTrack): void {
    this.setVideoTrack(parentElement, video);
    this.setAudioTrack(parentElement, audio);
  }

  private clearByTag(parentElement: ElementRef, tag: 'audio' | 'video'): void {
    const removable: (HTMLAudioElement | HTMLVideoElement)[] = parentElement.nativeElement.querySelectorAll(tag);

    if (removable.length) {
      removable.forEach((element: HTMLAudioElement | HTMLVideoElement) => {
        element.oncanplaythrough = null;
        this.renderer.removeChild(parentElement.nativeElement, element);
      });
    }
  }

  private setVideoTrack(parentElement: ElementRef, track: VideoTrack): void {
    if (track) {
      this.clearByTag(parentElement, 'video');
      const htmlVideoElement = track.attach();
      htmlVideoElement.style.transform = 'scale(-1, 1)';
      this.renderer.appendChild(parentElement.nativeElement, htmlVideoElement);
      if (this.isLocalParticipant) {
        this.localVideoElementAttached.emit(htmlVideoElement as HTMLVideoElement);
      }
      if (!this.isLocalParticipant) {
        htmlVideoElement.oncanplaythrough = () => {
          this.remoteVideoElementAttached.emit();
        };
      }
    }
  }

  private setAudioTrack(parentElement: ElementRef, track: AudioTrack): void {
    if (track) {
      this.clearByTag(parentElement, 'audio');
      this.renderer.appendChild(parentElement.nativeElement, track.attach());
    }
  }
}

import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, scan, Subscription, takeWhile, timer } from 'rxjs';
import { JokerModel, StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { getJokerAttributeByType } from '@helpers';

@Component({
  selector: 'lpt-level-up-update-time',
  templateUrl: './level-up-update-time.component.html',
  styleUrls: ['./level-up-update-time.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LevelUpUpdateTimeComponent implements OnDestroy {
  public StringsKeys = StringsKeys;
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);

  public allLevelUpTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public remainingTime: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public timer$: Observable<number>;
  private timerSub: Subscription;

  constructor(private dialogRef: DialogRef) {
    this.joker.next(this.dialogRef.data.joker);

    this.allLevelUpTime.next(this.dialogRef.data.allLevelUpTime);

    this.remainingTime.next(this.dialogRef.data.remainingTime);

    this.timer$ = timer(this.remainingTime.value, 1000).pipe(
      scan((acc) => --acc, this.remainingTime.value),
      takeWhile((x) => x >= 1)
    );

    this.timerSub = this.timer$.subscribe({
      complete: () => {
        this.dialogRef.close(false);
      },
    });
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public boost(): void {
    this.dialogRef.close(true);
  }

  public getJokerAttributeByType = getJokerAttributeByType;

  ngOnDestroy() {
    this.timerSub.unsubscribe();
  }
}

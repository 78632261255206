<ng-container *ngIf="marketOrder">
  <div class="market-order__container">
    <lpt-joker-image
      [image]="marketOrder.joker.metadata.image"
      [hasBottomRadius]="false"
      [useLazyLoadingSpinner]="true"
    ></lpt-joker-image>
    <div class="market-order__joker-stats">
      <div [style.color]="getJokerClassColor(marketOrder.joker.klass)">{{marketOrder.joker.klass | titlecase}}</div>
      <div>{{getJokerAttributeByType(marketOrder.joker, 'level')?.value}}</div>
    </div>

    <div class="market-order__price-container flex-between">
      <div class="market-order__price-section">
        <div class="market-order__price-section__amount">{{marketOrder.price | number: '1.0-5'}}</div>
        <svg-icon class="market-order__price-section__icon" [src]="'./assets/images/bnb.svg'"></svg-icon>
      </div>

      <button
        class="btn btn-orange fz-14 cursor-pointer market-order__btn"
        *ngIf="marketOrder.isMyOrder; else butButtonTemp"
        id="revoke-joker-button"
        (click)="revokeJokerClick($event,marketOrder)"
      >
        {{StringsKeys.btnRevoke | toString}}
      </button>

      <ng-template #butButtonTemp>
        <button
          class="btn btn-orange fz-14 cursor-pointer market-order__btn"
          id="buy-joker-button"
          (click)="buyJokerClick($event,marketOrder)"
        >
          {{StringsKeys.btnBuy | toString}}
        </button>
      </ng-template>
    </div>
  </div>
</ng-container>

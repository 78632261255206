import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-unsupported-device-dialog',
  templateUrl: './unsupported-device-dialog.component.html',
  styleUrls: ['./unsupported-device-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsupportedDeviceDialogComponent {
  public readonly StringsKeys = StringsKeys;

  constructor(public dialogRef: DialogRef) {}
}

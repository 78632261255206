<div class="resource">
  <svg-icon [src]="iconSrc" class="icon"></svg-icon>

  <div class="resource__progress">
    <div class="resource__progress-header">
      <div class="resource__progress-header-title">{{title}}</div>
      <div *ngIf="!hideSubtitle" class="resource__progress-header-subtitle">{{subtitle}}</div>
    </div>
    <lpt-progress-bar
      [minValue]="minValue"
      [maxValue]="maxValue"
      [currentValue]="currentValue"
      [color]="color"
      [stepped]="stepped"
    ></lpt-progress-bar>
  </div>
</div>

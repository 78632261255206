import { ChangeDetectionStrategy, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TELEGRAM_LOGIN_REDIRECT_PATH, TELEGRAM_LOGIN_WIDGET_URL } from '@constants';

@Component({
  selector: 'lpt-telegram-login-widget',
  templateUrl: './telegram-login-widget.component.html',
  styleUrls: ['./telegram-login-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelegramLoginWidgetComponent implements OnInit {
  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.addTelegramWidgetScript();
  }

  private addTelegramWidgetScript(): void {
    const script = this.renderer.createElement('script');
    script.src = TELEGRAM_LOGIN_WIDGET_URL;
    script.async = true;
    script.setAttribute('data-telegram-login', environment.telegramLoginBotName);
    script.setAttribute('data-size', 'medium');
    script.setAttribute('data-auth-url', `${environment.apiUrl}/${TELEGRAM_LOGIN_REDIRECT_PATH}`);
    script.setAttribute('data-request-access', 'write');
    this.renderer.appendChild(this.el.nativeElement, script);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChoosePlayModeComponent } from './choose-play-mode.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DialogModule } from '../dialog/dialog.module';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [ChoosePlayModeComponent],
  imports: [CommonModule, AngularSvgIconModule, DialogModule, ToStringPipeModule],
  exports: [ChoosePlayModeComponent],
})
export class ChoosePlayModeModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject, defer, filter, from, iif, map, Observable, of, OperatorFunction, pipe, switchMap, take } from 'rxjs';
import { JokerService } from '../joker/joker.service';
import { NavigationService } from '../navigation.service';
import { MediaPermissionsService } from '../media-permissions.service';
import { ECheckMediaPermissionsStatus, JokerModel, PlayMode, StringsKeys } from '@types';
import { LoaderService } from '../loader.service';
import { DialogHelperService } from '../dialog-helper.service';
import { FaceApiProcessorService } from './face-api-processor.service';
import { SpinnerService } from '../spinner.service';
import { ToStringService } from '../to-string.service';
import { DetectionPropertyAverager, hasEnoughCrazinessToPlay, isJokerOrdinary } from '@helpers';
import { CustomToastrService } from '../custom-toastr-service.service';
import { HandLandmarkerService } from './hand-landmarker.service';
import { PREFLIGHT_FACE_DETECTION_SOURCE_URI } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class GamePreflightService {
  private _isPreflightReady$ = new BehaviorSubject<boolean>(false);
  private _roomId?: string;
  private _readyToMountRoomsmakingLayout = false;
  private readonly _isPreflightFaceDetectionExecuted = new BehaviorSubject<boolean | null>(false);
  readonly isPreflightFaceDetectionExecuted$ = this._isPreflightFaceDetectionExecuted.asObservable();

  constructor(
    private jokerService: JokerService,
    private navigationService: NavigationService,
    private mediaPermissionsService: MediaPermissionsService,
    private loader: LoaderService,
    private spinnerService: SpinnerService,
    private faceApiProcessorService: FaceApiProcessorService,
    private handLandmarkerService: HandLandmarkerService,
    private dialogHelperService: DialogHelperService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService
  ) {}

  public set roomId(id: string | undefined) {
    this._roomId = id;
  }

  public get roomId(): string | undefined {
    return this._roomId;
  }

  public isPreflightReady(): Observable<boolean> {
    return this._isPreflightReady$.asObservable();
  }

  public markPreflightAsReady(): void {
    this._isPreflightReady$.next(true);
  }

  public markPreflightAsUnready(): void {
    this._isPreflightReady$.next(false);
  }

  public isReadyToMountRoomsmakingLayout(): boolean {
    return this._readyToMountRoomsmakingLayout;
  }

  public markAsReadyToMountRoomsmakingLayout(): void {
    this._readyToMountRoomsmakingLayout = true;
  }

  public markAsUnreadyToMountRoomsmakingLayout(): void {
    this._readyToMountRoomsmakingLayout = false;
  }

  public reset(): void {
    this.markPreflightAsUnready();
    this._roomId = undefined;
  }

  public preflight(roomId?: string): void {
    this.loader.activate();
    this.spinnerService.showGlobalSpinner();

    void this.handLandmarkerService.loadModels();

    this.faceApiProcessorService.isModelsLoaded$
      .pipe(
        switchMap((isModelsLoaded) =>
          iif(
            () => isModelsLoaded,
            defer(() => of(true)),
            defer(() => from(this.faceApiProcessorService.loadModels()))
          )
        ),
        switchMap(() => from(this.mediaPermissionsService.checkMediaPermissions()))
      )
      .subscribe({
        next: (status) => {
          switch (status) {
            case ECheckMediaPermissionsStatus.Granted:
              if (roomId) {
                this._roomId = roomId;
              }
              if (!this.handLandmarkerService.isHandLandmarkerLoadingFailed()) {
                this.markPreflightAsReady();
                this.navigationService.toGame();
              }
              break;
            default:
              this.dialogHelperService.openErrorDialogWithText(
                this.toStringService.toString(StringsKeys.permissionsCameraAndMicrophoneRequired)
              );
          }
          this.loader.deactivate();
          this.spinnerService.hideGlobalSpinner();
        },
        complete: () => {
          this.spinnerService.hideGlobalSpinner();
          this.loader.deactivate();
        },
      });
  }

  public preflightFaceDetection(): void {
    if (this._isPreflightFaceDetectionExecuted.value) {
      return;
    }
    queueMicrotask(() => {
      this.faceApiProcessorService
        .preflightFaceDetection(PREFLIGHT_FACE_DETECTION_SOURCE_URI, new DetectionPropertyAverager())
        .then(() => {
          this._isPreflightFaceDetectionExecuted.next(true);
        })
        .catch(() => {
          this._isPreflightFaceDetectionExecuted.next(null);
          this.dialogHelperService.openDefaultErrorDialog();
        });
    });
  }

  public hasAvailableGamesToPlay(): Observable<boolean> {
    return this.jokerService.currentJoker$.pipe(
      take(1),
      map((joker) => Boolean(joker?.gamesToPlay))
    );
  }

  public checkAbilityToPlayOrdinaryJoker(joker: JokerModel): OperatorFunction<PlayMode, PlayMode> {
    return pipe(
      filter((mode) => {
        if (!isJokerOrdinary(joker)) return true;
        if (mode !== PlayMode.Play) return true;
        if (!joker.gamesToPlay) {
          this.jokerService.handleRestoreOrdinaryJokerGames();
          return false;
        }
        return true;
      })
    );
  }

  public checkIsEnoughCrazinessToPlay(joker: JokerModel): OperatorFunction<PlayMode, PlayMode> {
    if (isJokerOrdinary(joker)) return pipe(); // In case ordinary skip craziness check
    return pipe(
      filter((mode) => {
        if (mode !== PlayMode.Play) return true;
        const hasEnoughCraziness = hasEnoughCrazinessToPlay(joker);

        if (hasEnoughCraziness) {
          return true;
        }
        this.customToastrService.show('', this.toStringService.toString(StringsKeys.playNotEnoughCrazinessToast));
        return false;
      })
    );
  }
}

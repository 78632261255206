export enum EGameStage {
  Pairing,
  Call,
  Result,
}

export interface IGameEvent {
  smileProbability: number;
  faceVisible: boolean;
  headBounce: number;
  brightness: number;
  handInFrame: boolean;
}

export enum EGameResult {
  won = 'won',
  lost = 'lost',
  draw = 'draw',
  unknown = 'unknown',
}

export enum EGameFinishedReason {
  no_light = 'no light timeout',
  no_face = 'no face timeout',
  sunglasses = 'sunglasses timeout',
  artifacts = 'artifacts timeout',
  closed_mouth = 'closed mouth timeout',
  disconnect = 'disconnect',
  smile = 'smiled user',
  naked = 'naked user',
  head_bounce = 'head bounce timeout',
  exit = 'player exit from game',
  kill_app = 'player kill app',
  hand_in_frame = 'hand in frame timeout',
}

export enum EGameBlurredPlayerType {
  FullLayout = 'fulllayout',
  OnlyOpponent = 'onlyopponent',
}

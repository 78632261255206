import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterTextDialogComponent } from './enter-text-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToStringPipeModule } from '@pipes';
import { InputModule } from '../input/input.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [EnterTextDialogComponent],
  imports: [CommonModule, AngularSvgIconModule, ToStringPipeModule, InputModule, ReactiveFormsModule],
  exports: [EnterTextDialogComponent],
})
export class EnterTextDialogModule {}

<nav [ngClass]="{ 'ios-webview': isIos && isTelegramWebView }">
  <ul>
    <li *ngFor="let navItem of navItems">
      <button
        *ngIf="navItem.isButton; else urlNavigationItem"
        class="nav-item flex-center"
        [class]="textColor"
        [style]="{ 'flex-direction': verticalAppearance ? 'column' : 'row' }"
        id="navigation-panel-button-link-to-{{navItem.text}}"
        (click)="navItem.action && navItem.action()"
      >
        <svg-icon [src]="navItem.icon" [style]="{ width: navItem.iconWidth, height: navItem.iconHeight }"></svg-icon>
        <span *ngIf="navItem.text">{{ navItem.text }}</span>
      </button>

      <ng-template #urlNavigationItem>
        <a
          class="nav-item flex-center"
          [class]="textColor"
          [class.active]="router.isActive(navItem.route, true)"
          [routerLink]="[navItem.route]"
          [style]="{ 'flex-direction': verticalAppearance ? 'column' : 'row' }"
          id="navigation-panel-router-link-to-{{navItem.text}}"
          (click)="navItem.action && navItem.action()"
        >
          <svg-icon
            [src]="router.isActive(navItem.route, true) ? navItem.activeIcon : navItem.icon"
            [style]="{ width: navItem.iconWidth, height: navItem.iconHeight }"
          ></svg-icon>
          <span *ngIf="navItem.text">{{ navItem.text }}</span>
        </a>
      </ng-template>
    </li>
  </ul>
</nav>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PriceFeeItem, StringsKeys } from '@types';
import { NavigationService } from '@services';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-buy-free-games-dialog',
  templateUrl: './buy-free-games-dialog.component.html',
  styleUrls: ['./buy-free-games-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyFreeGamesDialogComponent {
  public readonly StringsKeys = StringsKeys;
  public priceInfo: PriceFeeItem;

  constructor(
    private dialogRef: DialogRef,
    private navigationService: NavigationService
  ) {
    this.priceInfo = this.dialogRef.data.priceInfo;
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public toMarketPlace(): void {
    this.dialogRef.close(false);
    this.navigationService.toMarketPlace();
  }

  public buyGames(): void {
    this.dialogRef.close(true);
  }
}

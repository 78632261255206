import { Injectable } from '@angular/core';
import { DialogHelperService } from '../dialog-helper.service';
import { BehaviorSubject } from 'rxjs';
import { IGameEvent, SingleModeDetectionReturnType } from '@types';
import { FaceApiModelsLoader, FaceDetectionHandler, DetectionPropertyAverager } from '@helpers';
import { TinyFaceDetectorOptions, detectSingleFace, FaceExpressions } from 'face-api.js';
import { AppSettingsService } from '../app-settings.service';

@Injectable({
  providedIn: 'root',
})
export class FaceApiProcessorService {
  /**
   * Detection models have to be loaded before game or single mode session was started.
   */
  private readonly _isModelsLoaded = new BehaviorSubject<boolean>(false);
  readonly isModelsLoaded$ = this._isModelsLoaded.asObservable();

  private _options?: TinyFaceDetectorOptions;

  constructor(
    private dialogHelperService: DialogHelperService,
    private appSettingsService: AppSettingsService
  ) {}

  public loadModels(): Promise<void | [void, void]> {
    return Promise.all([FaceApiModelsLoader.loadTinyFaceDetectorModel(), FaceApiModelsLoader.loadFaceExpressionModel()])
      .then(() => this._isModelsLoaded.next(true))
      .catch(this.exceptionHandler.bind(this));
  }

  private getFaceDetectorOptions(): TinyFaceDetectorOptions {
    return this._options ?? (this._options = this.appSettingsService.getLoadedFaceDetectorOptions());
  }

  public detectFace(
    video: HTMLVideoElement | HTMLImageElement,
    averager: DetectionPropertyAverager
  ): Promise<Partial<IGameEvent> | void> {
    return detectSingleFace(video, this.getFaceDetectorOptions())
      .withFaceExpressions()
      .then((detection) => detection)
      .then((detection) => FaceDetectionHandler.process(detection, video, averager));
  }

  public detectFaceExpressionsForSingleMode(video: HTMLVideoElement | HTMLImageElement): Promise<SingleModeDetectionReturnType> {
    return detectSingleFace(video, this.getFaceDetectorOptions())
      .withFaceExpressions()
      .then((detection) => detection)
      .then((detection) => ({
        expressions: detection?.expressions,
        headBounce: FaceDetectionHandler.calcHeadBounce(detection),
      }));
  }

  private exceptionHandler(e: Error): void {
    this.dialogHelperService.openDefaultErrorDialog();
    throw e;
  }

  /**
   * @note Preflight face detection is an initial iteration (detection) to ensure a smooth start of game or single mode
   * session without freezing.
   * Webgl and tfjs takes some time to "warmup" the model (compile gles shaders and upload weighs as textures to gpu).
   * After initial detection, anything afterwards is faster.
   */
  public preflightFaceDetection(imgurl: string, averager: DetectionPropertyAverager): Promise<Partial<IGameEvent> | void> {
    const image = new Image();
    image.src = imgurl;
    image.crossOrigin = 'anonymous';
    return image.decode().then(() => {
      return this.detectFace(image, averager);
    });
  }
}

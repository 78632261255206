import { Component } from '@angular/core';
import { NavigationItem } from '@types';
import { footerNavigationItems } from '@constants';
import { Router } from '@angular/router';
import { bindGoogleAnalyticsNavigationActions } from '@helpers';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'lpt-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss'],
})
export class MobileFooterComponent {
  public navItems: NavigationItem[] = bindGoogleAnalyticsNavigationActions(this.gaService, footerNavigationItems);
  constructor(
    public readonly router: Router,
    private readonly gaService: GoogleAnalyticsService
  ) {}
}

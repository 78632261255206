export interface NavigationItem {
  icon: string;
  activeIcon: string;
  route: string;
  iconHeight: number;
  iconWidth: number;
  text?: NavigationItemIdentifier | string;
  isButton?: boolean;
  action?: () => void;
}

export enum NavigationItemIdentifier {
  Dashboard = 'Dashboard',
  Earn = 'Earn',
  Friends = 'Friends',
  Marketplace = 'Marketplace',
  Wallet = 'Wallet',
}

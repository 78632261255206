export enum GoogleAnalyticsEventsType {
  play = 'play',
  tabDashboard = 'tab_dashboard',
  tabEarn = 'tab_earn',
  tabReferral = 'tab_referral',
  tabWallet = 'tab_wallet',
  earnLinkView = 'earn_link_view',
  earnLinkGrab = 'earn_link_grab',
  playClosed = 'play_closed',
  earnedScreen = 'earned_screen',
  viewMultiplierHelp = 'view_multiplier_help',
  referralLinkCopied = 'referral_link_copied',
  referralLinkSharing = 'referral_link_sharing',
  profileOpened = 'profile_opened',
  notificationsOpened = 'notifications_opened',
  onboardingScreen1Click = 'onboarding_screen_1_click',
  onboardingScreen2Click = 'onboarding_screen_2_click',
  onboardingScreen3Click = 'onboarding_screen_3_click',
  referralsActiveView = 'referrals_active_view',
  referralsPendingView = 'referrals_pending_view',
  dailyGameRewardOk = 'daily_game_reward_ok',
  gameOpenedRewardOk = 'game_opened_reward_okgame_reward_ok',
}

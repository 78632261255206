import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPanelComponent } from './filter-panel.component';
import { ItemsSwitcherModule } from '../items-switcher/items-switcher.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [FilterPanelComponent],
  imports: [CommonModule, ItemsSwitcherModule, AngularSvgIconModule],
  exports: [FilterPanelComponent],
})
export class FilterPanelModule {}

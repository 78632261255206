import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-single-mode-multiplication-dialog',
  templateUrl: './single-mode-multiplication-dialog.component.html',
  styleUrls: ['./single-mode-multiplication-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleModeMultiplicationDialogComponent {
  public readonly StringsKeys = StringsKeys;
  public readonly dialogRef: DialogRef = inject(DialogRef);
}

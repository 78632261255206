import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddJokerPointsDialogComponent } from './add-joker-points-dialog.component';
import { ToStringPipeModule } from '@pipes';
import { SelectPointsAmountModule } from '../select-points-amount/select-points-amount.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AddJokerPointsDialogComponent],
  imports: [CommonModule, ToStringPipeModule, SelectPointsAmountModule, ReactiveFormsModule],
  exports: [AddJokerPointsDialogComponent],
})
export class AddJokerPointsDialogModule {}

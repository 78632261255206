import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EHttpHeaderKey, ILoginDto, ISendOtpCodeDto, ResponseDto, SessionData } from '@types';
import { map, Observable, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NavigationService } from './navigation.service';
import { LocalStorageService } from './local-storage.service';
import { closeTelegramWebView, isTelegramWebView } from '@helpers';
import { OnboardingService } from "../../../modules/onboarding/onboarding.service";
import { SingleModeService } from "./single-mode.service";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private http: HttpClient,
    private navigationService: NavigationService,
    private onboardingService: OnboardingService,
    private singleModeService: SingleModeService,
  ) {}

  public sendOtpCode(email: string, intent: string | undefined = undefined): Observable<ISendOtpCodeDto> {
    const headers = new HttpHeaders({ [EHttpHeaderKey.IgnoreByInterceptor]: String(true) });
    return this.http
      .post<ResponseDto<ISendOtpCodeDto>>(
        `${environment.apiUrl}/otp`,
        {
          email,
          intent,
        },
        { headers }
      )
      .pipe(
        take(1),
        map(({ result }) => result)
      );
  }

  public login(email: string, otp: string): Observable<ILoginDto> {
    const headers = new HttpHeaders({ [EHttpHeaderKey.IgnoreByInterceptor]: String(true) });
    return this.http.post<ResponseDto<ILoginDto>>(`${environment.apiUrl}/session`, { email, otp }, { headers }).pipe(
      take(1),
      map((res) => res.result)
    );
  }

  public activateActivationCode(code: string, accessToken: string): Observable<ResponseDto<boolean>> {
    const headers = new HttpHeaders({
      [EHttpHeaderKey.Authorization]: `Bearer ${accessToken}`,
      [EHttpHeaderKey.IgnoreByInterceptor]: String(true),
    });
    return this.http.post<ResponseDto<boolean>>(`${environment.apiUrl}/user/activate`, { code }, { headers }).pipe(take(1));
  }

  public logout(): void {
    this.onboardingService.resetState();
    this.singleModeService.resetState();
    LocalStorageService.deleteAccessToken();
    LocalStorageService.deleteAuthData();
    void this.navigationService.toLogin();

    if (isTelegramWebView()) {
      closeTelegramWebView();
    }
  }

  public getSessionData(): Observable<SessionData> {
    return this.http.get<ResponseDto<{ user: SessionData }>>(`${environment.apiUrl}/session`).pipe(
      take(1),
      map((res) => res.result.user)
    );
  }
}

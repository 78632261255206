import { ChangeDetectionStrategy, Component } from '@angular/core';
import { INFTAttribute, StringsKeys } from '@types';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-add-joker-points',
  templateUrl: './add-joker-points-dialog.component.html',
  styleUrls: ['./add-joker-points-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddJokerPointsDialogComponent {
  public readonly StringsKeys = StringsKeys;

  public formGroup: FormGroup<Record<string, FormControl<number>>>;

  public initialAttributes: Record<string, INFTAttribute>;
  public availablePoints: number;

  constructor(private dialogRef: DialogRef) {
    this.initialAttributes = this.dialogRef.data.initialAttributes;
    this.availablePoints = this.dialogRef.data.availablePoints;
    this.initForm();
  }

  get controls(): [string, FormControl<number>][] {
    return Object.entries(this.formGroup.controls);
  }

  get formPointsSum(): number {
    return this.controls.reduce((acc, [name, control]) => (acc += control.value), 0);
  }

  get hasAvailablePoints(): boolean {
    if (!this.availablePoints) return false;
    return Boolean(this.availablePoints - (this.formPointsSum - this.initialPointsSum));
  }

  get initialPointsSum(): number {
    return Object.entries(this.initialAttributes).reduce((acc, [name, attribute]) => (acc += Number(attribute.value)), 0);
  }
  public initForm(): void {
    const groupControls = Object.entries(this.initialAttributes).reduce(
      (acc, [key, attribute]) => {
        acc[key] = new FormControl<number>(Number(attribute.value), { nonNullable: true });
        return acc;
      },
      {} as Record<string, FormControl<number>>
    );

    this.formGroup = new FormGroup(groupControls);
  }
  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirmAddPoints(): void {
    const addedPointsObject = this.getAddedPointsObject;
    this.dialogRef.close(addedPointsObject);
  }

  public get getAddedPointsObject(): Record<string, number> {
    const formData = this.formGroup.value;

    const addedPointsObject: Record<string, number> = {};

    for (const formDataKey in formData) {
      addedPointsObject[formDataKey] = Number(formData[formDataKey]) - Number(this.initialAttributes[formDataKey].value);
    }

    return addedPointsObject;
  }
}

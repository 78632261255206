<div class="text-wr">
  <div class="header flex-center">
    <div class="header__icon flex-center cursor-pointer" id="enter-text-dialog-close-icon-button" (click)="closeDialog()">
      <svg-icon class="header__icon-svg" [src]="'./assets/images/arrow-left.svg'"></svg-icon>
    </div>
    <div class="header__title">{{title| toString}}</div>
  </div>

  <div class="text__main-section">
    <div class="text__main-section__wr pb-20">
      <div class="text__main-section__input-label fz-14 pb-10" *ngIf="inputLabel">{{inputLabel | toString}}</div>
      <lpt-input
        [class]="'d-block h-' + customHeight"
        [formControl]="enterTextFormControl"
        [clearable]="clearable"
        [valid]="enterTextFormControl.dirty && enterTextFormControl.valid"
        [invalid]="enterTextFormControl.touched && enterTextFormControl.invalid"
        [placeholder]="(placeholder | toString) || ''"
        [isTextarea]="isTextarea"
      ></lpt-input>
      <div class="shared-error" *ngIf="controlErrors && enterTextFormControl.touched">{{controlErrors}}</div>
    </div>

    <button
      class="btn btn-orange"
      id="enter-text-dialog-confirm-button"
      [disabled]="!enterTextFormControl.valid"
      (click)="saveValue()"
    >
      {{confirmButtonText | toString}}
    </button>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'points',
})
export class PointsPipe implements PipeTransform {
  private readonly transformRegExp = /\B(?=(\d{3})+(?!\d))/g;

  transform(value: number | null | undefined, usePlusSign = false): string {
    if (typeof value !== 'number') {
      return '';
    }

    let stringValue = value.toString();
    stringValue = stringValue.replace(this.transformRegExp, ' ');

    if (usePlusSign) {
      stringValue = `+ ${stringValue}`;
    }
    return stringValue;
  }
}

import { BehaviorSubject, catchError, filter, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EHttpHeaderKey, InsufficientFundsError, PriceFeeItem, ResponseDto, StringsKeys } from '@types';
import { UserProfileInfo } from '../../types/src/profile/profile.types';
import camelcaseKeys from 'camelcase-keys';
import { DialogHelperService } from './dialog-helper.service';
import { SpinnerService } from './spinner.service';
import { LocalStorageService } from './local-storage.service';
import { ChangeEmailDialogComponent, DeleteAccountDialogComponent } from '@components';
import { instagramUrl } from '@helpers';
import { NavigationService } from './navigation.service';
import { CustomToastrService } from './custom-toastr-service.service';
import { ToStringService } from './to-string.service';

// Local interface won't be used elsewhere
interface UpdatePropertyOptions {
  endpoint: 'gender' | 'play-with' | 'nickname';
  dialogTitle: StringsKeys;
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private userProfileInfo: BehaviorSubject<UserProfileInfo | null> = new BehaviorSubject<UserProfileInfo | null>(null);
  public userProfileInfo$: Observable<UserProfileInfo | null> = this.userProfileInfo.asObservable();

  constructor(
    private http: HttpClient,
    private dialogHelperService: DialogHelperService,
    private spinnerService: SpinnerService,
    private navigationService: NavigationService,
    private customToastrService: CustomToastrService,
    private toStringService: ToStringService
  ) {}

  public updateGender(gender: string, accessToken?: string): Observable<unknown> {
    const headers = new HttpHeaders({
      ...(accessToken && {
        [EHttpHeaderKey.Authorization]: `Bearer ${accessToken}`,
        [EHttpHeaderKey.IgnoreByInterceptor]: String(true),
      }),
    });
    return this.http.put<ResponseDto<unknown>>(`${environment.apiUrl}/profiles/gender`, { gender }, { headers }).pipe(take(1));
  }

  public getProfileInfo(): Observable<UserProfileInfo> {
    return this.http.get<ResponseDto<UserProfileInfo>>(`${environment.apiUrl}/profiles`).pipe(
      take(1),
      map(({ result }) => camelcaseKeys(result, { deep: true })),
      tap((value) => this.userProfileInfo.next(value)),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getOpponentInstagram(id: string): Observable<string | null> {
    return this.http.get<ResponseDto<UserProfileInfo>>(`${environment.apiUrl}/profiles/${id}`).pipe(
      take(1),
      map(({ result }) => result.instagramUrl),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  public getProfileUpdateFee(intent: 'gender' | 'nickname' | 'playWith'): Observable<PriceFeeItem> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .get<ResponseDto<PriceFeeItem>>(`${environment.apiUrl}/profiles/fee`, {
        params: {
          intent,
        },
      })
      .pipe(
        take(1),
        tap(() => this.spinnerService.hideGlobalSpinner()),

        map(({ result }) => camelcaseKeys(result, { deep: true })),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();

          const insufficientFundsError: InsufficientFundsError | undefined = e.error.payload;

          if (insufficientFundsError) {
            this.dialogHelperService.openInsufficientFundsDialog(insufficientFundsError);
            throw e;
          }
          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public updateProfileEmail(data: { email: string; otp: string }): Observable<{ token: { accessToken: string } }> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .put<ResponseDto<{ token: { accessToken: string } }>>(`${environment.apiUrl}/profiles/email`, {
        email: data.email,
        otp: data.otp,
      })
      .pipe(
        take(1),
        tap(() => {
          this.customToastrService.show('', this.toStringService.toString(StringsKeys.editProfilePopupChangeEmailSuccess));
          this.spinnerService.hideGlobalSpinner();
        }),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        tap((res) => {
          LocalStorageService.setAccessToken(res.token.accessToken);
        }),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();
          if (e.status !== 406 && e.status !== 422) {
            this.dialogHelperService.openDefaultErrorDialog();
          }
          throw e;
        })
      );
  }

  public updateProfile(
    endpoint: 'gender' | 'nickname' | 'play-with' | 'instagram',
    field: 'gender' | 'nickname' | 'playWith' | 'instagramUrl',
    value: string | null
  ): Observable<null> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .put<null>(`${environment.apiUrl}/profiles/${endpoint}`, {
        [field]: value,
      })
      .pipe(
        take(1),
        tap(() => this.spinnerService.hideGlobalSpinner()),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();

          this.dialogHelperService.openDefaultErrorDialog();
          throw e;
        })
      );
  }

  public deleteAccount(otp: string): Observable<UserProfileInfo> {
    this.spinnerService.showGlobalSpinner();
    return this.http
      .delete<ResponseDto<UserProfileInfo>>(`${environment.apiUrl}/user`, {
        body: {
          otp,
        },
      })
      .pipe(
        take(1),
        tap(() => this.spinnerService.hideGlobalSpinner()),
        map(({ result }) => camelcaseKeys(result, { deep: true })),
        catchError((e) => {
          this.spinnerService.hideGlobalSpinner();

          if (e.status !== 406 && e.status !== 422) {
            this.dialogHelperService.openDefaultErrorDialog();
          }
          throw e;
        })
      );
  }

  public editProfileProperty(property: 'nickname' | 'gender' | 'playWith', currentValue: string | undefined): void {
    const updatePropertyOptions = this.getUpdatePropertyOptions(property);
    if (!updatePropertyOptions) return;

    this.getProfileUpdateFee(property)
      .pipe(
        switchMap((info) => this.dialogHelperService.getChangeProfileConfirmDialog(info, updatePropertyOptions.dialogTitle)),
        filter(Boolean),
        switchMap(() => {
          if (property === 'nickname') return this.dialogHelperService.getNickNameChangeDialog(currentValue);
          if (property === 'gender') return this.dialogHelperService.getGenderChangeDialog(currentValue);
          if (property === 'playWith') return this.dialogHelperService.getPlayWithChangeDialog(currentValue);
          return of(null);
        }),
        filter(Boolean),
        switchMap((value) => this.updateProfile(updatePropertyOptions.endpoint, property, value)),
        switchMap(() => this.getProfileInfo())
      )
      .subscribe();
  }

  private getUpdatePropertyOptions(property: 'nickname' | 'gender' | 'playWith'): UpdatePropertyOptions | undefined {
    if (property === 'nickname') return { endpoint: 'nickname', dialogTitle: StringsKeys.editProfilePopupChangeNickname };
    if (property === 'gender') return { endpoint: 'gender', dialogTitle: StringsKeys.editProfilePopupChangeGender };
    if (property === 'playWith') return { endpoint: 'play-with', dialogTitle: StringsKeys.editProfilePopupChangePlayWith };
    return undefined;
  }

  public editEmail(): void {
    this.dialogHelperService
      .openDialogComponent<ChangeEmailDialogComponent, { email: string; otp: string } | undefined>(ChangeEmailDialogComponent)
      .pipe(
        filter(Boolean),
        switchMap(() => this.getProfileInfo())
      )
      .subscribe();
  }

  public editInstNickname(instNickname: string): void {
    this.dialogHelperService
      .getInstNickNameChangeDialog(instNickname)
      .pipe(
        filter((res) => res !== undefined), // case if dialog was closed without changing value
        switchMap((nickname) => this.updateProfile('instagram', 'instagramUrl', nickname ? `${instagramUrl}${nickname}` : null)),
        switchMap(() => this.getProfileInfo())
      )
      .subscribe();
  }

  public processAccountDeletion(email: string): void {
    this.dialogHelperService
      .openDialogComponent<DeleteAccountDialogComponent, { email: string; otp: string } | undefined>(
        DeleteAccountDialogComponent,
        {
          email,
        }
      )
      .pipe(
        filter(Boolean),
        tap(() => {
          LocalStorageService.deleteAccessToken();
          LocalStorageService.deleteAuthData();
          this.navigationService.toLogin();
        })
      )
      .subscribe();
  }
}

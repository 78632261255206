import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class CustomToastrService {
  constructor(private toastrService: ToastrService) {}

  public show(
    title: string,
    message: string,
    timeOut = 3000,
    color: 'success' | 'error' | 'default' = 'default',
    toastClass?: string
  ): void {
    this.toastrService.show(message, title, {
      toastClass: `ngx-toastr toast-${color} ${toastClass || ''}`,
      timeOut,
      positionClass: 'toast-top-center',
    });
  }
}

import { AudioTrack, VideoTrack } from 'twilio-video';
import { EffectModel } from './joker/effect.types';

export interface IOpponentOnChanges {
  audio?: SimpleLocalMediaChange<AudioTrack> | undefined;
  video?: SimpleLocalMediaChange<VideoTrack> | undefined;
  effect?: SimpleLocalMediaChange<EffectModel> | undefined;
}

interface SimpleLocalMediaChange<T> {
  currentValue: T | undefined;
  firstChange: boolean;
  previousValue: T | undefined;
}

export enum EParticipantState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  RECONNECTING = 'reconnecting',
  DISCONNECTED = 'disconnected',
}

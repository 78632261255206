<ng-container *ngIf="joker | async as jokerValue">
  <div class="heal-joker__container dialog__container">
    <h3 class="heal-joker__title">{{StringsKeys.healPopup | toString}}</h3>

    <lpt-joker-image [image]="jokerValue.metadata.image" [circleAppearance]="true" size="sm"></lpt-joker-image>

    <h3 class="heal-joker__title pt-10">#{{jokerValue.tokenId}}</h3>

    <div *ngIf="extraCraziness | async as extraCraziness" class="w-100">
      <div class="heal-joker__craziness-title flex-between pb-10">
        <div>{{StringsKeys.jokerCrazinessTitle | toString}}</div>
        <div>{{healCrazinessAmount | async}} / {{extraCraziness.maxValue}}</div>
      </div>

      <lpt-range-selector
        [minRangeValue]="extraCraziness.minValue || 0"
        [maxRangeValue]="extraCraziness.maxValue || 100"
        [minValue]="+extraCraziness.value"
        [maxValue]="+extraCraziness.value"
        [minLimit]="+extraCraziness.value"
        [singleSelector]="true"
        [displayMinMax]="false"
        (rangeChanged)="healCrazinessAmount.next($event.min)"
      ></lpt-range-selector>
    </div>
    <div class="heal-joker__info w-100" *ngIf="healInfo | async as healInfoItem">
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="StringsKeys.popupPricePriceTitle | toString"
        [rightText]="(healPrice | number : '1.0-2' )+ ' ' + healInfoItem.price.currency"
        [rightIconSrc]="'./assets/images/earning-token.svg'"
      ></lpt-info-row-item>
      <lpt-info-row-item
        class="d-block w-100"
        [leftText]="StringsKeys.popupPriceTransactionFeeTitle | toString"
        [rightText]="(healInfoItem.fee.amount | number : '1.0-5') + ' ' + healInfoItem.fee.currency"
        [rightIconSrc]="'./assets/images/bnb.svg'"
      ></lpt-info-row-item>
    </div>

    <div class="heal-joker__action-buttons w-100 flex-between">
      <button class="btn btn-white" id="heal-joker-dialog-close-button" (click)="closeDialog()">
        {{StringsKeys.btnClose | toString}}
      </button>
      <button
        class="btn btn-orange"
        id="heal-joker-dialog-confirm-button"
        (click)="confirmHealing()"
        [disabled]="!(pointsToHeal | async)"
      >
        {{StringsKeys.btnConfirm | toString}}
      </button>
    </div>
  </div>
</ng-container>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoJokerComponent } from './no-joker.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { RouterModule } from '@angular/router';
import { JokerImageModule } from '../joker-image/joker-image.module';

@NgModule({
  declarations: [NoJokerComponent],
  imports: [CommonModule, AngularSvgIconModule, RouterModule, JokerImageModule],
  exports: [NoJokerComponent],
})
export class NoJokerModule {}

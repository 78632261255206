import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PointsPipeModule } from '@pipes';

@Component({
  selector: 'lpt-points',
  template: `
    <div class="reward__wrapper flex-center">
      <span class="reward">{{ value | points: usePlusSign }}</span>
      <svg-icon class="flex-center" [src]="'./assets/images/earning-token.svg'" [svgStyle]="{ width: 32, height: 32 }"></svg-icon>
    </div>
  `,
  styles: [
    `
      :host {
        width: fit-content;
      }
      .reward__wrapper {
        width: fit-content;
        gap: 6px;
        border-radius: 22px;
        padding: 8px 10px 8px 12px;
        background: var(--backgrounds-primary);
      }
      .reward {
        color: var(--text-primary);
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AngularSvgIconModule, PointsPipeModule],
})
export class PointsComponent {
  @Input() value!: number;
  @Input() usePlusSign = true;
}

<div class="timer flex-between" [class.over]="isStopped">
  <svg-icon
    *ngIf="!isStopped"
    class="timer__close cursor-pointer"
    src="assets/images/close.svg"
    id="call-timer-close-icon-button"
    (click)="closeOutput.emit()"
  ></svg-icon>
  <svg-icon *ngIf="isStopped" class="timer__circle" src="assets/images/time-circle.svg"></svg-icon>

  <span *ngIf="isStopped" class="timer__matchtime">Match time</span>

  <span class="timer__seconds">{{ (isStopped ? matchTime : remainingSeconds) | remainingTime }}</span>

  <svg-icon *ngIf="!isStopped" class="timer__recording" src="assets/images/recording.svg"></svg-icon>
</div>

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { JokerModel, PriceFeeItem, StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { getTokenIconByCurrencyCode } from '@helpers';

@Component({
  selector: 'lpt-joker-level-up',
  templateUrl: './joker-level-up.component.html',
  styleUrls: ['./joker-level-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerLevelUpComponent {
  public StringsKeys = StringsKeys;
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);

  public levelUpInfo: BehaviorSubject<PriceFeeItem | null> = new BehaviorSubject<PriceFeeItem | null>(null);
  public timerCooldown: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  constructor(private dialogRef: DialogRef) {
    this.joker.next(this.dialogRef.data.joker);
    this.levelUpInfo.next(this.dialogRef.data.levelUpInfo);
    this.timerCooldown.next(this.dialogRef.data.timerCooldown);
  }

  public closeDialog(): void {
    this.dialogRef.close(false);
  }

  public confirmLevelUp(): void {
    this.dialogRef.close(true);
  }

  public getTokenIconByCurrencyCode = getTokenIconByCurrencyCode;
}

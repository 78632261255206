import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemsSwitcherComponent } from './items-switcher.component';

@NgModule({
  declarations: [ItemsSwitcherComponent],
  imports: [CommonModule],
  exports: [ItemsSwitcherComponent],
})
export class ItemsSwitcherModule {}

<div class="choose-item__container">
  <div class="choose-item-title">{{title}}</div>

  <div
    *ngFor="let option of options"
    id="choose-item-dialog-sort-button"
    (click)="sortChanged(option.value)"
    class="choose-item__option flex-between"
    [class.active]="currentValue === option.value"
  >
    <div class="choose-item__option-label">{{option.label}}</div>
    <svg-icon *ngIf="currentValue === option.value" src="./assets/images/checked.svg"></svg-icon>
  </div>
</div>

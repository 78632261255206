import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InsufficientFundsError, StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';
import { getTokenIconByCurrencyCode } from '@helpers';

@Component({
  selector: 'lpt-insufficient-funds-dialog',
  templateUrl: './insufficient-funds-dialog.component.html',
  styleUrls: ['./insufficient-funds-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsufficientFundsDialogComponent {
  public StringsKeys = StringsKeys;
  public insufficientFundsError: InsufficientFundsError;

  constructor(private dialogRef: DialogRef) {
    this.insufficientFundsError = this.dialogRef.data.insufficientFundsError;
  }

  public closeDialog(goToWalletPage: boolean): void {
    this.dialogRef.close(goToWalletPage);
  }

  public getTokenIconByCurrencyCode = getTokenIconByCurrencyCode;
}

<div class="dialog__wr flex-center">
  <ng-content *ngIf="isContent; else defaultContent"></ng-content>
  <ng-template #defaultContent>
    <div class="gialog flex-center">
      <img class="gialog-icon" [src]="icon || '/assets/images/light.png'" alt="" />
      <h2 class="gialog-title">{{ title || (StringsKeys.textOops | toString ) }}</h2>
      <p *ngIf="desc" class="gialog-desc">{{ desc }}</p>

      <ng-container *ngIf="declineButton && acceptButton">
        <div class="gialog-controls flex-center">
          <button class="btn btn-white" id="dialog-decline-button" type="button" (click)="decline()">{{ declineButton }}</button>
          <button class="btn btn-orange" id="dialog-accept-button" type="button" (click)="accept()">{{ acceptButton }}</button>
        </div>
      </ng-container>
      <ng-container *ngIf="!declineButton && acceptButton">
        <button class="btn btn-white" id="dialog-accept-single-button" type="button" (click)="accept()">
          {{ acceptButton }}
        </button>
      </ng-container>
      <ng-container *ngIf="declineButton && !acceptButton">
        <button class="btn btn-white" id="dialog-decline-single-button" type="button" (click)="accept()">
          {{ acceptButton }}
        </button>
      </ng-container>
      <ng-container *ngIf="!declineButton && !acceptButton">
        <button class="btn btn-white" id="dialog-ok-button" type="button" (click)="accept()">
          {{ StringsKeys.btnOK | toString }}
        </button>
      </ng-container>
    </div>
  </ng-template>
</div>

import { Pipe, PipeTransform } from '@angular/core';

type Direction = 'up' | 'down';

@Pipe({ name: 'toFixed' })
export class ToFixedPipe implements PipeTransform {
  transform(value: number | string, digits = 0, dir: Direction = 'down'): number {
    const round = dir === 'down' ? Math.floor : Math.ceil;
    return round(Number(value) * 10 ** digits) / 10 ** digits;
  }
}

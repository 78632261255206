import { JokerModel } from './joker.types';

export enum JokerDetailsWSEvent {
  HealSuccess = 'tokenomic.joker.healSuccess',
  HealFailed = 'tokenomic.joker.healFailed',
  LevelUpSuccess = 'tokenomic.joker.levelUpSuccess',
  LevelUpFailed = 'tokenomic.joker.levelUpFailed',
  BoostSuccess = 'tokenomic.joker.boostSuccess',
  BoostFailed = 'tokenomic.joker.boostFailed',
}

export interface WsJokerDetailsResponse {
  type: JokerDetailsWSEvent;
  data: JokerModel;
}

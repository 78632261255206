import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamingHistoryStatItemComponent } from './gaming-history-stat-item.component';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [GamingHistoryStatItemComponent],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [GamingHistoryStatItemComponent],
})
export class GamingHistoryStatItemModule {}

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EffectsService, GameLayoutStateService } from '@services';
import { EEffectType, EffectModel, StringsKeys } from '@types';
import { combineLatest, map, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'lpt-effects-panel',
  templateUrl: './effects-panel.component.html',
  styleUrls: ['./effects-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EffectsPanelComponent {
  @Output() private readonly appliedEffect = new EventEmitter<EffectModel>();

  public readonly eEffectType: typeof EEffectType = EEffectType;
  public readonly StringsKeys = StringsKeys;

  constructor(
    public effectsService: EffectsService,
    public gameStateService: GameLayoutStateService
  ) {
    this.subscribeToLocalAppliedEffects();
  }

  private subscribeToLocalAppliedEffects(): void {
    combineLatest([this.effectsService.localAnimationAppliedEffect$, this.effectsService.localSoundAppliedEffect$])
      .pipe(
        untilDestroyed(this),
        map(([animation, sound]) => Boolean(animation || sound)),
        tap((has) => {
          if (has && !this.effectsService.hasActiveTimer()) {
            this.effectsService.initAppliedLocalEffectTimeout(() => untilDestroyed(this).bind(this));
          }
        })
      )
      .subscribe();
  }

  public applyEffect(e: EffectModel): void {
    if (!this.effectsService.hasActiveTimer()) {
      this.appliedEffect.emit(e);
    }
  }
}

export enum EPermissionError {
  NotAllowed = 'NotAllowedError',
  NotFound = 'NotFoundError',
}

export enum EPermissionState {
  Granted = 'granted',
  Denied = 'denied',
  Prompt = 'prompt',
}

export enum ECheckMediaPermissionsStatus {
  Granted = 'Granted',
  NotAllowed = 'NotAllowedError',
  NotFound = 'NotFoundError',
  GrantedButNotFound = 'GrantedButNotFound',
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDto } from '@types';

@Injectable({
  providedIn: 'root',
})
export class RoomsmakingService {
  constructor(private http: HttpClient) {}

  public generateUniqueRoomId(): Observable<string> {
    return this.http.post<ResponseDto<{ roomId: string }>>(`${environment.apiUrl}/playrooms`, null).pipe(
      take(1),
      map(({ result }) => result.roomId)
    );
  }
}

import { EJokerKlass } from '@types';
import { Colors } from './colors';

export const JOKER_CLASS_COLOR: Record<EJokerKlass, string> = {
  [EJokerKlass.Legendary]: '#F79E00',
  [EJokerKlass.Epic]: '#A25DD2',
  [EJokerKlass.Rare]: '#43A1FF',
  [EJokerKlass.Ordinary]: Colors.Text_Primary,
  [EJokerKlass.Common]: '#5BD83D',
};

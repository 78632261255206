<div class="no-joker__container flex-center">
  <lpt-joker-image></lpt-joker-image>

  <h3 class="no-joker__title">You don't have a joker yet</h3>
  <p class="no-joker__subtitle">You can buy the joker in the marketplace, but you can also try to play for free</p>

  <!-- Just as we have only single mode, we need to hide block below which could be displayed when smth -->
  <!-- went wrong during the loading jokers on dashboard.  -->

  <!--  TODO: Hide temporarily-->
  <!--  <button class="btn btn-white cursor-pointer no-joker__free-joker-btn" (click)="requestOrdinaryJoker()">-->
  <!--    Get your free Ordinary Joker-->
  <!--  </button>-->

  <!--  TODO: Hide temporarily-->
  <!--  <button class="btn btn-orange cursor-pointer no-joker__marketplace-btn" [routerLink]="routes.MARKETPLACE">-->
  <!--    Go to marketplace-->
  <!--  </button>-->
</div>

import { Component, Optional } from '@angular/core';
import {
  DialogHelperService,
  GameLayoutStateService,
  GamePreflightService,
  GameStageService,
  GamingHistoryService,
  NavigationService,
  ReportsService,
} from '@services';
import { ROUTES_ENUM } from '@constants';
import { filter, Observable, switchMap, take } from 'rxjs';
import { EGameStage, EReportType, JokerModel, OpponentModel, StringsKeys } from '@types';
import { ReportTypeSelectionComponent } from '../report-type-selection/report-type-selection.component';
import { hasEnoughCrazinessToPlay, isJokerOrdinary } from '@helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'lpt-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss'],
})
export class ResultComponent {
  public readonly StringsKeys = StringsKeys;

  constructor(
    public readonly gameStateService: GameLayoutStateService,
    private readonly navigationService: NavigationService,
    @Optional() private readonly stageService: GameStageService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly reportsService: ReportsService,
    private readonly gamingHistoryService: GamingHistoryService,
    private readonly gamePreflightService: GamePreflightService
  ) {}

  public upload(gameId: string): void {
    this.gamingHistoryService.uploadGame(gameId);
  }

  public report(): void {
    this.dialogHelperService
      .openDialogComponent<ReportTypeSelectionComponent, EReportType>(ReportTypeSelectionComponent)
      .pipe(
        take(1),
        untilDestroyed(this),
        filter(Boolean),
        switchMap((reportType) => this.reportsService.report(reportType, this.gameStateService.getOpponentId()!))
      )
      .subscribe();
  }

  public toHome(): void {
    this.navigationService.toDashboard();
  }

  public nextGame(): void {
    this.gameStateService.setOpponent(null);
    this.gameStateService.setGameId(null);
    this.gameStateService.setGameResultData(null);
    this.stageService.setStage(EGameStage.Pairing);
  }

  public get fromHistory$(): Observable<boolean> {
    return this.navigationService.isRouteActive$(ROUTES_ENUM.GAMING_HISTORY);
  }

  public toGameHistory(): void {
    this.navigationService.toGamingHistory();
  }

  public showOpponentStat(opponent: OpponentModel): void {
    this.gameStateService.showOpponentStat(opponent);
  }

  public hasAbilityToPlayNext(joker: JokerModel | null): boolean {
    if (this.gamePreflightService.roomId) return true;
    if (!joker) return false;
    if (isJokerOrdinary(joker)) {
      return Boolean(joker.gamesToPlay);
    }

    return hasEnoughCrazinessToPlay(joker);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lpt-gaming-history-stat-item',
  templateUrl: './gaming-history-stat-item.component.html',
  styleUrls: ['./gaming-history-stat-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GamingHistoryStatItemComponent {
  @Input() public statName: string;
  @Input() public statValue: string;
  @Input() public iconSrc: string;
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { CustomToastrService, ToStringService } from '@services';
import { StringsKeys } from '@types';

@Component({
  selector: 'lpt-wallet-address',
  templateUrl: './wallet-address.component.html',
  styleUrls: ['./wallet-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WalletAddressComponent {
  @Input() public address: string;

  constructor(
    private clipboardService: ClipboardService,
    private toStringService: ToStringService,
    private customToastrService: CustomToastrService
  ) {}

  public copyToClipboard(): void {
    this.clipboardService.copy(this.address);
    this.customToastrService.show('', this.toStringService.toString(StringsKeys.toastAddressCopiedText));
  }
}

<div class="result_wr flex-center">
  <div *ngIf="gameStateService.gameResultData$ | async as data" class="result flex-between w-100">
    <div class="result__main w-100 flex-between">
      <div class="result__col result__header w-100 flex-between">
        <svg-icon
          *ngIf="fromHistory$ | async"
          class="header__icon-svg"
          id="result-to-game-history-icon-button"
          [src]="'./assets/images/arrow-left.svg'"
          (click)="toGameHistory()"
        ></svg-icon>
        <lpt-call-timer [total]="data.gameDuration"></lpt-call-timer>
        <button
          class="cursor-pointer flex-center"
          id="result-upload-button"
          *ngIf="gameStateService.gameId$ | async as gameId"
          (click)="upload(gameId)"
        >
          <svg-icon src="./assets/images/upload.svg"></svg-icon>
        </button>
      </div>

      <svg-icon
        class="result__col result__result"
        [class.grayscale]="data.gameResult === 'lost'"
        src="./assets/images/game-result/{{data.gameResult}}.svg"
      ></svg-icon>

      <div class="result__col result__reward flex-center">
        <span>+{{ data.reward | toFixed:2 }}</span>
        <img src="./assets/images/tokens/token-blue.png" alt="Token" />
      </div>

      <div class="result__current">
        <img *ngIf="data.gameResult === 'won'" class="result__crown current" src="./assets/images/crown.png" alt="Icon" />
        <lpt-joker-image
          *ngIf="gameStateService.currentJoker$ | async as currentJoker"
          [class.grayscale]="data.gameResult === 'lost'"
          [image]="currentJoker.metadata.image"
          [nickname]="gameStateService.nickname"
          [size]="'md'"
        ></lpt-joker-image>
      </div>

      <div class="result__col result__opponent-wr w-100">
        <div class="result__opponent d-flex cursor-pointer">
          <img *ngIf="data.gameResult === 'lost'" class="result__crown opponent" src="./assets/images/crown.png" alt="Icon" />
          <lpt-joker-image
            *ngIf="gameStateService.opponent$ | async as opponent"
            [class.grayscale]="data.gameResult === 'won'"
            [image]="opponent.joker.metadata.image"
            [nickname]="opponent.nickname"
            [size]="'sm'"
            id="result-show-opponent-stats-image-button"
            (click)="showOpponentStat(opponent)"
          ></lpt-joker-image>
        </div>
      </div>
    </div>

    <footer class="result__actions w-100">
      <div class="result__actions-col flex-center" *ngIf="!(fromHistory$ | async)">
        <button class="btn btn-white" id="result-to-home-button" (click)="toHome()">{{ StringsKeys.btnHome | toString }}</button>
        <button
          class="btn btn-orange"
          id="result-next-game-button"
          *ngIf="hasAbilityToPlayNext(gameStateService.currentJoker$ | async)"
          (click)="nextGame()"
        >
          {{ StringsKeys.btnNextGame | toString }}
        </button>
      </div>
      <button class="result__actions-report link d-block cursor-pointer" id="result-report-button" (click)="report()">
        {{ StringsKeys.btnReport | toString }}
      </button>
    </footer>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerImageComponent } from './joker-image.component';
import { ToStringPipeModule } from '@pipes';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageCacheDirective } from '@directives';

@NgModule({
  declarations: [JokerImageComponent],
  imports: [CommonModule, ToStringPipeModule, AngularSvgIconModule, NgxSpinnerModule, ImageCacheDirective],
  exports: [JokerImageComponent],
})
export class JokerImageModule {}

import { IJokerEffectsObject } from './effect.types';
import { EJokerKlass, INFTMetadata } from './joker.metadata.structure';

export class JokerBaseModel {
  readonly tokenId: string;

  readonly metadata: INFTMetadata;

  readonly gamesToPlay: number;

  readonly maxGamesToPlay: number;

  readonly effects: IJokerEffectsObject[];

  readonly attributePoints: number;

  readonly dailyCoins: number;

  readonly maxDailyCoins: number;

  readonly minExtraCraziness: number;

  readonly ownerId: string;

  readonly orderId: string | null;
}

export class JokerModel extends JokerBaseModel {
  readonly imageLayers: [[string, number]];

  readonly klass: EJokerKlass;

  readonly recoveryTime: number;
  readonly isOnSale: boolean;
}

export const JokerEventNamespace = 'joker';

export enum JokerWsEvent {
  ORDINARY_JOKER_MINTED = `joker.ordinary.minted`,
  ORDINARY_JOKER_MINT_FAILED = `joker.ordinary.mintFailed`,
  ORDINARY_JOKER_RENEWED = `joker.ordinary.renewed`,
  ORDINARY_JOKER_RENEW_FAILED = `joker.ordinary.renewFailed`,
}

export interface WsJokerResponse {
  type: JokerWsEvent;
  data: JokerModel;
}

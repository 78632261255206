<div class="enter__roomid-wr">
  <h2 class="enter__roomid-title">{{ StringsKeys.playOptionsRoomIDPopupTitle | toString }}</h2>

  <div class="flex-between">
    <h3 class="enter__roomid-control:label">{{ StringsKeys.playOptionsRoomIDPopupFieldTitle | toString }}</h3>
    <button class="enter__roomid-paste cursor-pointer link" id="enter-room-id-paste-button" type="button" (click)="paste()">
      {{ StringsKeys.btnPaste | toString }}
    </button>
  </div>

  <div class="enter__roomid-control:wr pos-rel">
    <lpt-input
      [formControl]="control"
      [valid]="control.dirty && control.valid"
      type="text"
      [mask]="mask"
      [placeholder]="StringsKeys.playOptionsRoomIDPopupFieldHint | toString"
    ></lpt-input>
    <svg-icon
      class="pos-abs cursor-pointer"
      id="enter-room-id-scan-icon-button"
      src="./assets/images/scaner.svg"
      (click)="scan()"
    ></svg-icon>
  </div>

  <div class="enter__roomid-controls flex-center">
    <button class="btn btn-white" id="enter-room-id-close-button" (click)="close()">{{ StringsKeys.btnClose | toString }}</button>
    <button class="btn btn-orange" id="enter-room-id-join-button" [disabled]="control.invalid" (click)="join()">
      {{ StringsKeys.playOptionsQRJoin | toString }}
    </button>
  </div>
</div>

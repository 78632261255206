import { Directive, ElementRef, HostListener } from '@angular/core';
import { getEffectSourceElement } from '@helpers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EffectsService } from '@services';
import { EEffectType } from '@types';

@UntilDestroy()
@Directive({
  selector: '[lptPlaybackAudioEffect]',
})
export class PlaybackAudioEffectDirective {
  constructor(
    private el: ElementRef<HTMLAudioElement>,
    private effectsService: EffectsService
  ) {
    this.subscribeToOpponentAppliedSoundEffect();
  }

  @HostListener('ended')
  onAudioEnded() {
    this.effectsService.applyRemoteEffect(undefined, EEffectType.SOUND);
  }

  private subscribeToOpponentAppliedSoundEffect(): void {
    this.effectsService.opponentAppliedSoundEffect$.pipe(untilDestroyed(this)).subscribe((effect) => {
      const sourceElement = getEffectSourceElement();
      if (!sourceElement) {
        return;
      }

      this.el.nativeElement.pause();
      sourceElement.src = effect?.url || '';
      try {
        this.el.nativeElement.load();
      } finally {
        if (effect) {
          this.el.nativeElement.play();
        }
      }
    });
  }
}

import { Injectable } from '@angular/core';
import { EGameStage } from '@types';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GameStageService {
  private _stage = new BehaviorSubject<EGameStage>(EGameStage.Pairing);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  public get activeStage(): EGameStage {
    return this._stage.value;
  }

  public get stage$() {
    return this._stage.asObservable();
  }

  public setStage(stage: EGameStage) {
    stage === this.activeStage || this._stage.next(stage);
  }
}

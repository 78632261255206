import { Injectable } from '@angular/core';
import { StringsKeys } from '@types';
import * as strings from 'src/assets/strings/strings.json';

@Injectable()
export class ToStringService {
  private readonly strings: Map<StringsKeys, string>;

  constructor() {
    this.strings = new Map(<[StringsKeys, string][]>Object.entries(strings));
  }

  toString(key: StringsKeys, props?: Record<string, any>): string {
    let string = this.strings.get(key) || key;

    if (props instanceof Object) {
      string = Object.entries(props).reduce((str, el) => str.replace(`{${el[0]}}`, el[1]), string);
    }

    return string;
  }
}

import { FaceDetectionTaskResultType, IGameEvent } from '@types';
import { DetectionPropertyAverager } from '../detection-property-averager';

export class FaceDetectionHandler {
  public static process(
    detections: FaceDetectionTaskResultType,
    source: HTMLVideoElement | HTMLImageElement,
    averager: DetectionPropertyAverager
  ): Partial<IGameEvent> {
    return {
      faceVisible: FaceDetectionHandler.calcVisibility(detections),
      headBounce: FaceDetectionHandler.calcHeadBounce(detections),
      brightness: FaceDetectionHandler.calcBrightness(detections, source),
      smileProbability: averager.setNewValue(FaceDetectionHandler.calcSmileProbability(detections)).getAverageValue(),
    };
  }

  private static calcVisibility(_detections: FaceDetectionTaskResultType): boolean {
    return Boolean(_detections);
  }

  public static calcHeadBounce(_detections: FaceDetectionTaskResultType): number | undefined {
    if (!_detections) {
      return;
    }

    const imageWidth = _detections.detection.imageWidth;
    const faceWidth = _detections.detection.box.width;

    const imageHeight = _detections.detection.imageWidth;
    const faceHeight = _detections.detection.box.height;

    const heightPercent = (faceHeight / imageHeight) * 100;

    const widthPercent = (faceWidth / imageWidth) * 100;

    return (heightPercent + widthPercent) / 2;
  }

  private static calcSmileProbability(_detections: FaceDetectionTaskResultType): number | undefined {
    if (!_detections) {
      return;
    }
    return _detections.expressions.happy;
  }

  private static calcBrightness(
    _detections: FaceDetectionTaskResultType,
    video: HTMLVideoElement | HTMLImageElement
  ): number | undefined {
    if (!_detections) {
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    const { top, left, width, height } = _detections.detection.box;
    canvas.width = width;
    canvas.height = height;
    context.drawImage(video, left, top, width, height, 0, 0, width, height);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height).data;

    let sum = 0;
    for (let i = 0; i < imageData.length; i += 4) {
      const brightness = (imageData[i] + imageData[i + 1] + imageData[i + 2]) / 3;
      sum += brightness;
    }

    return sum / (imageData.length / 4) / 2.55;
  }
}

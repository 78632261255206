import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericJokerConfirmDialogComponent } from './generic-joker-confirm-dialog.component';
import { JokerImageModule } from '../joker-image/joker-image.module';
import { InfoRowItemModule } from '../info-row-item/info-row-item.module';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [GenericJokerConfirmDialogComponent],
  imports: [CommonModule, JokerImageModule, InfoRowItemModule, AngularSvgIconModule],
  exports: [GenericJokerConfirmDialogComponent],
})
export class GenericJokerConfirmDialogModule {}

<div class="progress-bar__container">
  <div
    class="progress-bar__element"
    [style]="{
  width: filledPercentage | percent,
  backgroundColor: color
  }"
  ></div>

  <ng-container *ngIf="stepped">
    <div class="progress-bar__steps">
      <div
        *ngFor="let step of dividers;let i = index"
        class="progress-bar__steps-item"
        [style]="{width: 100 / filledPercentage | percent }"
      ></div>
    </div>

    <div class="progress-bar__labels">
      <div *ngFor="let step of steps">{{step+1}}</div>
    </div>
  </ng-container>
</div>

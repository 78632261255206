import { Directive, ElementRef } from '@angular/core';
import { PlaybackMediaTracksService } from '@services';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[lptPlaybackVideoTrack]',
})
export class PlaybackVideoTrackDirective {
  constructor(
    private el: ElementRef<HTMLVideoElement>,
    private playbackMediaTracksService: PlaybackMediaTracksService
  ) {
    this.subscribeToVideoTrack();
  }

  private subscribeToVideoTrack(): void {
    this.playbackMediaTracksService.videoStream$.pipe(untilDestroyed(this)).subscribe((stream) => {
      if (stream) {
        // 'play()' method is (should be) executed within user gesture scope to avoid exceptions.
      } else if (stream === null) {
        this.stop();
      }
    });
  }

  stop(): void {
    this.el.nativeElement.pause();
    this.el.nativeElement.src = '';
    this.el.nativeElement.srcObject = null;
    this.el.nativeElement.load();
  }
}

import { MarketFilterParams } from '@types';

export const defaultFilterParams: MarketFilterParams = {
  classes: [],
  minLevel: 0,
  maxLevel: 30,
};

export function isDefaultParams(params: MarketFilterParams): boolean {
  return (
    Boolean(!params.classes?.length) &&
    params.maxLevel === defaultFilterParams.maxLevel &&
    params.minLevel === defaultFilterParams.minLevel
  );
}

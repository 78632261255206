import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StringsKeys } from '@types';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-joker-purchase-published-dialog',
  templateUrl: './joker-purchase-published-dialog.component.html',
  styleUrls: ['./joker-purchase-published-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerPurchasePublishedDialogComponent {
  public readonly StringsKeys = StringsKeys;

  constructor(private dialogRef: DialogRef) {}

  public closeDialog(): void {
    this.dialogRef.close(false);
  }
}

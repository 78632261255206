import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarketFilterDialogComponent } from './market-filter-dialog.component';
import { RangeSelectorModule } from '../range-selector/range-selector.module';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [MarketFilterDialogComponent],
  imports: [CommonModule, RangeSelectorModule, ToStringPipeModule],
  exports: [MarketFilterDialogComponent],
})
export class MarketFilterDialogModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'lpt-joker-resource',
  templateUrl: './joker-resource.component.html',
  styleUrls: ['./joker-resource.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JokerResourceComponent {
  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public iconSrc: string;
  @Input() public minValue: number;
  @Input() public maxValue: number;
  @Input() public currentValue: number;
  @Input() public stepped: boolean;
  @Input() public color: string;
  @Input() public hideSubtitle?: boolean;
}

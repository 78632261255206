import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InfoRowItemProps, JokerModel } from '@types';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-generic-joker-confirm-dialog',
  templateUrl: './generic-joker-confirm-dialog.component.html',
  styleUrls: ['./generic-joker-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericJokerConfirmDialogComponent {
  public joker: BehaviorSubject<JokerModel | null> = new BehaviorSubject<JokerModel | null>(null);
  public title: string;
  public subTitle: string;
  public closeBtn: string;
  public confirmBtn: string;
  public icon: string;
  public rows: BehaviorSubject<InfoRowItemProps[]> = new BehaviorSubject<InfoRowItemProps[]>([]);

  constructor(public dialogRef: DialogRef) {
    this.title = this.dialogRef.data.title;
    this.subTitle = this.dialogRef.data.subTitle;
    this.closeBtn = this.dialogRef.data.closeBtn;
    this.confirmBtn = this.dialogRef.data.confirmBtn;
    this.icon = this.dialogRef.data.icon;

    this.joker.next(this.dialogRef.data.joker);

    this.rows.next(this.dialogRef.data.rows);
  }
}

import { JokerBaseModel } from './joker/joker.types';

export class OpponentModel {
  readonly id: string;

  readonly email: string;

  readonly nickname: string;

  readonly joker: IJokerResponse;
}

export class IJokerResponse extends JokerBaseModel {
  readonly isOnSale?: boolean;
}

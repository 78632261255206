<header class="flex-between header">
  <svg-icon src="./assets/images/logo.svg" class="logo"></svg-icon>

  <div class="flex-between header__items">
    <lpt-navigation-panel [navItems]="navItems" [verticalAppearance]="false"></lpt-navigation-panel>
    <button class="flex-center logout-btn cursor-pointer">
      <span>Log Out</span>
      <svg-icon src="./assets/images/exit.svg" [style.stroke]="Colors.Background_Primary"></svg-icon>
    </button>

    <button class="flex-center theme-btn cursor-pointer">
      <svg-icon src="./assets/images/theme-icon.svg" class="theme-btn-icon"></svg-icon>
    </button>
  </div>
</header>

import { SessionData } from '@types';

export class ReferralModel {
  readonly id: string;

  readonly firstName: string;

  readonly lastName: string;

  readonly masterId: string;

  readonly provider: string;

  readonly puppet: SessionData;

  readonly puppetId: SessionData['id'];

  readonly referenceId: string;

  readonly reward: number;

  readonly username: string | undefined;

  readonly createdAt: string;

  readonly updatedAt: string;

  readonly image: string;
}

export interface ReferralsDto {
  activeReferrals: ReferralModel[];
  activeReferralsCount: number;
  inactiveReferrals: ReferralModel[];
  inactiveReferralsCount: number;
  totalEarned: number;
}

export enum EReferralsTab {
  Accepted = 'activeReferrals',
  Pending = 'inactiveReferrals',
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WalletService } from '@services';
import { BalanceLoopItem } from '@types';

@Component({
  selector: 'lpt-balance-header',
  templateUrl: './balance-header.component.html',
  styleUrls: ['./balance-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceHeaderComponent {
  public readonly tokens: BalanceLoopItem[] = [
    { name: 'baseAsset', precision: '1.0-5', icon: './assets/images/bnb.svg' },
    { name: 'earningToken', precision: '1.0-2', icon: './assets/images/earning-token.svg' },
    // Hidden by LPT-1238
    // { name: 'governanceToken', precision: '1.0-5', icon: './assets/images/governance-token.svg' },
  ];

  constructor(public walletService: WalletService) {}
}

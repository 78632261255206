import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, take } from 'rxjs';
import { IEarnItem, IEarnItemsDto, Pagination, ResponseDto } from '@types';
import { environment } from 'src/environments/environment';
import { DialogHelperService } from './dialog-helper.service';

@Injectable({
  providedIn: 'root',
})
export class EarnService {
  constructor(
    private readonly http: HttpClient,
    private readonly dialogHelperService: DialogHelperService
  ) {}

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/TasksController_tasks
   */
  public getEarnList(pagination: Pagination): Observable<ResponseDto<IEarnItemsDto>> {
    const params = new HttpParams({
      fromObject: {
        limit: pagination.limit,
        page: pagination.page,
      },
    });
    return this.http.get<ResponseDto<IEarnItemsDto>>(`${environment.apiUrl}/single-mode/tasks`, { params }).pipe(
      take(1),
      catchError((e) => {
        this.dialogHelperService.openDefaultErrorDialog();
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/TasksController_completeTask
   */
  public grabEarning(id: string): Observable<boolean> {
    return this.http.post<ResponseDto<boolean>>(`${environment.apiUrl}/single-mode/tasks/${id}/complete`, null).pipe(
      take(1),
      map(({ result }) => result),
      catchError((e: HttpErrorResponse) => {
        if (e.error.code === 422) {
          this.dialogHelperService.closeAllDialogs();
        } else {
          this.dialogHelperService.openDefaultErrorDialog(true);
        }
        throw e;
      })
    );
  }

  /**
   * @see https://lolypto-api.dev.scrij.com/api-docs/#/Single%20Mode/TasksController_show
   */
  public getEarningById(id: string): Observable<IEarnItem> {
    return this.http.get<ResponseDto<IEarnItem>>(`${environment.apiUrl}/single-mode/tasks/${id}`).pipe(
      take(1),
      map(({ result }) => result),
      catchError((e: HttpErrorResponse) => {
        this.dialogHelperService.openDefaultErrorDialog(true);
        throw e;
      })
    );
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperComponent } from './swiper.component';
import { SwiperModule } from 'swiper/angular';
import { JokerImageModule } from '../joker-image/joker-image.module';

@NgModule({
  declarations: [SwiperComponent],
  imports: [SwiperModule, CommonModule, JokerImageModule],
  exports: [SwiperComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SwiperComponentModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuyJokerDialogComponent } from './buy-joker-dialog.component';
import { InfoRowItemModule, JokerImageModule, JokerStatsModule } from '@components';
import { ToStringPipeModule } from '@pipes';

@NgModule({
  declarations: [BuyJokerDialogComponent],
  imports: [CommonModule, JokerImageModule, JokerStatsModule, InfoRowItemModule, ToStringPipeModule],
  exports: [BuyJokerDialogComponent],
})
export class BuyJokerDialogModule {}

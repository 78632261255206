export const Colors = {
  Blue: 'var(--blue)',
  Red: 'var(--red)',
  Purple: 'var(--types-of-djokers-epic)',
  Orange: 'var(--orange)',
  Green: 'var(--types-of-djokers-common)',
  Background_Primary: 'var(--backgrounds-primary)',
  Background_Secondary: 'var(--backgrounds-secondary)',
  Text_Primary: 'var(--text-primary)',
  Text_Secondary: 'var(--text-secondary)',
  Transparent: 'transparent',
};

import { Injectable } from '@angular/core';
import { NgxSpinnerService, Spinner } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  public showGlobalSpinner(): void {
    this.ngxSpinnerService.show('global-spinner');
  }

  public hideGlobalSpinner(): void {
    this.ngxSpinnerService.hide('global-spinner');
  }

  public showLocalSpinner(name: string, options?: Spinner): void {
    this.ngxSpinnerService.show(name, options);
  }

  public hideLocalSpinner(name: string): void {
    this.ngxSpinnerService.hide(name);
  }
}

<section>
  <ng-container *ngIf="lottieAnimationOptions">
    <ng-lottie [options]="lottieAnimationOptions" (complete)="animationCompleted()"></ng-lottie>
  </ng-container>
</section>

<div class="flex-center w-100">
  <img src="assets/images/face.png" alt="Face" />
  <p class="flex-1">{{ StringsKeys.textSmileToEarn | toString }}</p>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JokerStatsComponent } from './joker-stats.component';

@NgModule({
  declarations: [JokerStatsComponent],
  imports: [CommonModule],
  exports: [JokerStatsComponent],
})
export class JokerStatsModule {}

import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { StringsKeys } from '@types';
import { AnimationOptions } from 'ngx-lottie';
import { lottieAnimationOptionsFactory } from '@helpers';
import { DialogRef } from '@ngneat/dialog';
import { SINGLE_MODE_PREPARATION_ANIMATION_PATH } from '@constants';

@Component({
  selector: 'lpt-single-mode-preparation-dialog',
  templateUrl: './single-mode-preparation-dialog.component.html',
  styleUrls: ['./single-mode-preparation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleModePreparationDialogComponent {
  public readonly StringsKeys = StringsKeys;
  public readonly lottieAnimationOptions: AnimationOptions = lottieAnimationOptionsFactory(
    SINGLE_MODE_PREPARATION_ANIMATION_PATH
  );
  private readonly dialogRef: DialogRef = inject(DialogRef);

  public animationCompleted() {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { GamePreflightService, NavigationService } from '@services';

@Injectable({
  providedIn: 'root',
})
export class GameGuard {
  constructor(
    private gamePreflightService: GamePreflightService,
    private navigationService: NavigationService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.gamePreflightService.isPreflightReady().pipe(tap((isReady) => isReady || this.navigationService.toDashboard()));
  }
}

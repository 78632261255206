import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';

@Component({
  selector: 'lpt-player-issue-dialog',
  template: `
    <img class="issue__icon" src="assets/images/warning.png" alt="Icon" />
    <p class="issue__message">{{ text }}</p>
  `,
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        gap: 20px;
        padding: 20px;
        background-color: var(--backgrounds-secondary);
      }
      .issue__icon {
        width: 40px;
        height: 40px;
      }
      .issue__message {
        color: var(--text-primary);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayerIssueDialogComponent {
  text: string;

  constructor(
    private dialogRef: DialogRef,
    private cdr: ChangeDetectorRef
  ) {
    this.text = this.dialogRef.data?.text;
  }

  public setNewText(newText: string): void {
    this.text = newText;
    this.cdr.detectChanges();
  }
}

export enum EEffectType {
  SOUND = 'sound',
  ANIMATION = 'animation',
}

export interface IJokerEffectsObject {
  effect: EffectModel;
}

export class EffectModel {
  readonly id: string;

  readonly type: EEffectType;

  readonly name: string;

  readonly url: string;

  readonly iconUrl: string;
}

import { Injectable } from '@angular/core';
import { DetectionPropertyAverager, HandLandmarkerModelsLoader } from '@helpers';
import { BehaviorSubject } from 'rxjs';
import { DialogHelperService } from '../dialog-helper.service';
import { HandLandmarker } from '@mediapipe/tasks-vision';

@Injectable({
  providedIn: 'root',
})
export class HandLandmarkerService {
  private readonly _isHandLandmarkerLoaded = new BehaviorSubject<boolean>(false);
  readonly isHandLandmarkerLoaded$ = this._isHandLandmarkerLoaded.asObservable();

  private _isHandLandmarkerLoadingFailed = false;

  private _handLandmarker: HandLandmarker;
  private _handLandmarkerAverager: DetectionPropertyAverager;

  constructor(private dialogHelperService: DialogHelperService) {}

  public isHandLandmarkerLoadingFailed(): boolean {
    return this._isHandLandmarkerLoadingFailed;
  }

  public detectHand(video: HTMLVideoElement): boolean {
    const handLandmarkerResult = this._handLandmarker?.detectForVideo(video, performance.now());
    const handednessLength = this._handLandmarkerAverager.setNewValue(handLandmarkerResult.handedness.length).getAverageValue();
    return Boolean(handednessLength);
  }

  public loadModels(): Promise<void> {
    return HandLandmarkerModelsLoader.loadHandLandmarkerModel()
      .then((instance) => {
        this._handLandmarkerAverager = new DetectionPropertyAverager();
        this._handLandmarker = instance;
        this._isHandLandmarkerLoaded.next(true);
      })
      .catch(this.exceptionHandler.bind(this));
  }

  private exceptionHandler(): void {
    this._isHandLandmarkerLoadingFailed = true;
    this.dialogHelperService.openDefaultErrorDialog();
  }
}

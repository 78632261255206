export enum EJokerKlass {
  Common = 'common',
  Epic = 'epic',
  Rare = 'rare',
  Legendary = 'legendary',
  Ordinary = 'ordinary',
}

export enum EJokerAttributeTraitType {
  klass = 'class',
  stamina = 'stamina',
  craziness = 'craziness',
  elegance = 'elegance',
  level = 'level',
  extraCraziness = 'extra_craziness',
}

export interface INFTAttribute {
  traitType: EJokerAttributeTraitType;
  value: string | number;
  minValue?: number;
  maxValue?: number;
}

export interface INFTMetadata {
  name: string;
  description: string;
  image: string;
  externalUrl: string;
  attributes: INFTAttribute[];
}

import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROOMSMAKING_ROUTES_SCOPE, ROUTES_ENUM } from '@constants';
import { distinctUntilChanged, filter, map, Observable, startWith } from 'rxjs';
import { isTelegramWebView } from '@helpers';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private router: Router) {}

  public isRouteActive$(route: ROUTES_ENUM, strict = false): Observable<boolean> {
    return this.router.events.pipe(
      filter((ev): ev is NavigationEnd => ev instanceof NavigationEnd),
      startWith(this.router),
      map((ev) => {
        const tgWebAppHashData = '#tgWebAppData';
        return isTelegramWebView() && ev.url.includes(tgWebAppHashData) ? ev.url.split(tgWebAppHashData)[0] : ev.url;
      }),
      map((url) => {
        if (strict) return url === this.router.createUrlTree([route]).toString();

        return url.includes(route);
      }),
      distinctUntilChanged()
    );
  }

  public toDashboard(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.HOME]);
  }

  public toLogin(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.LOGIN]);
  }

  public toGame(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.GAME]);
  }

  public toJokerDetails(jokerId: string): Promise<boolean | void> {
    return this.router.navigate([ROUTES_ENUM.JOKERS, jokerId]);
  }

  public toWallet(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.HOME, ROUTES_ENUM.WALLET]);
  }

  public toProfile(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.PROFILE]);
  }

  public toProfileEdit(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.PROFILE, ROUTES_ENUM.EDIT_PROFILE]);
  }

  public toGamingHistory(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.PROFILE, ROUTES_ENUM.GAMING_HISTORY]);
  }

  public toGameResult(gameId: string): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.PROFILE, ROUTES_ENUM.GAMING_HISTORY, gameId]);
  }

  public toNotifications(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.NOTIFICATIONS]);
  }

  public toFriendRoomCreation(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.ROOMSMAKING, ROOMSMAKING_ROUTES_SCOPE.CREATE_ROOM]);
  }

  public toFriendRoomJoining(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.ROOMSMAKING, ROOMSMAKING_ROUTES_SCOPE.JOIN_ROOM]);
  }

  public toMarketPlace(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.HOME, ROUTES_ENUM.MARKETPLACE]);
  }

  public toOnboarding(fromPath?: string): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.ONBOARDING], {
      ...(fromPath && {
        queryParams: { from: this.router.url },
      }),
    });
  }

  public toSingleMode(): Promise<boolean> {
    return this.router.navigate([ROUTES_ENUM.SINGLE_MODE]);
  }
}

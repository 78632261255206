import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DialogRef } from '@ngneat/dialog';
import { EJokerKlass, MarketFilterParams, StringsKeys } from '@types';
import { getJokerClassColor } from '@helpers';
import { defaultFilterParams, isDefaultParams } from '@constants';

@Component({
  selector: 'lpt-market-filter-dialog',
  templateUrl: './market-filter-dialog.component.html',
  styleUrls: ['./market-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketFilterDialogComponent {
  public StringsKeys = StringsKeys;
  public title: string;
  public types: EJokerKlass[];
  public currentParams!: MarketFilterParams;
  public newParams: MarketFilterParams;

  constructor(private dialogRef: DialogRef) {
    this.title = this.dialogRef.data.title;
    this.types = this.dialogRef.data.types;
    this.currentParams = this.dialogRef.data.currentParams;
    this.newParams = structuredClone(this.dialogRef.data.currentParams);
  }

  public isDefaultParams = isDefaultParams;

  public getJokerClassColor = getJokerClassColor;

  public isInTheList(eClass: EJokerKlass): boolean {
    return this.newParams.classes?.includes(eClass) || false;
  }

  public toggleClassInFilters(eClass: EJokerKlass): void {
    if (this.newParams.classes?.includes(eClass)) {
      this.newParams.classes = this.newParams.classes.filter((c) => c !== eClass);
      return;
    }
    this.newParams.classes?.push(eClass);
  }

  public levelRangeChanged({ min, max }: { min: number; max: number }): void {
    this.newParams = { ...this.newParams, minLevel: min, maxLevel: max };
  }

  public applyFilter(): void {
    this.dialogRef.close(this.newParams);
  }

  public clearFilter(): void {
    this.dialogRef.close(defaultFilterParams);
  }
}

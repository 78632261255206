import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  AppSettingsService,
  DialogHelperService,
  FaceApiProcessorService,
  GamePreflightService,
  LoaderService,
  NavigationService,
  PlaybackMediaTracksService,
  SingleModeService,
  SpinnerService,
  ToStringService,
} from '@services';
import { GoogleAnalyticsEventsType, StringsKeys } from '@types';
import { defer, filter, from, iif, mergeMap, of, switchMap, take, tap, timer } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { VIDEO_MEDIA_TRACK_CONSTRAINTS } from '@constants';
import { SingleModeMultiplicationDialogComponent } from '../single-mode-multiplication-dialog/single-mode-multiplication-dialog.component';
import { getPlaybackVideoElement, isCompatibleToVideoCall, playbackTrack } from '@helpers';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { sendSentryReport } from '@helpers';

@UntilDestroy()
@Component({
  selector: 'lpt-single-mode-widget',
  templateUrl: './single-mode-widget.component.html',
  styleUrls: ['./single-mode-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleModeWidgetComponent implements OnInit {
  public readonly StringsKeys = StringsKeys;

  constructor(
    public readonly singleModeService: SingleModeService,
    public readonly loader: LoaderService,
    private readonly spinnerService: SpinnerService,
    private readonly navigationService: NavigationService,
    private readonly faceApiProcessorService: FaceApiProcessorService,
    private readonly dialogHelperService: DialogHelperService,
    private readonly preflightService: GamePreflightService,
    private readonly appSettingsService: AppSettingsService,
    private readonly playbackMediaTracksService: PlaybackMediaTracksService,
    private readonly toStringService: ToStringService,
    private readonly gaService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.initTimer();
  }

  private initTimer(): void {
    timer(0, 1e3)
      .pipe(
        untilDestroyed(this),
        mergeMap(() => this.singleModeService.settings$.pipe(take(1))),
        filter(Boolean),
        tap((settings) => {
          if (settings.timeTillNextGame > 0) {
            settings.update();
            this.singleModeService.settings.next(settings);
          }
        })
      )
      .subscribe();
  }

  public earn(): void {
    this.gaService.event(GoogleAnalyticsEventsType.play);

    if (!isCompatibleToVideoCall()) {
      return this.dialogHelperService.openErrorDialogWithText(
        this.toStringService.toString(StringsKeys.videoCallsNotSupportedInBrowser)
      );
    }

    this.loader.activate();
    this.spinnerService.showGlobalSpinner();

    this.startVideoTrack()
      .catch(() => {
        this.handleException(StringsKeys.permissionsCameraAndMicrophoneRequired);
      })
      .then((stream) => {
        if (stream) {
          return playbackTrack(getPlaybackVideoElement(), stream);
        }
        return;
      })
      .then((stream) => {
        if (stream) {
          this.initSecondaryDependencies(stream);
        }
      })
      .catch((e) => {
        // Case when video play() was failed.
        this.handleException(StringsKeys.userGestureCallbackIsLostForPermissionCamera);
        sendSentryReport(e, 'Exception: Case when video play() or load() were failed.');
      });
  }

  private initSecondaryDependencies(stream: MediaStream): void {
    this.faceApiProcessorService.isModelsLoaded$
      .pipe(
        switchMap((isModelsLoaded) =>
          iif(
            () => isModelsLoaded,
            defer(() => of(true)),
            defer(() => from(this.faceApiProcessorService.loadModels()))
          )
        ),
        switchMap(() => this.appSettingsService.getAppSettings())
      )
      .subscribe({
        next: () => {
          this.preflightFaceDetection(stream);
        },
        error: () => {
          this.playbackMediaTracksService.detachVideoStream();
          this.spinnerService.hideGlobalSpinner();
          this.loader.deactivate();
        },
      });
  }

  private handleException(key: StringsKeys): void {
    this.loader.deactivate();
    this.spinnerService.hideGlobalSpinner();
    this.dialogHelperService.openErrorDialogWithText(this.toStringService.toString(key));
  }

  private preflightFaceDetection(stream: MediaStream): void {
    this.preflightService.isPreflightFaceDetectionExecuted$
      .pipe(
        tap((isExecuted) => {
          if (isExecuted === null) {
            this.spinnerService.hideGlobalSpinner();
            this.loader.deactivate();
          } else if (isExecuted) {
            this.playbackMediaTracksService.storeVideoStream(stream);
            this.singleModeService.markSingleModeAsReady();
            void this.navigationService.toSingleMode();
          } else {
            this.preflightService.preflightFaceDetection();
          }
        })
      )
      .subscribe();
  }

  private startVideoTrack(): Promise<MediaStream> {
    return navigator.mediaDevices.getUserMedia({ video: VIDEO_MEDIA_TRACK_CONSTRAINTS, audio: false });
  }

  public multiplication(): void {
    this.gaService.event(GoogleAnalyticsEventsType.viewMultiplierHelp);

    this.dialogHelperService.openDialogComponentWithRef(SingleModeMultiplicationDialogComponent);
  }
}

export const FACE_API_MODELS_URI = 'assets/face-api/models';

export const PREFLIGHT_FACE_DETECTION_SOURCE_URI = 'assets/face-api/preflight-source.jfif';

/**
 * @see https://justadudewhohacks.github.io/face-api.js/docs/index.html#usage-face-detection-options
 */
export const MINIMUM_CONFIDENCE_THRESHOLD = 0.6;

/**
 * @see https://justadudewhohacks.github.io/face-api.js/docs/index.html#usage-face-detection-options
 */
export const PROCESSED_IMAGE_SIZE = 128;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaybackAudioEffectDirective } from './playback-audio-effect.directive';

@NgModule({
  declarations: [PlaybackAudioEffectDirective],
  imports: [CommonModule],
  exports: [PlaybackAudioEffectDirective],
})
export class PlaybackAudioEffectDirectiveModule {}

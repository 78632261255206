import { EGameFinishedReason, EGameResult } from './game.types';
import { OpponentModel } from './opponent.types';
import { IPlayerState } from './player.type';

export interface IWsLobbyDataDto {
  roomSid: string;
  accessToken: string;
  opponents: [OpponentModel];
  gameId: string;
}

export interface IWsErrorDto {
  error: string;
}

export interface IWsGameDataDto {
  type: 'timer' | 'expired' | 'finished';
  data: IWsGameTimerDataDto | IWsGameFinishedDataDto | Record<string, never>;
}

export interface IWsGameFinishedDataDto {
  reward: string;
  reason?: EGameFinishedReason;
  gameDuration: number;
  gameResult: EGameResult;
}

export interface IWsGameTimerDataDto {
  counter: number;
  playerIds: string[];
  activePlayerIds: string[];
  blurredPlayerIds: string[];
  playersIssues: IPlayerState[];
}

export enum IWsErrorMessage {
  GameNotAvailable = 'Game not available',
  AlreadyPlayedToday = 'You have already played today',
}

<div class="info-row flex-between cursor-pointer" [style.background-color]="backGroundColor">
  <div class="info-row__left flex-center txt-primary">
    <svg-icon
      class="flex-start"
      *ngIf="leftIconSrc && !noSvgleftIconSrc"
      [src]="leftIconSrc"
      [svgStyle]="{ 'stroke':leftIconFillColor }"
    ></svg-icon>
    <div class="info-row__left-image flex-center" *ngIf="leftIconSrc && noSvgleftIconSrc">
      <img [src]="leftIconSrc" class="w-100 h-100" />
    </div>
    <div class="flex-start w-100" [class.vertical]="leftSubText">
      <ng-content select="leftText"></ng-content>
      <div *ngIf="leftText" class="info-row__left:text overflow-ellipsis w-85" [style.color]="leftTextColor">{{leftText}}</div>
      <div *ngIf="leftSubText" class="info-row__left:subtext overflow-ellipsis w-85" [style.color]="leftSubTextColor">
        {{leftSubText}}
      </div>
    </div>
  </div>

  <div class="info-row__right flex-center txt-primary">
    <div *ngIf="rightText" class="info-row__right:text" [style.color]="rightTextColor">{{rightText}}</div>
    <svg-icon
      class="flex-center"
      *ngIf="rightIconSrc"
      [src]="rightIconSrc"
      [svgStyle]="{ 'stroke':leftIconFillColor }"
    ></svg-icon>
  </div>
</div>

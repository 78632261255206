import * as strings from 'src/assets/strings/strings.json';

export type StringsKeys = keyof typeof strings;

type StringKeyObj = { [p in StringsKeys]: p };

export const StringsKeys = (Object.keys(strings) as StringsKeys[]).reduce((acc, currentValue) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  acc[currentValue] = currentValue;
  return acc;
}, {} as StringKeyObj);

import { HandLandmarker } from '@mediapipe/tasks-vision';
import { BuildHandLandmarkerOptions } from './hand-landmarker-options.helper';
import { HAND_LANDMARKER_WASM_FILESET_BINARY_URI, HAND_LANDMARKER_WASM_FILESET_LOADER_URI } from '@constants';

export class HandLandmarkerModelsLoader {
  public static async loadHandLandmarkerModel(): Promise<HandLandmarker> {
    return await HandLandmarker.createFromOptions(
      {
        wasmLoaderPath: HAND_LANDMARKER_WASM_FILESET_LOADER_URI,
        wasmBinaryPath: HAND_LANDMARKER_WASM_FILESET_BINARY_URI,
      },
      BuildHandLandmarkerOptions()
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { addSeconds, intervalToDuration } from 'date-fns';

@Pipe({
  name: 'remainingTime',
})
export class RemainingTimePipe implements PipeTransform {
  transform(seconds: number, toFormat = 'minutes:seconds', hideZeroFor = ''): string {
    const now = new Date();
    const targetDate = addSeconds(now, seconds);

    const duration = intervalToDuration({
      start: now,
      end: targetDate,
    });

    // * Avoid to display days, increase hours instead.
    if (duration.days && duration.days > 0) {
      Object.assign(duration, {
        days: 0,
        hours: (duration.hours || 0) + duration.days * 24,
      });
    }

    let value = toFormat;

    for (const key in duration) {
      if (value.includes(key)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let segment = duration[key];
        if (segment === 0 && hideZeroFor.includes(key)) {
          value = value.replace(`${key}:`, '');
        } else {
          segment = segment < 10 ? `0${segment}` : segment;
          value = value.replace(key, segment);
        }
      }
    }

    const doubleZero = '00:';
    if (value.startsWith(doubleZero)) {
      value = value.replace(doubleZero, '0:');
    }

    return value;
  }
}

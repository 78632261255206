import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeWithPostfixPipe } from './time-with-postfix.pipe';

@NgModule({
  declarations: [TimeWithPostfixPipe],
  imports: [CommonModule],
  exports: [TimeWithPostfixPipe],
})
export class TimeWithPostfixModule {}

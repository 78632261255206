import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeleteAccountDialogComponent } from './delete-account-dialog.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { InputModule } from '../input/input.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RemainingTimePipeModule, ToStringPipeModule } from '@pipes';
import { DialogModule } from '../dialog/dialog.module';

@NgModule({
  declarations: [DeleteAccountDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    DialogModule,
    AngularSvgIconModule,
    RemainingTimePipeModule,
    ToStringPipeModule,
  ],
  exports: [DeleteAccountDialogComponent],
})
export class DeleteAccountDialogModule {}

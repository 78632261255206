<div class="identify__gender">
  <div class="identify__gender-header">
    <p class="identify__gender-title">{{StringsKeys.genderTitle | toString}}</p>
  </div>
  <div class="identify__gender-content">
    <form class="identify__gender-form w-100" #form="ngForm">
      <label class="identify__gender-formlabel flex-between cursor-pointer w-100">
        <input type="radio" name="gender" value="male" hidden ngModel />
        <span>{{StringsKeys.editProfileGenderMale | toString}}</span>
        <svg-icon *ngIf="form.value.gender === 'male'" src="./assets/images/checked.svg"></svg-icon>
      </label>
      <label class="identify__gender-formlabel flex-between cursor-pointer w-100">
        <input type="radio" name="gender" value="female" hidden ngModel />
        <span>{{StringsKeys.editProfileGenderFemale | toString}}</span>
        <svg-icon *ngIf="form.value.gender === 'female'" src="./assets/images/checked.svg"></svg-icon>
      </label>
      <label class="identify__gender-formlabel flex-between cursor-pointer w-100">
        <input type="radio" name="gender" value="other" hidden ngModel />
        <span>{{StringsKeys.editProfileGenderOther | toString}}</span>
        <svg-icon *ngIf="form.value.gender === 'other'" src="./assets/images/checked.svg"></svg-icon>
      </label>
    </form>
    <div align="center">
      <button
        class="btn btn-orange"
        type="button"
        id="identify-gender-confirm-button"
        (click)="next(form.value.gender)"
        [disabled]="!form.value.gender"
      >
        {{StringsKeys.btnConfirm | toString}}
      </button>
    </div>
  </div>
</div>
